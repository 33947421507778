import React from 'react';
import { fccPaidLeaveState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const FccPaidLeave = () => {

  const [fccPaidLeave, setFccPaidLeaveState] = useRecoilState(fccPaidLeaveState);

  const handleOnChange = (value) => {
    setFccPaidLeaveState(value)
  }

  return (
  <Stack direction="row" spacing={1}>
    <SalaryField
      value={fccPaidLeave}
      handleOnChange={handleOnChange}
      label='paid_leave'
      fullWidth
    />
    <Help contentKey="help_fcc_paid_leave" />
  </Stack>
  )

}

export default FccPaidLeave

