import React from 'react';
import { fccSalaryDataSourceState } from '../../recoil_state'
import { useRecoilValue } from 'recoil'
import FccSalaryDataSource from './FccSalaryDataSource'
import FccUserEnteredSalaries from './FccUserEnteredSalaries'
import Grid from '@mui/material/Grid';

const FccSalaries = () => {

  const fccSalaryDataSource = useRecoilValue(fccSalaryDataSourceState);

  const isUser = fccSalaryDataSource === 'user'

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FccSalaryDataSource/>
      </Grid>
    {isUser ? <Grid item xs={12}><FccUserEnteredSalaries /></Grid> : null}
    </Grid>
  )

}

export default FccSalaries

