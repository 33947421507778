import React from 'react';
import { fccEpeTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccEduProgResults = () => {

  const value = useRecoilValue(fccEpeTotalState)

  return <ResultsLineItem name="education_program_exp" value={value}/>

}

export default FccEduProgResults;

