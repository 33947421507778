/**
 * Cal Constants
 */

export const DAILY_COVERAGE_PERCENTAGE = .2
export const ANNUAL_EMPLOYEE_TRAINING_TIME = 18
export const SALARY_TO_HOURLY_DIVISOR = 2080

export const FICA_SOCIAL_SECURITY_PERCENTAGE = .062
export const MEDICARE_PERCENTAGE = .0145
export const UNEMPLOYMENT_PERCENTAGE = .01
export const UNEMPLOYMENT_MULTIPLIER = 7000
export const WORKERS_COMP_PERCENTAGE = .02

export const FAMILY_ENGAGEMENT_HOURS_PER_CHILD = 2
export const SCHOOL_AGE_PERCENTAGE_OF_SALARY = .6
export const SCHOOL_AGE_CPC_ADJUSTMENT = .4

export const OCCUPANCY_MULTIPLIER = 65
export const OPERATING_RESERVE_FUND_CONTRIBUTION_PERCENTAGE = .05


/**
 * Sheeet: Nonpersonnel PCQC
 */


/**
 * Program Management
 */

// Cell B6
export const PM_SUPPLIES = 118.55

// Cell B7
export const PM_PHONE = 5340

// Cell B8
export const PM_INSURANCE = 130.3

// Cell B9
export const PM_LEGAL = 3559.64

// Cell B10
export const PM_FEES = 593.81

// Cell B11
export const PM_MEMBERSHIPS = 74.76

// Cell B12
export const PM_MARKETING = 0

// Cell B13
export const PM_OTHER = 0



/**
 * Occupancy
 */

// Cell B15
export const OCC_RENT = 22.4

// Cell B16
export const OCC_OTHER = 6.09

// Cell B17
export const OCC_MAINT = 4.38


/**
 * Education/Program - CHILD
 */

// Cell B20
export const EDU_CHILD_FOOD = 2366.01

// Cell B21
export const EDU_CHILD_SUPPLIES = 148.45

// Cell B22
export const EDU_CHILD_MEDICAL = 66.89

// Cell B33
export const EDU_CHILD_EDUCATIONAL = 118.55

// Cell B24
export const EDU_CHILD_ADVERTISING = 23.5

// Cell B25
export const EDU_CHILD_ASSESSMENT = 26.7


/**
 * Education/Program - Staff
 */

// Cell B20
export const EDU_STAFF_PERSONNEL = 1281.6

// Cell B21
export const EDU_STAFF_TRAINING = 200


/**
 * Multipliers
 */

export const KIDS = 90;

export const CLASSROOMS = 5
