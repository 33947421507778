import React from 'react';
import { displayPdfState, orgTypeState, stateState, centerRatioRegsState, centerSalaryDataSourceState, centerDiscretionaryBenefitsState, centerRetirementBenefitsState, centerSickDaysState, centerPaidLeaveState, centerFamilyEngagementState, centerOrPercentageState } from '../../recoil_state'
import { useRecoilValue } from 'recoil';
import wrapper from '../wrapper'
import Grid from '@mui/material/Unstable_Grid2';
import CenterSizeDisplay from '../center/CenterSizeDisplay'
import CenterCharts from '../center/results/CenterCharts'
import ResultsCard from '../ResultsCard'
import Content from '../Content'
import PdfCard from './PdfCard'
import { getStateName } from '../../helpers';

const CenterData = ({ translate }) => {

  const orgType = useRecoilValue(orgTypeState)
  const state = useRecoilValue(stateState)
  const displayPdf = useRecoilValue(displayPdfState);
  const centerRatioRegs = useRecoilValue(centerRatioRegsState);
  const centerSalaryDataSource = useRecoilValue(centerSalaryDataSourceState);
  const centerDiscretionaryBenefits = useRecoilValue(centerDiscretionaryBenefitsState);
  const centerRetirementBenefits = useRecoilValue(centerRetirementBenefitsState);
  const centerSickDays = useRecoilValue(centerSickDaysState);
  const centerPaidLeave = useRecoilValue(centerPaidLeaveState);
  const centerFamilyEngagement = useRecoilValue(centerFamilyEngagementState);
  const centerOrPercentage = useRecoilValue(centerOrPercentageState);

  if(!displayPdf){
    return null;
  }

  const modifiedValue = v => v === true ? 'yes' : 'no';

  return (
    <React.Fragment>
      <PdfCard title="model_data">
      <Grid container item xs={12} spacing={1} className='pdf-data-grid'>
        <Grid item xs={12}>
          <Content variant="h6" contentKey='state_center_size' />
        </Grid>
        <Grid item xs={3}>
          {translate('state')}:
        </Grid>
        <Grid item xs={3}>
          {getStateName(state)}
        </Grid>
        <Grid item xs={3}>
          {translate('ratio_regs')}:
        </Grid>
        <Grid item xs={3}>
          {translate(centerRatioRegs)}
        </Grid>
        <Grid item xs={12} className='pdf-margin pdf-break'>
          <CenterSizeDisplay pdfDisplayOnly />
        </Grid>

        <Grid item xs={12} className='pdf-data-section'>
          <Content variant="h6" contentKey='salaries' />
        </Grid>
        <Grid item xs={3}>
          {translate('salary_data_source')}:
        </Grid>
        <Grid item xs={9}>
          {translate(centerSalaryDataSource)}
        </Grid>


        <Grid item xs={12} className='pdf-data-section'>
          <Content variant="h6" contentKey='benefits' />
        </Grid>
        <Grid item xs={3}>
          {translate('discretionary_benefits')}:
        </Grid>
        <Grid item xs={3}> {translate(modifiedValue(centerDiscretionaryBenefits))}
        </Grid>
        <Grid item xs={3}>
          {translate('retirement_benefits')}:
        </Grid>
        <Grid item xs={3}> {centerRetirementBenefits}%
        </Grid>
        <Grid item xs={3}>
          {translate('sick_days')}:
        </Grid>
        <Grid item xs={3}> {centerSickDays}
        </Grid>
        <Grid item xs={3}>
          {translate('paid_leave')}:
        </Grid>
        <Grid item xs={3}> {centerPaidLeave}
        </Grid>



        <Grid item xs={12} className='pdf-data-section'>
          <Content variant="h6" contentKey='enhancements' />
        </Grid>
        <Grid item xs={3}>
          {translate('family_engagement')}:
        </Grid>
        <Grid item xs={3}> {translate(modifiedValue(centerFamilyEngagement))}
        </Grid>
        <Grid item xs={3}>
          {translate('op_reserve_contribution')}:
        </Grid>
        <Grid item xs={3}> {centerOrPercentage}%
        </Grid>
      </Grid>

      </PdfCard>
      <Grid container item xs={12} spacing={2} className='pdf-margin pdf-break'>
        <Grid item xs={6}>
          <ResultsCard title="program_level_results" which="programLevel" orgType={orgType} />
        </Grid>
        <Grid item xs={6}>
          <ResultsCard title="cpc_results" which="cpc" orgType={orgType} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className='pdf-margin'>
        <CenterCharts pdfDisplayOnly />
      </Grid>
    </React.Fragment>
  );
}


export default wrapper(CenterData);

