import React, { useEffect } from 'react';
import {
  fccTotalChildrenState,
  //fccTotalClassroomsState,
  fccInfantState,
  fccToddlerState,
  fccThreesState,
  fccFoursState,
  fccSchoolState,
  //fccTotalSchoolAgeClassroomsState,
  //fccPositionCountState,
  fccTotalSchoolAgeChildrenState,
  fccRatioRegsState,
  fccAccreditationState,
  fccPositionCountState,
  fccTotalStaffState
} from "../../recoil_state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'


const FccSizeTotalsProvider = () => {

  const fccRatioRegs = useRecoilValue(fccRatioRegsState);
  const fccAccreditation = useRecoilValue(fccAccreditationState);
  const fccInfant = useRecoilValue(fccInfantState);
  const fccToddler = useRecoilValue(fccToddlerState);
  const fccThrees = useRecoilValue(fccThreesState);
  const fccFours = useRecoilValue(fccFoursState);
  const fccSchool = useRecoilValue(fccSchoolState);

  const setFccTotalStaffState = useSetRecoilState(fccTotalStaffState);
  const [fccPositionCount, setFccPositionCountState] = useRecoilState(fccPositionCountState);

  const [fccTotalChildren, setFccTotalChildrenState] = useRecoilState(fccTotalChildrenState);

  const [fccTotalSchoolAgeChildren, setFccTotalSchoolAgeChildrenState] = useRecoilState(fccTotalSchoolAgeChildrenState);
  //const [fccTotalClassrooms, setFccTotalClassroomsState] = useRecoilState(fccTotalClassroomsState);
  //const [fccTotalSchoolAgeClassrooms, setFccTotalSchoolAgeClassroomsState] = useRecoilState(fccTotalSchoolAgeClassroomsState);

  //const classrooms = _.round(_.sum(_.map([fccInfant, fccToddler, fccThrees, fccFours, fccSchool], (v) => v.classrooms)
  //), 0);
  //
  //const schoolAgeClassrooms = _.get(fccSchool, 'classrooms') || 0

  const childrenAge = _.round(_.sum(_.map([fccInfant, fccToddler, fccThrees, fccFours, fccSchool], (v) => v.childrenAge)
  ), 0);

  //Asst teacher value
  let asstTeacher = 0;

  if(fccRatioRegs === 'small_fcc'){
    asstTeacher = .25;
  }else{
    asstTeacher = fccAccreditation === 'cfoc' ? 2 : 1;
  }


  useEffect(() => {

    setFccPositionCountState(
      {
        ...fccPositionCount,
        asstTeacher
      }
    )

    setFccTotalStaffState(_.sum(
      [
        fccPositionCount.director,
        asstTeacher
      ]
    ))


    if(childrenAge !== fccTotalChildren){
      setFccTotalChildrenState(childrenAge)
    }

    if(fccSchool.childrenAge !== fccTotalSchoolAgeChildren){
      setFccTotalSchoolAgeChildrenState(fccSchool.childrenAge)
    }

  }, [
    fccInfant,
    fccToddler,
    fccThrees,
    fccFours,
    fccSchool,
    fccRatioRegs,
    fccAccreditation
  ]);

}

export default FccSizeTotalsProvider
