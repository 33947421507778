import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  orgTypeState
} from "../recoil_state";
import { useRecoilValue } from 'recoil'
import Stack from '@mui/material/Stack';
import OrgType from './OrgType';
import Center from './center/Center';
import ResultsCard from './ResultsCard';
import Fcc from './fcc/Fcc';
import CenterCharts from './center/results/CenterCharts';
import FccCharts from './fcc/results/FccCharts';
import Actions from './actions/Actions';




const Calculator = () => {

  const orgType = useRecoilValue(orgTypeState)

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8}>
            <Box>
              {/*<Locale/>*/}
              <Stack direction="row" spacing={2} className="org-type-selection">
                <OrgType/>
              </Stack>

              {orgType === 'fcc' ? <Fcc /> : <Center />}
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Actions />
            <ResultsCard title="program_level_results" which="programLevel" orgType={orgType} />
            <ResultsCard title="cpc_results" which="cpc" orgType={orgType} />
          </Grid>
          <Grid item xs={12}>
              {orgType === 'fcc' ? <FccCharts /> : <CenterCharts />}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Calculator
