import React from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import wrapper from './wrapper'

const Help = ({ translate, contentKey, noRM, pdfDisplayOnly }) => {

  const sx = noRM ? { paddingRight: 0 } : {}

  if(pdfDisplayOnly){
    return null;
  }

  return (
    <Tooltip disableFocusListener title={translate(contentKey)}>
      <IconButton sx={sx}>
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}


export default wrapper(Help);
