import React from 'react';
import FccDiscretionaryBenefits from './FccDiscretionaryBenefits'
import FccRetirementBenefits from './FccRetirementBenefits'
import FccSickDays from './FccSickDays'
import FccPaidLeave from './FccPaidLeave'
import Grid from '@mui/material/Grid';


const FccSalaries = () => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <FccDiscretionaryBenefits/>
      </Grid >
      <Grid item xs={12} sm={6}>
       <FccRetirementBenefits />
      </Grid >
      <Grid item xs={12} sm={6}>
       <FccSickDays />
      </Grid >
      <Grid item xs={12} sm={6}>
       <FccPaidLeave />
      </Grid >
    </Grid>
  )

}

export default FccSalaries

