import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import '../../css/css.css'
import wrapper from '../wrapper';
import { technicalMethodology } from '../../helpers/analytics';

const Footer = ({ translate }) => {

  const year = new Date().getFullYear();

  return (
    <div className="footer-container" >
      <Stack spacing={2}>
        <div className="footer-box" >
          <Typography variant="h6" gutterBottom className="footer-one">
            {translate('co_name')}
          </Typography>
        </div>
        <div className="footer-disclaimer-box" >
            <Typography variant="body" gutterBottom className="welcome" >
                {translate('disclaimer_1')} <Link href={`${translate('technical_methodology_url')}`} onClick={() => technicalMethodology() } rel="noopener" target="_blank">{translate('technical_methodology_here')}</Link>.
            </Typography >
        </div>
        <div className="footer-disclaimer-box" >
            <Typography variant="body" gutterBottom className="welcome" >
                {translate('disclaimer_2')} <Link href={`mailto:${translate('info_email')}`}>{translate('info_email')}</Link>.
            </Typography >
        </div>
        <div className="footer-box" >
          <Typography variant="body" gutterBottom className="copyright">
            &copy; {year} - {translate('co_name')}
          </Typography>
        </div>
        <div className="footer-box" >
          <Typography variant="body" gutterBottom className="copyright">
            {process.env.REACT_APP_VERSION}
          </Typography>
        </div>
      </Stack>
    </div >
  )

}

export default wrapper(Footer);
