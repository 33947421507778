import React from 'react';
import { localeState, contentState } from "../recoil_state";
import { useRecoilValue } from "recoil";
import _ from 'lodash'
import Typography from '@mui/material/Typography';

export default function Content({ contentKey, noWrap=false, sx, variant }) {

  const locale = useRecoilValue(localeState);
  const content = useRecoilValue(contentState);

  const value = _.get(content, [locale, contentKey]) || null

  return (
    <Typography noWrap={noWrap} sx={sx} variant={variant}>
      {value}
    </Typography>
  );
}
