import React from 'react';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../../css/css.css'

const LinkBuild = (props) => {

const { href, text } = props

  return (
    <Link
      color="rgb(24, 24, 24)"
      underline="none"
      className="header-links"
      onClick={() => window.location.href = `https://www.prenatal5fiscal.org/${href}`}
    >{text}</Link >
  )
}

const NavLinks = () => {

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
        <Grid item className="nav-grid">
    <Stack direction="row"
      spacing={2}
      className="header-container" >
        <LinkBuild href=""
          text="Home" />
        <LinkBuild href="comprehensive-approach"
          text="Comprehensive Approach" />
        <LinkBuild href="resources"
          text="Resources" />
        <LinkBuild href="adirondacks"
          text="Active State Projects" />
        <LinkBuild href="webinarseries"
          text="Webinars" />
        <LinkBuild href="about"
          text="About" />
      </Stack >
        </Grid>
      </Grid>
    </Box>
  )

}

export default NavLinks
