import React, { useEffect } from 'react';
import { stateState } from '../recoil_state'
import { useRecoilState } from 'recoil'
import _ from 'lodash'
import states from '../data/stateAbbreviations.json'
import { entryState } from '../helpers/analytics'


const StateProvider = () => {

  const [appState, setState] = useRecoilState(stateState)

  const state = _.toUpper(_.get(_.split(window.location.pathname, '/'), '1'))

  useEffect(() => {

    if(appState === state){
      return
    }

    if(_.includes(states, state)){
      setState(state)
      entryState(state)
    }


  }, [appState]);

}

export default StateProvider
