import * as React from 'react';
import FccCpcChartResults from './FccCpcChartResults';
import FccExpenseTotalChartResults from './FccExpenseTotalChartResults';
import FccExpensePersonnelChartResults from './FccExpensePersonnelChartResults';
import FccExpenseNonPersonnelChartResults from './FccExpenseNonPersonnelChartResults';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { fccTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const FccCharts = () => {

  const value = useRecoilValue(fccTotalExpenseState)

  if(!value) return null

  return (

    <Box sx={{ flexGrow: 1 }} >
      <Grid container
        spacing={2} >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccCpcChartResults which="annual"
            cardTitle="annual_cost_per_child" />
        </Grid >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccCpcChartResults which="monthly"
            cardTitle="monthly_cost_per_child" />
        </Grid >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccCpcChartResults which="weekly"
            cardTitle="weekly_cost_per_child" />
        </Grid >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccExpenseTotalChartResults
            cardTitle="total_expense_lower" />
        </Grid >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccExpensePersonnelChartResults
            cardTitle="total_personnel" />
        </Grid >
        <Grid item
          xs={12} md={6} lg={4}>
          <FccExpenseNonPersonnelChartResults
            cardTitle="total_nonpersonnel" />
        </Grid >
      </Grid >
    </Box >

  )
};

export default FccCharts;

