import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import wrapper from '../wrapper'
import Content from '../Content'
import FccTableCellData from './FccTableCellData'
import FccSizeTotals from './FccSizeTotals'
import Help from '../Help'

const FccSizeTable = ({ pdfDisplayOnly, translate }) => {

  const keys = [
    "infant",
    "toddler",
    "threes",
    "fours",
    "school"
  ]

  return (

    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('age')}</TableCell>
            <TableCell align="right">{translate('number_of_children')}<Help pdfDisplayOnly={pdfDisplayOnly} contentKey="help_children" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key) => (
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Content contentKey={key} />
              </TableCell>
              <TableCell align="right">
                <FccTableCellData
                  row={key}
                  col='childrenAge'
                  numerical
                  pdfDisplayOnly={pdfDisplayOnly}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
              <TableCell component="th" scope="row">
                <Content contentKey="totals" />
              </TableCell>
              <TableCell align="right">
                <FccSizeTotals col="childrenAge" />
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default wrapper(FccSizeTable)
