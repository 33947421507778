import React from 'react';
import { fccNonPersonnelTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccNonPersonnelTotalResults = () => {

  const value = useRecoilValue(fccNonPersonnelTotalState)

  return <ResultsLineItem name="total_nonpersonnel" value={value} bold={600} />;

}

export default FccNonPersonnelTotalResults;

