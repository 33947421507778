import {
  atom
} from "recoil";
import { orgTypeSelect,viewComparison, viewPdf, savedForComparison } from '../helpers/analytics'

export const stateState = atom({
  key: "stateState",
  default: 'US'
});

export const localeState = atom({
  key: "localeState",
  default: 'en',
  effects: [
    ({onSet}) => {
      onSet(newID => {
        console.debug("New Locale", newID);
      });
    },
  ],
});

export const orgTypeState = atom({
  key: "orgTypeState",
  default: "center",
  effects: [
    ({onSet}) => {
      onSet(v => {
        console.log("RUN ON DEFAULT")
        orgTypeSelect(v)
      });
    },
  ],
});

export const comparisonSavedState = atom({
  key: "comparisonSavedState",
  default: {},
  effects: [
    ({onSet}) => {
      onSet(v => {
        savedForComparison()
      });
    },
  ]

});

export const comparisonCurrentState = atom({
  key: "comparisonCurrentState",
  default: {},
  effects: [
    ({onSet}) => {
      onSet(newID => {
        console.debug("comparisonCurrentState", newID);
      });
    },
  ],
});

export const displayComparisonState = atom({
  key: "displayComparisonState",
  default: false,
  effects: [
    ({onSet}) => {
      onSet(v => {
        viewComparison()
      });
    },
  ]
});

export const displayPdfState = atom({
  key: "displayPdfState",
  default: false,
  effects: [
    ({onSet}) => {
      onSet(v => {
        viewPdf()
      });
    },
  ]
});
