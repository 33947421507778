import React, { useEffect } from 'react';
import {
  fccTotalChildrenState,
  fccTotalSchoolAgeChildrenState,
  fccInfantState,
  fccToddlerState,
  fccThreesState,
  fccFoursState,
  fccSchoolState,
  fccPositionCountState,
  fccTotalStaffState,
  fccSalariesState,
  fccWagesTotalState,
  fccPersonnelTotalState,
  fccSickDaysState,
  fccPaidLeaveState,
  fccMandatoryBenefitsTotalState,
  fccDiscretionaryBenefitsTotalState,
  fccDiscretionaryBenefitsState,
  stateState,
  fccRetirementBenefitsState,
  fccBenefitsTotalState,
  fccFamilyEngagementTotalState,
  fccNonPersonnelTotalState,
  fccOrTotalState,
  fccTotalExpenseState,
  fccQvTotalState,
  fccEpeTotalState,
  fccOccupTotalState,
  fccPmaTotalState,
  fccWageGroupsState,
  fccAnnualCpcInfantCalculationsState,
  fccAnnualCpcToddlerCalculationsState,
  fccAnnualCpcThreesCalculationsState,
  fccAnnualCpcFoursCalculationsState,
  fccAnnualCpcSchoolAgeCalculationsState,
  fccAnnualCpcInfantState,
  fccAnnualCpcToddlerState,
  fccAnnualCpcThreesState,
  fccAnnualCpcFoursState,
  fccAnnualCpcSchoolAgeState,
  fccRatioRegsState,
  fccOrPercentageState,
  fccFamilyEngagementState,
  fccAccreditationState
} from "../../recoil_state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'
import {
  ANNUAL_EMPLOYEE_TRAINING_TIME,
  FICA_SOCIAL_SECURITY_PERCENTAGE,
  MEDICARE_PERCENTAGE,
  WORKERS_COMP_PERCENTAGE,
} from '../../data/statics/pcqc/fcc'
import  * as fccp from '../../calculations/fccProfile'
import { getInsurance } from '../../calculations/stateOverrideData'
import { round, roundSum, debugLog } from '../../helpers'


const FccProfileProvider = () => {

  const state = useRecoilValue(stateState);
  const fccInfant = useRecoilValue(fccInfantState);
  const fccToddler = useRecoilValue(fccToddlerState);
  const fccThrees = useRecoilValue(fccThreesState);
  const fccFours = useRecoilValue(fccFoursState);
  const fccSchool = useRecoilValue(fccSchoolState);
  const fccTotalChildren = useRecoilValue(fccTotalChildrenState);
  const fccTotalSchoolAgeChildren = useRecoilValue(fccTotalSchoolAgeChildrenState);
  //const fccTotalClassrooms = useRecoilValue(fccTotalClassroomsState);
  //const fccTotalSchoolAgeClassrooms = useRecoilValue(fccTotalSchoolAgeClassroomsState);
  const fccSalaries = useRecoilValue(fccSalariesState);
  const fccSickDays = useRecoilValue(fccSickDaysState);
  const fccPaidLeave = useRecoilValue(fccPaidLeaveState);
  const fccDiscretionaryBenefits = useRecoilValue(fccDiscretionaryBenefitsState);
  const fccRetirementBenefits = useRecoilValue(fccRetirementBenefitsState);
  //const fccFamilyEngagement = useRecoilValue(fccFamilyEngagementState);
  const fccRatioRegs = useRecoilValue(fccRatioRegsState);
  const fccAccreditation = useRecoilValue(fccAccreditationState);
  const fccOrPercentage = useRecoilValue(fccOrPercentageState);
  const fccFamilyEngagement = useRecoilValue(fccFamilyEngagementState);

  const [fccPositionCount, setFccPositionCountState] = useRecoilState(fccPositionCountState);
  const [fccQvTotal, setFccQvTotalState] = useRecoilState(fccQvTotalState);
  const [fccWageGroups, setFccWageGroupsState] = useRecoilState(fccWageGroupsState);
  //const [fccTeachersByGroup, setFccTeachersByGroupState] = useRecoilState(fccTeachersByGroupState);
  const [fccAnnualCpcInfantCalculations, setFccAnnualCpcInfantCalculationsState] = useRecoilState(fccAnnualCpcInfantCalculationsState);
  const [fccAnnualCpcToddlerCalculations, setFccAnnualCpcToddlerCalculationsState] = useRecoilState(fccAnnualCpcToddlerCalculationsState);
  const [fccAnnualCpcThreesCalculations, setFccAnnualCpcThreesCalculationsState] = useRecoilState(fccAnnualCpcThreesCalculationsState);
  const [fccAnnualCpcFoursCalculations, setFccAnnualCpcFoursCalculationsState] = useRecoilState(fccAnnualCpcFoursCalculationsState);
  const [fccAnnualCpcSchoolAgeCalculations, setFccAnnualCpcSchoolAgeCalculationsState] = useRecoilState(fccAnnualCpcSchoolAgeCalculationsState);

  const fccTotalStaff = useRecoilValue(fccTotalStaffState);

  //const setFccTeacherStaffState = useSetRecoilState(fccTeacherStaffState);
  const setFccWagesTotalState = useSetRecoilState(fccWagesTotalState);
  const setFccMandatoryBenefitsTotalState = useSetRecoilState(fccMandatoryBenefitsTotalState);
  const setFccDiscretionaryBenefitsTotalState = useSetRecoilState(fccDiscretionaryBenefitsTotalState);
  const setFccBenefitsTotalState = useSetRecoilState(fccBenefitsTotalState);
  const setFccFamilyEngagementTotalState = useSetRecoilState(fccFamilyEngagementTotalState);
  const setFccNonPersonnelTotalState = useSetRecoilState(fccNonPersonnelTotalState);
  const setFccOrTotalState = useSetRecoilState(fccOrTotalState);
  const setFccPersonnelTotalState = useSetRecoilState(fccPersonnelTotalState);
  const setFccTotalExpenseState = useSetRecoilState(fccTotalExpenseState);
  const setFccEpeTotalState = useSetRecoilState(fccEpeTotalState);
  const setFccOccupTotalState = useSetRecoilState(fccOccupTotalState);
  const setFccPmaTotalState = useSetRecoilState(fccPmaTotalState);
  const setFccAnnualCpcInfantState = useSetRecoilState(fccAnnualCpcInfantState);
  const setFccAnnualCpcToddlerState = useSetRecoilState(fccAnnualCpcToddlerState);
  const setFccAnnualCpcThreesState = useSetRecoilState(fccAnnualCpcThreesState);
  const setFccAnnualCpcFoursState = useSetRecoilState(fccAnnualCpcFoursState);
  const setFccAnnualCpcSchoolAgeState = useSetRecoilState(fccAnnualCpcSchoolAgeState);


  /**
   * Determine Wages
   */

  const directorWages = fccp.directorCost(fccSalaries.director, fccPositionCount.director)
  const asstTeacherWages = fccp.asstTeacherCost(fccSalaries.asstTeacher, fccPositionCount.asstTeacher)
  const floaterWages = { total : 0 };
  const substituteWages = fccp.substituteCost(fccSalaries.asstTeacher, fccTotalStaff, fccSickDays, fccPaidLeave, ANNUAL_EMPLOYEE_TRAINING_TIME)

  const wagesTotal = roundSum(
    [
      directorWages.total || 0,
      asstTeacherWages.total || 0,
      floaterWages.total || 0,
      substituteWages.total || 0,
    ]
  );


  /**
   * Determine Mandatory benefits total
   */


  const unemployment = fccp.unemploymentBenefitsCost(substituteWages, fccTotalStaff)

  const mandatoryBenefitsTotal = roundSum([
      FICA_SOCIAL_SECURITY_PERCENTAGE * wagesTotal,
      MEDICARE_PERCENTAGE * wagesTotal,
    unemployment,
      WORKERS_COMP_PERCENTAGE * wagesTotal,
    ]
  )

  /**
   * Determine Disc benefits total
   */

  const determineDiscBenefits = () => {

    if(!fccDiscretionaryBenefits){
      return 0
    }

    const insuranceCostPerEmployee = getInsurance(state, 'fcc') || 0
    const insuranceTotal = insuranceCostPerEmployee * fccTotalStaff;
    const retirementPercentage = fccRetirementBenefits/100;
    const retirementTotal = (wagesTotal - substituteWages.total || 0) * retirementPercentage;

    return roundSum([
      insuranceTotal,
      retirementTotal
    ]);

  }

  const discBenefitsTotal = determineDiscBenefits();

  /**
   * Determine Benefits Total
   */


  const benefitsTotal = roundSum([
    mandatoryBenefitsTotal,
    discBenefitsTotal
  ]);


  /**
   * Determine Personnel Total
   */

  const personnelTotal = fccp.personnelCost(wagesTotal, mandatoryBenefitsTotal, discBenefitsTotal)

  /**
   * Determine Family Engagement Cost
   */
  const familyEngagementTotal = fccp.familyEngagementCost(fccFamilyEngagement, fccSalaries.asstTeacher, fccTotalChildren, fccTotalSchoolAgeChildren)

  /**
   * Determine Quality Vars Cost
   *
   * Right now, Family Engagement is the only factor in QV
   */
  const qvTotal = familyEngagementTotal;

  /**
   * Determine NonPersonnel Costs
   */
  const nonPersonnelTotals = fccp.nonPersonnelCost(fccTotalChildren, state, qvTotal)

  let nonPersonnelTotal = _.get(nonPersonnelTotals, 'total') || 0;
  const nonEpeTotal = _.get(nonPersonnelTotals, 'epe') || 0;
  const nonOccupTotal = _.get(nonPersonnelTotals, 'occup') || 0;
  const nonPmaTotal = _.get(nonPersonnelTotals, 'pma') || 0;



  /**
   * Determine Operating Reserve Costs
   */
  const orTotal = fccp.orCost(personnelTotal, nonPersonnelTotal, fccOrPercentage)


  debugLog([
    `nonPersonnelTotal before Operating reserve - ${nonPersonnelTotal}`,

    `orTotal - ${orTotal}`,
    `nonEpeTotal - ${nonEpeTotal}`,
    `nonOccupTotal - ${nonOccupTotal}`,
    `nonPmaTotal - ${nonPmaTotal}`,
  ])

  // Add OR into nonPersonnel total vars
  nonPersonnelTotal = round(nonPersonnelTotal + orTotal)
  nonPersonnelTotals.total = nonPersonnelTotal

  debugLog([
    `personnelTotal - ${personnelTotal}`,
    `nonPersonnelTotal - ${nonPersonnelTotal}`,
  ])


  /**
   * Determine Total Expense
   */
  const totalExpense = fccp.totalExpense(personnelTotal, nonPersonnelTotal)


  /**
   * Determine cpc calcs and values
   */

  //Handle School Age first so we can factor in school age adjustment into the rest

  const initialFccAnnualCpcSchoolAgeCalculations = fccp.annualCPC(fccTotalChildren, totalExpense,  fccSchool,null, true);

  debugLog([
    'initialFccAnnualCpcSchoolAgeCalculations',
    initialFccAnnualCpcSchoolAgeCalculations
  ])


  /**
   * get the school age adjustment
   */

  const schoolAgeAdjustment = fccp.schoolAgeAdjustment(initialFccAnnualCpcSchoolAgeCalculations, fccTotalChildren, fccSchool)

  debugLog([
    'schoolAgeAdjustment',
    schoolAgeAdjustment
  ])

  const newFccAnnualCpcSchoolAgeCalculations = fccp.adjustSchoolAgeCpc(initialFccAnnualCpcSchoolAgeCalculations, schoolAgeAdjustment, fccSchool)

  const newFccAnnualCpcInfantCalculations = fccp.annualCPC(fccTotalChildren, totalExpense,  fccInfant, schoolAgeAdjustment.perChild);
  const newFccAnnualCpcToddlerCalculations = fccp.annualCPC(fccTotalChildren, totalExpense,  fccToddler, schoolAgeAdjustment.perChild);
  const newFccAnnualCpcThreesCalculations = fccp.annualCPC(fccTotalChildren, totalExpense,  fccThrees, schoolAgeAdjustment.perChild);
  const newFccAnnualCpcFoursCalculations = fccp.annualCPC(fccTotalChildren, totalExpense,  fccFours, schoolAgeAdjustment.perChild);

  useEffect(() => {

    /**
     * If we don;t yet have a fccRatioRegs we'll fail so return
     */
    //if(!fccRatioRegs || !fccAccreditation){
    if(!fccRatioRegs){
      return
    }


    /**
     * Set fccPositionCountState
     */
    //setFccPositionCountState({ ...fccPositionCount, ...newFccPositionCount })



    /**
     * Set total Wages
     */
    setFccWagesTotalState(wagesTotal)

    /**
     * Set Mandatory benefits Total
     */
    setFccMandatoryBenefitsTotalState(mandatoryBenefitsTotal)

    /**
     * Set Disc benefits Total
     */
    setFccDiscretionaryBenefitsTotalState(discBenefitsTotal)

    /**
     * Set Benefits Total
     */
    setFccBenefitsTotalState(benefitsTotal)

    /**
     * Set Personnel Total
     */
    setFccPersonnelTotalState(personnelTotal)

    /**
     * Set Family Engagement Total
     */
    setFccFamilyEngagementTotalState(familyEngagementTotal)

    /**
     * Set Quality Vars Total
     */
    setFccQvTotalState(qvTotal)

    /**
     * Set EPE Total
     */
    setFccEpeTotalState(nonEpeTotal)

    /**
     * Set Occupancy Total
     */
    setFccOccupTotalState(nonOccupTotal)

    /**
     * Set PMA Total
     */
    setFccPmaTotalState(nonPmaTotal)

    /**
     * Set NonPersonnel Total
     */
    setFccNonPersonnelTotalState(nonPersonnelTotal)

    /**
     * Set Operating Reserve Total
     */
    setFccOrTotalState(orTotal)

    /**
     * Set total Expense
     */
    setFccTotalExpenseState(totalExpense)

    /**
     * Set CPC calcs and values
     */

    setFccAnnualCpcInfantCalculationsState({ ...fccAnnualCpcInfantCalculations, ...newFccAnnualCpcInfantCalculations })
    setFccAnnualCpcToddlerCalculationsState({ ...fccAnnualCpcToddlerCalculations, ...newFccAnnualCpcToddlerCalculations })
    setFccAnnualCpcThreesCalculationsState({ ...fccAnnualCpcThreesCalculations, ...newFccAnnualCpcThreesCalculations })
    setFccAnnualCpcFoursCalculationsState({ ...fccAnnualCpcFoursCalculations, ...newFccAnnualCpcFoursCalculations })
    setFccAnnualCpcSchoolAgeCalculationsState({ ...fccAnnualCpcSchoolAgeCalculations, ...newFccAnnualCpcSchoolAgeCalculations })

    setFccAnnualCpcInfantState(newFccAnnualCpcInfantCalculations.annualCpc)
    setFccAnnualCpcToddlerState(newFccAnnualCpcToddlerCalculations.annualCpc)
    setFccAnnualCpcThreesState(newFccAnnualCpcThreesCalculations.annualCpc)
    setFccAnnualCpcFoursState(newFccAnnualCpcFoursCalculations.annualCpc)
    setFccAnnualCpcSchoolAgeState(newFccAnnualCpcSchoolAgeCalculations.annualCpc)

  }, [
    state,
    fccTotalChildren,
    fccInfant,
    fccToddler,
    fccThrees,
    fccFours,
    fccSchool,
    fccSalaries,
    fccSickDays,
    fccPaidLeave,
    fccDiscretionaryBenefits,
    fccRetirementBenefits,
    fccFamilyEngagement,
    fccQvTotal,
    fccRatioRegs,
    fccOrPercentage,
    fccAccreditation,
    fccTotalStaff
  ]);



}

export default FccProfileProvider
