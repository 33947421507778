import React from 'react';
import { centerPmaTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterPgrmMgmtResults = () => {

  const value = useRecoilValue(centerPmaTotalState)

  return <ResultsLineItem name="program_mgmt" value={value} />

}

export default CenterPgrmMgmtResults;

