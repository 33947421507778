import React from 'react';
import { centerOrTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterOperatingReserveResults = () => {

  const value = useRecoilValue(centerOrTotalState)

  return <ResultsLineItem name="op_reserve" value={value} />

}

export default CenterOperatingReserveResults;

