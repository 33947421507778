import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const TableTextField = ({ value, row, col, handleOnChange, error, helperText }) => {

  const [stateVal, setStateVal] = useState(value);

  let interceptedVal = stateVal === "" ? stateVal : value;

  const interceptOnChange = ({ target: { value:fieldValue } }) => {
    setStateVal(fieldValue)
    if(fieldValue !== ''){
      handleOnChange({ target: { value:fieldValue } })
    }
  }

  const handleOnBlur = () => {
    if(stateVal === ''){
      setStateVal(0)
      handleOnChange({ target: { value:0 } })
    }
  }



  return (
    <TextField
      type='number'
      inputProps={{ min:0 }}
      error={error}
      helperText={helperText}
      variant="outlined"
      //value={value}
      value={interceptedVal}
      //onChange={handleOnChange}
      onChange={interceptOnChange}
      onBlur={handleOnBlur}
    />
  )
}

export default TableTextField
