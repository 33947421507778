import {
  round,
  halfPerFifty,
  salaryToHourly,
  roundSum
} from '../helpers'
import _ from 'lodash'
import {
  //DAILY_COVERAGE_PERCENTAGE,
  SCHOOL_AGE_PERCENTAGE_OF_SALARY,
  FAMILY_ENGAGEMENT_HOURS_PER_CHILD,
  EDU_CHILD_FOOD,
  EDU_CHILD_SUPPLIES,
  EDU_CHILD_MEDICAL,
  EDU_CHILD_EDUCATIONAL,
  EDU_CHILD_ADVERTISING,
  EDU_CHILD_ASSESSMENT,
  EDU_STAFF_PERSONNEL,
  EDU_STAFF_TRAINING,
  OCC_RENT,
  OCC_OTHER,
  OCC_MAINT,
  OCCUPANCY_MULTIPLIER,
  PM_SUPPLIES,
  PM_PHONE,
  PM_INSURANCE,
  PM_LEGAL,
  PM_FEES,
  PM_MEMBERSHIPS,
  PM_MARKETING,
  PM_OTHER,
  //OPERATING_RESERVE_FUND_CONTRIBUTION_PERCENTAGE,
  UNEMPLOYMENT_MULTIPLIER,
  UNEMPLOYMENT_PERCENTAGE,
  SCHOOL_AGE_CPC_ADJUSTMENT
} from '../data/statics/pcqc/center';
import costOfLiving from '../data/costOfLiving.json'


/**
 * NOTES
 *
 * Group Size = Number of Children in a classroom
 * Ratios = ratio of children to teacher - e.g. 1 = 1 teacher for every child, 4 = 1 teacher for every 4 children
 * Children determined by classrooms * group size
 * Teachers calculated by Children divided by ratio
 *
 */


/**
 * Expenses: Personnel
 */

export const directorCount = (childrenTotal = 0) => {
  return childrenTotal > 0 ? 1 : 0
}

export const asstDirectorCount = (childrenTotal = 0) => {
  return halfPerFifty(childrenTotal)
}

/**
 * Costs
 */

/**
 * Program Director
 * Qty: 1
 * Cell: E18
 * Atom: centerSalariesState
 * key: director
 */

export const directorCost = (salary = 0, childrenTotal = 0) => {
  try {

    const qty = directorCount(childrenTotal)

    return {
      unit: salary,
      total: round(salary * qty),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Assistant Director
 * Qty: .5 per 50
 * Cell: E19
 * Atom: centerSalariesState
 * key: asstDirector
 */

export const asstDirectorCost = (salary = 0, childrenTotal = 0) => {
  try {

    const qty = asstDirectorCount(childrenTotal)

    return {
      unit: salary,
      total: round(salary * qty),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Teacher count breakdown by group
 * Should be childrenAge/ratios with a min number of (groupSize/ratios * classrooms) rounded up
 */

export const determineTeachersByGroup = ({ classrooms = 0, ratios = 0, childrenAge = 0, groupSize = 0 }) => {

  //const min = _.ceil((groupSize / ratios) * classrooms);
  const min = 0;
  const teacherCalc = _.round(childrenAge / ratios, 2);

  return teacherCalc < min ? min : teacherCalc;

}

/**
 * Admin Assistant
 * Qty: .5
 * Cell: E19
 * Atom: centerSalariesState
 * key: adminAsst
 */

export const adminAsstCost = (salary = 0, childrenTotal = 0) => {
  try {

    const qty = asstDirectorCount(childrenTotal)
    return {
      unit: salary,
      total: round(salary * qty),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Lead Teacher
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E23
 * Atom: centerSalariesState
 * key: leadTeacher
 */

export const leadTeacherCost = (salary = 0, totalClassrooms = 0, totalSchoolAgeClassrooms = 0) => {
  try {

    const qty = totalClassrooms - totalSchoolAgeClassrooms

    return {
      unit: salary,
      total: round(salary * qty),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Asst Teacher
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E24
 * Atom: centerSalariesState
 * key: asstTeacher
 */

export const asstTeacherCost = (salary = 0, totalClassrooms = 0, totalSchoolAgeClassrooms = 0, teacherByGroup) => {
  try {

    const classrooms = totalClassrooms - totalSchoolAgeClassrooms

    const totalTeachers = _.sum([
      teacherByGroup.infant,
      teacherByGroup.toddler,
      teacherByGroup.threes,
      teacherByGroup.fours,
    ])

    // Total asst teachers = total teachers - total classrooms (as 1 lead teacher per classroom)

    const qty = totalTeachers - classrooms

    return {
      unit: salary,
      total: round(salary * qty),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Lead School Age Teacher
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E25
 * Atom: centerSalariesState
 * key: leadTeacher
 */

export const leadSchoolAgeTeacherCost = (salary = 0, totalSchoolAgeClassrooms = 0) => {
  try {
    return {
      unit: salary,
      total: round((salary * totalSchoolAgeClassrooms) * SCHOOL_AGE_PERCENTAGE_OF_SALARY),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Asst School Age Teacher
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E26
 * Atom: centerSalariesState
 * key: asstTeacher
 */

export const asstSchoolAgeTeacherCost = (salary = 0, totalSchoolAgeClassrooms = 0, teacherByGroup) => {
  try {

    const qty = teacherByGroup.schoolAge - totalSchoolAgeClassrooms

    return {
      unit: salary,
      total: round((salary * qty) * SCHOOL_AGE_PERCENTAGE_OF_SALARY),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Floater Assistants
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E26
 * Atom: centerSalariesState
 * key: substitute
 */

export const floaterCost = (salary = 0, floaterCount) => {
  try {

    return {
      unit: salary,
      total: round(salary * floaterCount),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Substitute Costs
 */
export const substituteCost = (salary = 0, totalTeachers, sickDays, paidLeave, trainingTime, newCenterTotalStaff) => {
  try {

    // Convert sal to hourly rate
    const hourlyRate = salaryToHourly(salary)

    const paidLeaveSubsCost = totalTeachers * (paidLeave * 8) * hourlyRate;
    const sickDaysSubsCost = totalTeachers * (sickDays * 8) * hourlyRate;
    const trainingTimeSubsCost = (newCenterTotalStaff * trainingTime) * hourlyRate;

    return {
      paidLeaveSubsCost,
      sickDaysSubsCost,
      unit: salary,
      total: roundSum([
        paidLeaveSubsCost,
        sickDaysSubsCost,
        trainingTimeSubsCost
      ]),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Personnel Cost
 * Sum of wages, mandatory and discretionary benefits
 */

export const personnelCost = (wagesTotal = 0, mandatoryBenefitsTotal = 0, discBenefitsTotal = 0) => {
  return roundSum([
    wagesTotal,
    mandatoryBenefitsTotal,
    discBenefitsTotal
  ])
}

/**
 * Unemployment Benefits Cost
 * UNEMPLOYMENT Percentage * (UNEMPLOYMENT_MULTIPLIER * Total Staff)
 */

export const unemploymentBenefitsCost = (substituteWages, centerTotalStaff) => {

  const subsSum = _.sum([
    substituteWages.paidLeaveSubsCost,
    substituteWages.sickDaysSubsCost
  ])

  return ((UNEMPLOYMENT_MULTIPLIER * centerTotalStaff) + substituteWages.total) * UNEMPLOYMENT_PERCENTAGE

}

/**
 * Family Engagement
 * Qty: 2 hours per child (omitting school age children) paid at floater/sub hourly
 * Cell: E26
 * Atom: centerFamilyEngagementTotalState
 * key: substitute
 */

export const familyEngagementCost = (centerFamilyEngagement, salary = 0, centerTotalChildren = 0, centerTotalSchoolAgeChildren = 0) => {
  try {

    if (!centerFamilyEngagement) {
      return 0;
    }

    const totalChildren = centerTotalChildren - centerTotalSchoolAgeChildren;

    // Convert sal to hourly rate
    const hourlyRate = salaryToHourly(salary)

    return round(hourlyRate * centerTotalChildren * FAMILY_ENGAGEMENT_HOURS_PER_CHILD);

  } catch (e) {
    console.error(e)
  }
}

/**
 * NonPersonnel Totals
 * Adjust the following by state based COLA.  Use static vals for rest
 *  EDU_CHILD_FOOD, EDU_CHILD_ADVERTISING, EDU_STAFF_PERSONNEL, OCC_RENT, OCC_OTHER, OCC_MAINT,
 *  PM_PHONE,  PM_INSURANCE, PM_LEGAL, PM_FEES
 */

export const nonPersonnelCost = (centerTotalChildren = 0, totalClassrooms = 0, state, qvTotal = 0, centerTeachingStaff=0) => {

  const cola = _.get(costOfLiving, state) || 1

  // Education

  const eduCosts = _.sum([
    _.sum([
      EDU_CHILD_FOOD * cola,
      EDU_CHILD_ADVERTISING * cola,
      EDU_CHILD_SUPPLIES,
      EDU_CHILD_MEDICAL,
      EDU_CHILD_EDUCATIONAL,
      EDU_CHILD_ASSESSMENT,
    ]) * centerTotalChildren,
    EDU_STAFF_PERSONNEL * cola,
    EDU_STAFF_TRAINING * centerTeachingStaff
  ]);

  // Occupancy

  const occupancyCosts = _.sum([
    OCC_RENT,
    OCC_OTHER,
    OCC_MAINT,
  ]) * OCCUPANCY_MULTIPLIER * centerTotalChildren * cola;

  //Administration

  const adminCosts = _.sum([
    PM_SUPPLIES * centerTotalChildren,
    PM_PHONE * cola,
    (PM_INSURANCE * centerTotalChildren) * cola,
    PM_LEGAL * cola,
    PM_FEES * cola,
    PM_MEMBERSHIPS * cola,
    PM_MARKETING * cola,
    PM_OTHER * cola,
  ])

  return {
    total: roundSum([qvTotal, eduCosts, occupancyCosts, adminCosts]),
    epe: round(eduCosts),
    occup: round(occupancyCosts),
    pma: round(adminCosts)
  }

}

/**
 * Operating Reserve
 * Total of Personnel and NonPersonnel expenses * centerOrPercentage
 */

export const orCost = (personnelTotal = 0, nonPersonnelTotal = 0, centerOrPercentage = 0) => {

  const orp = centerOrPercentage/100;

  return round(
    (personnelTotal + nonPersonnelTotal) * orp
  )
}

/**
 * Total Expense
 * Total of Personnel and NonPersonnel expenses and Operating Reserve
 */

export const totalExpense = (personnelTotal = 0, nonPersonnelTotal = 0, orTotal = 0) => {
  return roundSum(
    [personnelTotal, nonPersonnelTotal]
  )
}

/**
 * Annual Admin cost helper
 * (Admin wages / total children) * number of kids in group
 */

const adminCost = (centerWageGroups, centerTotalChildren = 0, centerChildGroupState) => {
  return round(
    (centerWageGroups.admin / centerTotalChildren) * centerChildGroupState.childrenAge
  )
}

const calcNonSchoolAgeTeachers = (teacherByGroup) => {
  return _.sum([
    teacherByGroup.infant,
    teacherByGroup.toddler,
    teacherByGroup.threes,
    teacherByGroup.fours,
  ])
}

/**
 * Annual Teacher cost helper
 * (teacher wages / total children) * number of kids in group
 */

const teacherCost = (centerWageGroups, centerTotalChildren = 0, centerChildGroupState = {}, teacherGroupQty, totalClassrooms, teacherByGroup, centerSalaries, isSchoolAge) => {

  const { classrooms, childrenAge } = centerChildGroupState

  if (classrooms === 0) {
    return 0
  }

  // total non school age teachers
  const totalTeachers = calcNonSchoolAgeTeachers(teacherByGroup)

  // 1 teacher per classroom, rest are assists
  const leadTeacherQty = classrooms;

  // asst teachers - total number of teachers in this group - group total lead teachers
  const asstTeacherQty = teacherGroupQty - leadTeacherQty;

  const ltSalary = isSchoolAge ? centerSalaries.leadTeacher * SCHOOL_AGE_PERCENTAGE_OF_SALARY : centerSalaries.leadTeacher;

  const atSalary = isSchoolAge ? centerSalaries.asstTeacher * SCHOOL_AGE_PERCENTAGE_OF_SALARY : centerSalaries.asstTeacher;

  // lead teacher total, lead teacher wages divided by total number of teachers * total number of teachers in this group
  const leadTeachersCost = ltSalary * leadTeacherQty || 0

  // asst teacher total, asst teacher wages divided by total number of asst teachers (teachers total - total number of
  // classrooms) * total number of (teachers in this group - classrooms in this group)
  const asstTeachersCost = atSalary * asstTeacherQty

  return roundSum([
    leadTeachersCost,
    asstTeachersCost
  ]);
}

/**
 * Annual Sub cost helper
 * total sub wages divided by total teachers multiplied by # of teachers in this group
 */

const subCost = (centerWageGroups, teacherByGroup, teacherGroupQty) => {

  // total non school age teachers
  //const totalTeachers = calcNonSchoolAgeTeachers(teacherByGroup)

  // Think it should be all teachers regardless of group
  const totalTeachers = _.sum(_.values(teacherByGroup));

  return round((centerWageGroups.sub / totalTeachers) * teacherGroupQty)
}

/**
 * Annual Floater cost helper
 * total floater wages divided by total teachers multiplied by # of teachers in this group
 */

const floatCost = (centerWageGroups, teacherGroupQty, teacherByGroup) => {

  const totalTeachers = _.sum(_.values(teacherByGroup));
  return round((centerWageGroups.floater / totalTeachers) * teacherGroupQty)
}

/**
 * Annual Benefits cost helper
 * total benefits divided by total teachers multiplied by # of teachers in this group
 */

const benefitsCost = (benefitsTotal, centerTotalChildren, centerChildGroupState) => {

  // total non school age teachers
  //const totalTeachers = calcNonSchoolAgeTeachers(teacherByGroup)
  return round((benefitsTotal / centerTotalChildren) * centerChildGroupState.childrenAge)
}

/**
 * Occupancy cost helper
 * nonOccupTotal divided by total classrooms multiplied by # of classrooms in this group
 */

const occupancyCost = (nonOccupTotal, centerChildGroupState, totalClassrooms) => {

  return round((nonOccupTotal / totalClassrooms) * centerChildGroupState.classrooms)
}

/**
 * Child cost helper
 * (nonEpeTotal + nonPmaTotal + orTotal + qvTotal) / total children * children in this group
 */

const childCost = (nonPersonnelTotals, orTotal, qvTotal, centerChildGroupState, centerTotalChildren) => {

  const value = _.sum([
    nonPersonnelTotals.epe,
    nonPersonnelTotals.pma,
    orTotal,
    qvTotal
  ])

  return round((value / centerTotalChildren) * centerChildGroupState.childrenAge)
}


/**
 * Annual Cost per infant
 *
 */

export const annualCPC = (centerWageGroups, centerTotalChildren = 0, centerChildGroupState, teacherByGroup, totalClassrooms, teacherGroupQty, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, schoolAgeAdjustmentPerChild, isSchoolAge = false) => {

  const admin = adminCost(centerWageGroups, centerTotalChildren, centerChildGroupState, );

  const teacher = teacherCost(centerWageGroups, centerTotalChildren, centerChildGroupState, teacherGroupQty, totalClassrooms, teacherByGroup, centerSalaries, isSchoolAge)

  const sub = subCost(centerWageGroups, teacherByGroup, teacherGroupQty)

  const floater = floatCost(centerWageGroups, teacherGroupQty, teacherByGroup)

  const benefits = benefitsCost(benefitsTotal, centerTotalChildren, centerChildGroupState)

  const occupancy = occupancyCost(nonPersonnelTotals.occup, centerChildGroupState, totalClassrooms)

  const child = childCost(nonPersonnelTotals, orTotal, qvTotal, centerChildGroupState, centerTotalChildren)

  const costCalculations = {
    admin,
    teacher,
    sub,
    floater,
    benefits,
    occupancy,
    child,
  }

  let totalGroupCost = null
  let annualCpc = null

  const totalGroupCostUnadjusted = roundSum(_.values(costCalculations))
  const annualCpcUnadjusted = round(totalGroupCostUnadjusted / centerChildGroupState.childrenAge)

  if(!schoolAgeAdjustmentPerChild){
    totalGroupCost = totalGroupCostUnadjusted;
    annualCpc = annualCpcUnadjusted;
  }else{
    totalGroupCost = roundSum([
      totalGroupCostUnadjusted,
      schoolAgeAdjustmentPerChild * centerChildGroupState.childrenAge
    ])
    annualCpc = round(totalGroupCost / centerChildGroupState.childrenAge)
  }

  return {
    totalGroupCost,
    annualCpc,
    totalGroupCostUnadjusted,
    annualCpcUnadjusted,
    ...costCalculations
  }

}

/**
 * School Age Adjustment
 * School age cpc totals - school age teacher salaries (already accounted for) /
 */

export const schoolAgeAdjustment = (schoolAgeCpc, centerTotalChildren, centerSchool) => {

  const { totalGroupCost, teacher } = schoolAgeCpc

  const adjustment = (totalGroupCost - teacher) * SCHOOL_AGE_CPC_ADJUSTMENT;

  // cpc for adjustment - all kids excluding school age kids

  return {
    perChild: round(adjustment / (centerTotalChildren - centerSchool.childrenAge)),
    total: adjustment
  }

}

/**
 * Updates school age cpc to account for school age adjustment subtractions
 */

export const adjustSchoolAgeCpc = (cpcCalcs, schoolAgeAdjustment, centerChildGroupState) => {

  const { totalGroupCostUnadjusted, annualCpcUnadjusted } = cpcCalcs

  const totalGroupCost = round(
    totalGroupCostUnadjusted - schoolAgeAdjustment.total
  )
  const annualCpc = round(totalGroupCost / centerChildGroupState.childrenAge)

  return {
    ...cpcCalcs,
    totalGroupCost,
    annualCpc,
  }

}
