import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import wrapper from './wrapper';
import BarChart from './BarChart';

const ChartCard = ({ translate, cardTitle, height, series, width, xAxis }) => {
  return (
    <Box sx={{ minWidth: 275 }} >
      <Card  className="results-card" >
        <CardHeader
          className="results-card-header"
          title={cardTitle}
        />
        <CardContent >

          <BarChart height={height} series={series} width={width} xAxis={xAxis} />

        </CardContent >
      </Card >
    </Box >
  );
}

export default wrapper(ChartCard);
