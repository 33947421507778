import React from 'react';
import { fccBenefitsTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccBenefitsResults = () => {

  const value = useRecoilValue(fccBenefitsTotalState)

  return <ResultsLineItem name="benefits" value={value}/>

}

export default FccBenefitsResults;

