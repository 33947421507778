import React, { useEffect } from 'react';
import { fccSalaryDataSourceState, fccSalariesState, stateState } from '../../recoil_state'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'
import { getWages } from '../../calculations/stateOverrideData'


const FccSalariesProvider = () => {

  const state = useRecoilValue(stateState);
  const fccSalaryDataSource = useRecoilValue(fccSalaryDataSourceState);
  const [fccSalaries, setFccSalariesState] = useRecoilState(fccSalariesState);

  const wages = getWages(state)
  const salaries = _.get(wages, ['fcc', fccSalaryDataSource])

  useEffect(() => {
    setFccSalariesState({ ...fccSalaries, ...salaries })
  }, [fccSalaryDataSource, state]);

}

export default FccSalariesProvider
