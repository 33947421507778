
const prodHostname = "ccmodel.prenatal5fiscal.org"

export const chartColors = [
  "rgba(0,113,185,1)",
  "rgba(152,153,199,1)",
  "rgba(83,180,175,1)",
  "rgba(235,184,85,1)",
  "rgba(15,67,109,1)",
]



export let GA_ID = null;
export const GA_DEBUG = false;

if(window.location.hostname === prodHostname){
  GA_ID = "G-ZJKGF64S5G";
} else {
  GA_ID = "G-9352GHQN5R";
}
