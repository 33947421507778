import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { displayPdfState, stateState } from '../../recoil_state'
import { useRecoilState, useRecoilValue } from 'recoil';
import wrapper from '../wrapper'
import PdfGenerator from './PdfGenerator'
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up"
    ref={ref} {...props} />;
});

const ComparisonDialog = ({ translate }) => {

  const [displayPdf, setDisplayPdfState] = useRecoilState(displayPdfState);
  const state = useRecoilValue(stateState);


  const handleClose = () => {
    setDisplayPdfState(false);
  };

  const handlePdfSave = () => {
    const element = document.getElementById("rootCalc");
    const opt = {
      margin: .3,
      filename: `Child_Care_Cost_Model_${state}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: { unit: 'in' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy']},
      html2canvas: {
        onclone: (element) => {
          const svgElements = Array.from(element.querySelectorAll('svg'));
          svgElements.forEach(s => {
            const bBox = s.getBBox();
            s.setAttribute("x", bBox.x);
            s.setAttribute("y", bBox.y);
            s.setAttribute("width", 'auto');
            s.setAttribute("height", 350);
            s.setAttribute("preserveAspectRatio","xMidYMid meet");
          })
          const svgRects = Array.from(element.querySelectorAll('rect'));
          svgRects.forEach(s => {
            if(!s.getAttribute('fill')) {
              s.style.setProperty('fill', 'rgb(0,113,185)')
              s.style.setProperty('opacity', 1)
            }
          })
        }
      }
    };
    window.html2pdf().set(opt).from(element).save();
  }

  return (
    <React.Fragment >
      <Dialog
        fullWidth
        maxWidth='xl'
        open={displayPdf}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
         <AppBar sx={{ position: 'relative' }} >
          <Toolbar >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton >
            <Typography sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div" >
              Save Model as PDF
            </Typography >
            <Button autoFocus
              color="inherit"
              onClick={handlePdfSave} >
              save
            </Button >
          </Toolbar >
        </AppBar >
        <DialogContent >
<PdfGenerator />
        </DialogContent >
        <DialogActions >
          <Button onClick={handleClose} >Close</Button >
        </DialogActions >
      </Dialog >
    </React.Fragment >
  );
}

export default wrapper(ComparisonDialog);
