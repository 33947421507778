import React from 'react';
import { centerSalaryDataSourceState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryDataSource from '../fields/SalaryDataSource'

const CenterSalaryDataSource = () => {

  const [centerSalaryDataSource, setCenterSalaryDataSourceState] = useRecoilState(centerSalaryDataSourceState);

  const handleOnChange = ({ target: { value } }) => {
    setCenterSalaryDataSourceState(value)
  }

  return (
    <SalaryDataSource value={centerSalaryDataSource} handleOnChange={handleOnChange} />
  )

}

export default CenterSalaryDataSource
