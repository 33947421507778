import React from 'react';
import {
  centerWagesTotalState,
  centerBenefitsTotalState,
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import PdfPieChartCard from '../../pdf/PdfPieChartCard';
import wrapper from '../../wrapper'


const CenterExpensePersonnelChartResults = ({ translate, which, cardTitle,pdfDisplayOnly }) => {

const centerWagesTotal = useRecoilValue(centerWagesTotalState);
const centerBenefitsTotal = useRecoilValue(centerBenefitsTotalState);


  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: centerWagesTotal, label: translate('wages') },
        { id: 1, value: centerBenefitsTotal, label: translate('benefits') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180,
      cy: 150
    },
  ]
  const ChartComponent = pdfDisplayOnly ? PdfPieChartCard : PieChartCard;

  return (
    <ChartComponent
      cardTitle={translate(cardTitle)}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(CenterExpensePersonnelChartResults)

