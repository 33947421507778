import React from 'react';
import { centerBenefitsTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'

const CenterBenefitsResults = () => {

  const value = useRecoilValue(centerBenefitsTotalState)

  return <ResultsLineItem name="benefits" value={value} />

}

export default CenterBenefitsResults;

