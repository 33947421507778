import React from 'react';
import {
  fccOrTotalState,
  fccQvTotalState,
  fccEpeTotalState,
  fccOccupTotalState,
  fccPmaTotalState,
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import wrapper from '../../wrapper'


const FccExpenseNonPersonnelChartResults = ({ translate, which, cardTitle }) => {

  const fccOrTotal = useRecoilValue(fccOrTotalState);
  const fccQvTotal = useRecoilValue(fccQvTotalState);
  const fccEpeTotal = useRecoilValue(fccEpeTotalState);
  const fccOccupTotal = useRecoilValue(fccOccupTotalState);
  const fccPmaTotal = useRecoilValue(fccPmaTotalState);


  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: fccQvTotal, label: translate('quality_variables_short') },
        { id: 1, value: fccEpeTotal, label: translate('education_program_exp_short') },
        { id: 2, value: fccOccupTotal, label: translate('occupancy_short') },
        { id: 3, value: fccPmaTotal, label: translate('program_mgmt_short') },
        { id: 4, value: fccOrTotal, label: translate('op_reserve_short') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180
    },
  ]

  return (
    <PieChartCard
      cardTitle={translate(cardTitle)}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(FccExpenseNonPersonnelChartResults)

