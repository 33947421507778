import React from 'react';
import { centerSalariesState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import wrapper from '../wrapper'
import Grid from '@mui/material/Grid';
import _ from 'lodash'

const CenterUserEnteredSalaries = ({ translate }) => {

  const [centerSalaries, setCenterSalariesState] = useRecoilState(centerSalariesState);

  const handleOnChange = (value, which) => {
    setCenterSalariesState({ ...centerSalaries, ...{ [which]: _.toNumber(value) }})
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <span className="user-enter-salaries-help" >
          {translate('user_entered_salaries_intro')}
        </span>
      </Grid>
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.director}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='director'
        label='director'
         fullWidth
      />
      </Grid >
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.asstDirector}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='asstDirector'
        label='asst_director'
         fullWidth
      />
      </Grid >
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.adminAsst}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='adminAsst'
        label='admin_asst'
         fullWidth
      />
      </Grid >
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.leadTeacher}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='leadTeacher'
        label='lead_teacher'
         fullWidth
      />
      </Grid >
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.asstTeacher}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='asstTeacher'
        label='asst_teacher'
         fullWidth
      />
      </Grid >
      <Grid item xs={12} sm={4}>
       <SalaryField
        value={centerSalaries.substitute}
        handleOnChange={handleOnChange}
         startAdornment="$"
        which='substitute'
        label='substitute'
         fullWidth
      />
      </Grid >
    </Grid>

  )

}

export default wrapper(CenterUserEnteredSalaries)


