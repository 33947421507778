import React from 'react';
import {
  fccPersonnelTotalState,
  fccNonPersonnelTotalState
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import wrapper from '../../wrapper'


const FccExpenseTotalChartResults = ({ translate, which, cardTitle }) => {

const fccPersonnelTotal = useRecoilValue(fccPersonnelTotalState);
const fccNonPersonnelTotal = useRecoilValue(fccNonPersonnelTotalState);

  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: fccNonPersonnelTotal, label: translate('total_nonpersonnel') },
        { id: 1, value: fccPersonnelTotal, label: translate('total_personnel') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180
    },
  ]

  return (
    <PieChartCard
      cardTitle={translate(cardTitle)}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(FccExpenseTotalChartResults)

