import React from 'react';
import { centerAnnualCpcInfantState, centerAnnualCpcToddlerState, centerAnnualCpcThreesState, centerAnnualCpcFoursState, centerAnnualCpcSchoolAgeState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import { round } from '../../../helpers'
import ChartCard from '../../ChartCard';
import wrapper from '../../wrapper'


const CenterCpcChartResults = ({ translate, which, cardTitle }) => {

  const infantValue = useRecoilValue(centerAnnualCpcInfantState)
  const toddlerValue = useRecoilValue(centerAnnualCpcToddlerState)
  const threesValue = useRecoilValue(centerAnnualCpcThreesState)
  const foursValue = useRecoilValue(centerAnnualCpcFoursState)
  const schoolAgeValue = useRecoilValue(centerAnnualCpcSchoolAgeState)

  const colors = {
    annual: '#de196b',
    monthly: '#da00ff',
    weekly: '#2e96ff',
  }

  const x = [{ color: '#2e96ff', scaleType: 'band', data: [translate('infant_short'), translate('toddler_short'), translate('threes'), translate('fours'), translate('school_short')], id: 'x-axis-id', }]

  const calData = (v) => {
    if(which === 'monthly'){
      return round(v/12)
    }
    if(which === 'weekly'){
      return v/52
    }
    return v
  }

  const series = [{ type: 'bar', data: [calData(infantValue), calData(toddlerValue), calData(threesValue), calData(foursValue), calData(schoolAgeValue)] }]

  return (
    <ChartCard
      cardTitle={translate(cardTitle)}
      xAxis={x}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(CenterCpcChartResults)

