import React from 'react';
import {
  fccWagesTotalState,
  fccBenefitsTotalState,
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import wrapper from '../../wrapper'


const FccExpensePersonnelChartResults = ({ translate, which, cardTitle }) => {

const fccWagesTotal = useRecoilValue(fccWagesTotalState);
const fccBenefitsTotal = useRecoilValue(fccBenefitsTotalState);


  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: fccWagesTotal, label: translate('wages') },
        { id: 1, value: fccBenefitsTotal, label: translate('benefits') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180
    },
  ]

  return (
    <PieChartCard
      cardTitle={translate(cardTitle)}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(FccExpensePersonnelChartResults)

