import React, { useState } from 'react';
import _ from 'lodash'
import wrapper from '../wrapper'
import TableTextField from '../fields/TableTextField'
import { centerRatioRegsState, centerInfantState, centerToddlerState, centerThreesState, centerFoursState, centerSchoolState} from "../../recoil_state";
import { useRecoilValue, useRecoilState } from "recoil";

const CenterTableCellData = (props) => {

  const { row, col, numerical, translate, pdfDisplayOnly } = props
  const [hasError, setHasError] = useState(false);

  const editableCols = pdfDisplayOnly ? [] : ['classrooms']

  const centerRatioRegs = useRecoilValue(centerRatioRegsState);
  const [centerInfant, setCenterInfantState] = useRecoilState(centerInfantState);
  const [centerToddler, setCenterToddlerState] = useRecoilState(centerToddlerState);
  const [centerThrees, setCenterThreesState] = useRecoilState(centerThreesState);
  const [centerFours, setCenterFoursState] = useRecoilState(centerFoursState);
  const [centerSchool, setCenterSchoolState] = useRecoilState(centerSchoolState);

  let stateData = {}

  let handleStateChange = () => {}

  if(row === 'infant'){
    stateData = { ...centerInfant }
    handleStateChange = setCenterInfantState
  }else if(row === 'toddler'){
    stateData = { ...centerToddler }
    handleStateChange = setCenterToddlerState
  }else if(row === 'threes'){
    stateData = { ...centerThrees }
    handleStateChange = setCenterThreesState
  }else if(row === 'fours'){
    stateData = { ...centerFours }
    handleStateChange = setCenterFoursState
  }else{
    stateData = { ...centerSchool }
    handleStateChange = setCenterSchoolState
  }

  if(centerRatioRegs === 'user' && !pdfDisplayOnly){
    editableCols.push('ratios', 'groupSize')
  }

  let error = hasError
  let helperText = !hasError ? null : col === 'ratios' ? translate('error_ratios') : col === "groupSize" ? translate('error_group_size') : null




  const handleOnChange = ({ target: { value:fieldValue } }) => {

    const newValue = numerical ? _.toNumber(fieldValue) : fieldValue


    const newData = {
      [col]: newValue
    }

    // if col is classrooms or group size we need to recalc children as well
    if(col === 'classrooms'){
      newData.childrenAge = _.round(_.multiply(newValue, stateData.groupSize), 0)
    }else if(col === 'groupSize'){
      newData.childrenAge = _.round(_.multiply(stateData.classrooms, newValue), 0)
    }

    if (col === 'ratios') {
      if (newValue > stateData.groupSize) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    } else if (col === 'groupSize') {
      if (newValue < stateData.ratios) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    } else {
      setHasError(false);
    }

    handleStateChange({
      ...stateData,
      ...newData
    })
  }

  return _.includes(editableCols, col) ?
    <TableTextField
      value={stateData[col]}
      row={row}
      col={col}
      handleOnChange={handleOnChange}
      error={error}
      helperText={helperText}
    /> :
    <span>{stateData[col]}</span>;

}

export default wrapper(CenterTableCellData)
