import React from 'react';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import wrapper from '../wrapper'
import { displayPdfState } from '../../recoil_state'
import { useSetRecoilState } from 'recoil';


const Pdf = ({ translate, disabled }) => {

  const setDisplayPdfState = useSetRecoilState(displayPdfState);

  const handleClick = () => {
    setDisplayPdfState(true);
  }

  return (
    <Tooltip disableFocusListener title={translate('generate_pdf')}>
      <span>
        <IconButton disabled={disabled} onClick={handleClick}>
          <PictureAsPdf />
        </IconButton>
      </span>
    </Tooltip>
  );
}


export default wrapper(Pdf);

