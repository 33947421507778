import React from 'react';
import Content from '../../Content'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CenterInfantCpcResults from './CenterInfantCpcResults';
import CenterToddlerCpcResults from './CenterToddlerCpcResults';
import CenterThreesCpcResults from './CenterThreesCpcResults';
import CenterFoursCpcResults from './CenterFoursCpcResults';
import CenterSchoolAgeCpcResults from './CenterSchoolAgeCpcResults';
import { centerTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const CenterCardResults = () => {


  const value = useRecoilValue(centerTotalExpenseState)

  const results = <Grid container spacing={2}>
      <Container className="cpc-results-container">
          <CenterInfantCpcResults />
      </Container>

        <div className="results-line" />
      <Container className="cpc-results-container">

          <CenterToddlerCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <CenterThreesCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <CenterFoursCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <CenterSchoolAgeCpcResults />

      </Container>




      </Grid>;

  const noResults = <Grid container spacing={2}>
      <Box sx={{ marginTop:2, marginLeft: 2 }}>
        <Content contentKey="results_need_input" />
      </Box>
      </Grid>

  return (
    <Box sx={{ flexGrow: 1 }}>
      {value ? results : noResults}
    </Box >
  )

}

export default CenterCardResults;

