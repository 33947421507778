import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const NavMenu = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const OptionBuild = (props) => {

    const { href, text } = props

    return (
      <MenuItem
        onClick={() => {handleClose(); window.location.href = `https://www.prenatal5fiscal.org/${href}`}}
      >{text}</MenuItem >
    )
  }

  const menuItems = [
    <OptionBuild href=""
      text="Home" key="1"/>,
    <OptionBuild href="comprehensive-approach"
      text="Comprehensive Approach" key="2"/>,
    <OptionBuild href="resources"
      text="Resources" key="3"/>,
    <OptionBuild href="adirondacks"
      text="Active State Projects" key="4"/>,
    <OptionBuild href="webinarseries"
      text="Webinars" key="5"/>,
    <OptionBuild href="about"
      text="About" key="6"/>
  ]

  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
        <Grid item className="nav-menu-grid">
      <IconButton
        id="basic-button"
        onClick={handleClick}
      >
        <MenuRoundedIcon fontSize="large" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuItems}
      </Menu>
        </Grid>
      </Grid>
    </Box>
  );
}


export default NavMenu
