import React from 'react';
import { centerPersonnelTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterPersonnelTotalResults = () => {

  const value = useRecoilValue(centerPersonnelTotalState)

  return <ResultsLineItem name="total_personnel" value={value} bold={600} />

}

export default CenterPersonnelTotalResults;

