import React from 'react';
import { fccPersonnelTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccPersonnelTotalResults = () => {

  const value = useRecoilValue(fccPersonnelTotalState)

  return <ResultsLineItem name="total_personnel" value={value} bold={600} />;

}

export default FccPersonnelTotalResults;

