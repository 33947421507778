import {
  atom
} from "recoil";

import en from '../data/content/en.json'
import es from '../data/content/es.json'

export const contentState = atom({
  key: "contentState",
  default: {
    en,
    es
  }
});
