import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useRecoilState } from "recoil";
import wrapper from './wrapper'
import Content from './Content'
import { orgTypeState } from "../recoil_state";

const OrgType = (props) => {

  const [orgType, setOrgType] = useRecoilState(orgTypeState);

  const updateOrgType = (event, value) => {
    if(value) setOrgType(value);
  };

  return (

    <ToggleButtonGroup
      color="primary"
      value={orgType}
      exclusive
      onChange={updateOrgType}
    >
      <ToggleButton value="center">
        <Content contentKey="center" />
      </ToggleButton>
      <ToggleButton value="fcc">
        <Content contentKey="fcc" />
      </ToggleButton>

    </ToggleButtonGroup>
  );
}

export default wrapper(OrgType)
