import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import wrapper from '../wrapper'
import TableTextField from '../fields/TableTextField'
import { fccRatioRegsState, fccAccreditationState, fccInfantState, fccToddlerState, fccThreesState, fccFoursState, fccSchoolState, fccTotalChildrenState} from "../../recoil_state";
import { MAX_SMALL_FCC_CFOC_CHILD_COUNT, MAX_SMALL_FCC_LICENSING_CHILD_COUNT, MAX_GROUP_FCC_CFOC_CHILD_COUNT, MAX_GROUP_FCC_LICENSING_CHILD_COUNT } from "../../data/statics/pcqc/fcc";
import { useRecoilValue, useRecoilState } from "recoil";

const FccTableCellData = (props) => {

  const { row, col, numerical, translate, pdfDisplayOnly } = props
  const [hasError, setHasError] = useState(false);

  const editableCols = pdfDisplayOnly ? [] : ['childrenAge']

  const fccRatioRegs = useRecoilValue(fccRatioRegsState);
  const fccTotalChildren = useRecoilValue(fccTotalChildrenState);
  const fccAccreditation = useRecoilValue(fccAccreditationState);
  const [fccInfant, setFccInfantState] = useRecoilState(fccInfantState);
  const [fccToddler, setFccToddlerState] = useRecoilState(fccToddlerState);
  const [fccThrees, setFccThreesState] = useRecoilState(fccThreesState);
  const [fccFours, setFccFoursState] = useRecoilState(fccFoursState);
  const [fccSchool, setFccSchoolState] = useRecoilState(fccSchoolState);

  //Asst teacher value
  let max = 0;

  if(fccRatioRegs === 'small_fcc'){
    max = fccAccreditation === 'cfoc' ? MAX_SMALL_FCC_CFOC_CHILD_COUNT : MAX_SMALL_FCC_LICENSING_CHILD_COUNT;
  }else{
    max = fccAccreditation === 'cfoc' ? MAX_GROUP_FCC_CFOC_CHILD_COUNT : MAX_GROUP_FCC_LICENSING_CHILD_COUNT;
  }

  let stateData = {}

  let handleStateChange = () => {}

  if(row === 'infant'){
    stateData = { ...fccInfant }
    handleStateChange = setFccInfantState
  }else if(row === 'toddler'){
    stateData = { ...fccToddler }
    handleStateChange = setFccToddlerState
  }else if(row === 'threes'){
    stateData = { ...fccThrees }
    handleStateChange = setFccThreesState
  }else if(row === 'fours'){
    stateData = { ...fccFours }
    handleStateChange = setFccFoursState
  }else{
    stateData = { ...fccSchool }
    handleStateChange = setFccSchoolState
  }

  let error = hasError
  let helperText= hasError ? `${translate('fcc_max_child_count_error')} ${max}` : null;

  const handleOnChange = ({ target: { value:fieldValue } }) => {

    const newValue = numerical ? _.toNumber(fieldValue) : fieldValue

    const newData = {
      [col]: newValue
    }

    const totalChildren = _.sum([
      fccInfant.childrenAge || 0,
      fccToddler.childrenAge || 0,
      fccThrees.childrenAge || 0,
      fccFours.childrenAge || 0,
      fccSchool.childrenAge || 0,
    ]) - stateData.childrenAge + newValue;



    if(totalChildren > max){
      setHasError(true);
    } else {
      setHasError(false);
    }

    handleStateChange({
      ...stateData,
      ...newData
    })
  }

  useEffect(() => {

    if(!hasError){
      return
    }

    if(fccTotalChildren <= max){
      setHasError(false);
    }

  }, [
    fccTotalChildren
  ]);

  return _.includes(editableCols, col) ?
    <TableTextField
      value={stateData[col]}
      row={row}
      col={col}
      handleOnChange={handleOnChange}
      error={error}
      helperText={helperText}
    /> :
    <span>{stateData[col]}</span>;

}

export default wrapper(FccTableCellData)
