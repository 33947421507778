import React from 'react';
import FccSizeTable from './FccSizeTable'
import { useRecoilValue } from "recoil";
import { fccRatioRegsState, fccAccreditationState } from "../../recoil_state";

const FccSizeDisplay = ({ pdfDisplayOnly }) => {


  const [fccRatioRegs] = useRecoilValue(fccRatioRegsState);
  const [fccAccreditation] = useRecoilValue(fccAccreditationState);

  if(!fccRatioRegs || !fccAccreditation){
    return null
  }

  return <FccSizeTable pdfDisplayOnly={pdfDisplayOnly}/>;

}

export default FccSizeDisplay
