import React from 'react';
import RatioAndGroupSizeRegs from '../fields/RatioAndGroupSizeRegs';
import State from '../fields/State';
import Grid from '@mui/material/Grid';
import CenterSizeDisplay from './CenterSizeDisplay';

const CenterStateAndRatioDisplay = () => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <State />
      </Grid >
      <Grid item
        xs={12} sm={6}>
        <RatioAndGroupSizeRegs />
      </Grid >
      <Grid item
        xs={12}>
        <CenterSizeDisplay />
      </Grid >
    </Grid >
  )

}

export default CenterStateAndRatioDisplay

