import React from 'react';
import {
  useRecoilValue,
} from "recoil";
import {
  centerTotalChildrenState,
  centerTotalClassroomsState,
} from "../../recoil_state";


const CenterSizeTotals = ({ col }) => {

  const centerTotalChildren = useRecoilValue(centerTotalChildrenState);
  const centerTotalClassrooms = useRecoilValue(centerTotalClassroomsState);

  return (
    <span >
      {col === 'classrooms' ? centerTotalClassrooms : centerTotalChildren}
    </span >
  )

}

export default CenterSizeTotals
