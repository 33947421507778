import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import wrapper from './wrapper'
import Content from './Content'


const YesNoSelect = ({ translate, handleOnChange, value, titleKey }) => {


  const updateState = ({ target: { value } }) => {
    handleOnChange(value === 'yes')
  };

  const modifiedValue = value === true ? 'yes' : 'no';

  return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"><Content contentKey={titleKey}/></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={modifiedValue}
          label={translate(titleKey)}
          onChange={updateState}
        >
          <MenuItem value="yes">{translate('yes')}</MenuItem>
          <MenuItem value="no">{translate('no')}</MenuItem>
        </Select>
      </FormControl>

  );
}

export default wrapper(YesNoSelect)
