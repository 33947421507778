import React, { useEffect } from 'react';
import {
  centerTotalChildrenState,
  centerTotalClassroomsState,
  centerInfantState,
  centerToddlerState,
  centerThreesState,
  centerFoursState,
  centerSchoolState,
  centerTotalSchoolAgeClassroomsState,
  centerTotalSchoolAgeChildrenState
} from "../../recoil_state";
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'


const CenterSizeTotalsProvider = () => {

  const centerInfant = useRecoilValue(centerInfantState);
  const centerToddler = useRecoilValue(centerToddlerState);
  const centerThrees = useRecoilValue(centerThreesState);
  const centerFours = useRecoilValue(centerFoursState);
  const centerSchool = useRecoilValue(centerSchoolState);

  const [centerTotalChildren, setCenterTotalChildrenState] = useRecoilState(centerTotalChildrenState);

  const [centerTotalSchoolAgeChildren, setCenterTotalSchoolAgeChildrenState] = useRecoilState(centerTotalSchoolAgeChildrenState);
  const [centerTotalClassrooms, setCenterTotalClassroomsState] = useRecoilState(centerTotalClassroomsState);
  const [centerTotalSchoolAgeClassrooms, setCenterTotalSchoolAgeClassroomsState] = useRecoilState(centerTotalSchoolAgeClassroomsState);

  const classrooms = _.round(_.sum(_.map([centerInfant, centerToddler, centerThrees, centerFours, centerSchool], (v) => v.classrooms)
  ), 0);

  const schoolAgeClassrooms = _.get(centerSchool, 'classrooms') || 0

  const childrenAge = _.round(_.sum(_.map([centerInfant, centerToddler, centerThrees, centerFours, centerSchool], (v) => v.childrenAge)
  ), 0);


  useEffect(() => {

    if(classrooms !== centerTotalClassrooms){
      setCenterTotalClassroomsState(classrooms)
    }

    if(schoolAgeClassrooms !== centerTotalSchoolAgeClassrooms){
      setCenterTotalSchoolAgeClassroomsState(schoolAgeClassrooms)
    }

    if(childrenAge !== centerTotalChildren){
      setCenterTotalChildrenState(childrenAge)
    }

    if(centerSchool.childrenAge !== centerTotalSchoolAgeChildren){
      setCenterTotalSchoolAgeChildrenState(centerSchool.childrenAge)
    }

  }, [
    centerInfant,
    centerToddler,
    centerThrees,
    centerFours,
    centerSchool,
  ]);

}

export default CenterSizeTotalsProvider
