import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import wrapper from '../wrapper'
import _ from 'lodash'
const formatter = new Intl.NumberFormat('en-US');

const formatNumber = (v) => {
  return formatter.format(v)
}

const cleanNumber = (v) => {
  return _.toNumber(v.replace(/\,/g,''))
}

const SalaryField = ({ value, handleOnChange, which='', label, translate, fullWidth=false, endAdornment, startAdornment }) => {

  //const [stateVal, setStateVal] = useState(value);

  const handleChange = ({ target: { value:fieldValue } }) => {
    //setStateVal(fieldValue)
    handleOnChange(fieldValue, which)
  }

  let InputProps = null

  if(endAdornment){
    InputProps = {
      endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>
    }
  }

  if(startAdornment){
    InputProps = {
      startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>
    }
  }



  return (
    <TextField
      type='number'
      variant="outlined"
      label={translate(label)}
      value={value}
      fullWidth={fullWidth}
      onChange={handleChange}
      InputProps={InputProps}
      inputProps={{ min:0 }}
    />
  )
}

export default wrapper(SalaryField)
