import React from 'react';
import { centerTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterTotalExpenseResults = () => {

  const value = useRecoilValue(centerTotalExpenseState)

  return <ResultsLineItem name="total_expense" value={value} bold={800} />

}

export default CenterTotalExpenseResults;

