import React from 'react';
import CenterDiscretionaryBenefits from './CenterDiscretionaryBenefits'
import CenterRetirementBenefits from './CenterRetirementBenefits'
import CenterSickDays from './CenterSickDays'
import CenterPaidLeave from './CenterPaidLeave'
import Grid from '@mui/material/Grid';


const CenterBenefits = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <CenterDiscretionaryBenefits/>
      </Grid >
      <Grid item xs={12} sm={6}>
       <CenterRetirementBenefits />
      </Grid >
      <Grid item xs={12} sm={6}>
       <CenterSickDays />
      </Grid >
      <Grid item xs={12} sm={6}>
       <CenterPaidLeave />
      </Grid >
    </Grid>
  )

}

export default CenterBenefits

