import React, { useEffect } from 'react';
import { centerSalaryDataSourceState, centerSalariesState, stateState } from '../../recoil_state'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'
import { getWages } from '../../calculations/stateOverrideData'


const CenterSalariesProvider = () => {

  const state = useRecoilValue(stateState);
  const centerSalaryDataSource = useRecoilValue(centerSalaryDataSourceState);
  const [centerSalaries, setCenterSalariesState] = useRecoilState(centerSalariesState);

  const wages = getWages(state)
  const salaries = _.get(wages, ['center', centerSalaryDataSource])

  useEffect(() => {
    setCenterSalariesState({ ...centerSalaries, ...salaries })
  }, [centerSalaryDataSource, state]);

}

export default CenterSalariesProvider
