import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import wrapper from './wrapper';
import CenterCardResults from './center/results/CenterCardResults';
import CenterCardCpcResults from './center/results/CenterCardCpcResults';
import FccCardResults from './fcc/results/FccCardResults';
import FccCardCpcResults from './fcc/results/FccCardCpcResults';


const ResultsCard = ({ translate, title, which, orgType }) => {


  let child = null

  if(orgType === 'fcc'){
    child = which === 'cpc' ? <FccCardCpcResults /> : <FccCardResults />;
  } else {
    child = which === 'cpc' ? <CenterCardCpcResults /> : <CenterCardResults />;
  }

  return (
    <Card className="results-card" id="resultsCard">
      <CardHeader
        className="results-card-header"
        title={translate(title)}
      />
      <CardContent >
        {child}
      </CardContent >
    </Card >
  );

}

export default wrapper(ResultsCard);
