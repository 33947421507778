import React from 'react';
import CompareArrows from '@mui/icons-material/CompareArrows';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import wrapper from '../wrapper'
import { displayComparisonState, comparisonCurrentState, comparisonSavedState } from '../../recoil_state'
import {
  useRecoilCallback, useRecoilState, useResetRecoilState, useSetRecoilState, useRecoilValue
} from 'recoil';
import _ from 'lodash'

const Compare = ({ translate, disabled }) => {

  const [comparisonCurrent, setComparisonCurrentState] = useRecoilState((comparisonCurrentState))
  const setDisplayComparisonState = useSetRecoilState(displayComparisonState);
  const comparisonSaved = useRecoilValue(comparisonSavedState);

  const handleSnapShot = useRecoilCallback(({ snapshot }) => async () => {

    const newState = { }

    for (const node of snapshot.getNodes_UNSTABLE()) {
      const value = await snapshot.getPromise(node);
      newState[node.key] = value
    }

    setComparisonCurrentState(newState)

  }, []);

  const resetState = useResetRecoilState(comparisonCurrentState);

  const handleClick = () => {


    if(!_.isEmpty(comparisonCurrent)){
      resetState();
    }

    handleSnapShot();
    setDisplayComparisonState(true);
  }

  const isDisabled = disabled || _.isEmpty(comparisonSaved)

  return (
    <Tooltip disableFocusListener title={translate('compare_results')}>
      <span>
        <IconButton disabled={isDisabled} onClick={handleClick}>
          <CompareArrows />
        </IconButton>
      </span>
    </Tooltip>
  );
}


export default wrapper(Compare);

