import React from 'react';
import { centerWagesTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterWagesResults = () => {

  const value = useRecoilValue(centerWagesTotalState)

  return <ResultsLineItem name="wages" value={value} />

}

export default CenterWagesResults;

