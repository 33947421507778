import React from 'react';
import {
  centerOrTotalState,
  centerQvTotalState,
  centerEpeTotalState,
  centerOccupTotalState,
  centerPmaTotalState,
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import PdfPieChartCard from '../../pdf/PdfPieChartCard';
import wrapper from '../../wrapper'


const CenterExpenseNonPersonnelChartResults = ({ translate, which, cardTitle, pdfDisplayOnly }) => {

  const centerOrTotal = useRecoilValue(centerOrTotalState);
  const centerQvTotal = useRecoilValue(centerQvTotalState);
  const centerEpeTotal = useRecoilValue(centerEpeTotalState);
  const centerOccupTotal = useRecoilValue(centerOccupTotalState);
  const centerPmaTotal = useRecoilValue(centerPmaTotalState);


  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: centerQvTotal, label: translate('quality_variables_short') },
        { id: 1, value: centerEpeTotal, label: translate('education_program_exp_short') },
        { id: 2, value: centerOccupTotal, label: translate('occupancy_short') },
        { id: 3, value: centerPmaTotal, label: translate('program_mgmt_short') },
        { id: 4, value: centerOrTotal, label: translate('op_reserve_short') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180,
      cy: 200
    },
  ]

  const ChartComponent = pdfDisplayOnly ? PdfPieChartCard : PieChartCard;

  return (
    <ChartComponent
      cardTitle={translate(cardTitle)}
      series={series}
      height={380}
      width={380}
    />
  )

}

export default wrapper(CenterExpenseNonPersonnelChartResults)

