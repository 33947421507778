import React from 'react';
import { centerTeacherStaffState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterTeachingStaffResults = () => {

  const value = useRecoilValue(centerTeacherStaffState)

  return <ResultsLineItem name="teaching_staff" value={value} integer/>

}

export default CenterTeachingStaffResults;

