import React from 'react';
import Calculator from './components/Calculator';
import ComparisonDialog from './components/comparison/ComparisonDialog';
import PDFDialog from './components/pdf/PDFDialog';
import DebugButton from './DebugButton';

const debug = process.env.NODE_ENV === 'development' ? <DebugButton /> : null;

function App() {
  return (
    <div className="main">
      <Calculator/>
      <ComparisonDialog/>
      <PDFDialog/>
      {debug}
    </div>
  );
}

export default App;
