import React from 'react';
import { centerFamilyEngagementState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import Stack from '@mui/material/Stack';
import Help from '../Help'
import YesNoSelect  from '../YesNoSelect'

const CenterFamilyEngagement = () => {

  const [centerFamilyEngagement, setCenterFamilyEngagementState] = useRecoilState(centerFamilyEngagementState);

  const handleOnChange = (value) => {
    setCenterFamilyEngagementState(value)
  }

  return (
    <Stack direction="row"
      spacing={1}
      alignItems="center" >
    {/*    <Content contentKey="family_engagement" />*/}
    {/*<Switch*/}
    {/*  value={centerFamilyEngagement}*/}
    {/*  handleOnChange={handleOnChange}*/}
    {/*  label='family_engagement'*/}
    {/*/> */}
    <YesNoSelect handleOnChange={handleOnChange} value={centerFamilyEngagement} titleKey='family_engagement' />
    <Help contentKey="help_family_engagement" />
    </Stack >
  )

}

export default CenterFamilyEngagement

