import React from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '../../css/css.css'
import logo from '../../images/logo.webp'
import NavLinks from './NavLinks'
import NavMenu from './NavMenu'
import wrapper from '../wrapper'
import useMediaQuery from '@mui/material/useMediaQuery';


const Header = ({ translate }) => {


  const isLarge = useMediaQuery('(min-width:900px)');

  const nav = isLarge ? <NavLinks /> : <NavMenu />;

  const year = new Date().getFullYear();

  return (
  <div className="header" >
    <Stack direction="row" spacing={2} >
      <img src={logo} className="logo" />
      {nav}
    </Stack >
    <div className="title-container" >
      <div className="title-box" >
        <Grid container spacing={2}>
          <Grid container item sx={{justifyContent:'center'}}>
            <Typography variant="h4" gutterBottom className="title" >
                {translate('app_title')}
            </Typography >
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body" gutterBottom className="welcome" >
                {translate('header_welcome_content_1')}
            </Typography >
          </Grid>
        </Grid>
      </div >
    </div >
  </div >
  )

}

export default wrapper(Header)
