import React from 'react';
import { centerSickDaysState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const CenterSickDays = () => {

  const [centerSickDays, setCenterSickDaysState] = useRecoilState(centerSickDaysState);

  const handleOnChange = (value) => {
    setCenterSickDaysState(value)
  }

  return (
    <Stack direction="row" spacing={1}>
    <SalaryField
      value={centerSickDays}
      handleOnChange={handleOnChange}
      label='sick_days'
      fullWidth
    />
    <Help contentKey="help_sick_days" />
    </Stack>
  )

}

export default CenterSickDays

