import * as React from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import wrapper from '../wrapper';
import FccSalaries from './FccSalaries';
import FccBenefits from './FccBenefits';
import FccStateAndRatioDisplay from './FccStateAndRatioDisplay';
import FccAdditionalCosts from './FccAdditionalCosts';
import SectionIntro from '../SectionIntro';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(153, 204, 255, .2)'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Fcc = ({ translate }) => {

  return (
    <div >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="section-header"
        >
          {translate('state_fcc_size')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="fcc_section_1_intro" />
          <FccStateAndRatioDisplay />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="section-header"
        >
          {translate('salaries')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="fcc_section_2_intro" helpContentKey="help_fcc_salary"/>
          <FccSalaries />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          className="section-header"
        >
          {translate('benefits')}
        </AccordionSummary >
        <AccordionDetails >
          {/*<SectionIntro contentKey="fcc_section_3_intro" />*/}
          <FccBenefits />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          {translate('enhancements')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="fcc_section_4_intro" />
          <FccAdditionalCosts />
        </AccordionDetails >
      </Accordion >
    </div >
  );
}

export default wrapper(Fcc)

