import React from 'react';
import CenterSizeTable from './CenterSizeTable'
import CenterSizeGrid from './CenterSizeGrid'
import { useRecoilState } from "recoil";
import { centerRatioRegsState } from "../../recoil_state";
import useMediaQuery from '@mui/material/useMediaQuery';

const CenterSizeDisplay = ({ pdfDisplayOnly }) => {

  const isSmall = useMediaQuery('(max-width:565px)');

  const [centerRatioRegs] = useRecoilState(centerRatioRegsState);

  if(!centerRatioRegs){
    return null
  }

  return isSmall ? <CenterSizeGrid /> : <CenterSizeTable pdfDisplayOnly={pdfDisplayOnly} />;

}

export default CenterSizeDisplay
