import React from 'react';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { orgTypeState, centerRatioRegsState, centerInfantState, centerToddlerState, centerThreesState, centerFoursState, centerSchoolState, stateState, fccRatioRegsState } from "../../recoil_state";
import { useRecoilState, useSetRecoilState } from "recoil";
import wrapper from '../wrapper'
import { calcDefaultDataByCenterDataType } from '../../calculations/centerSizeDetermination'
import Help from '../Help'


const RatioAndGroupSizeRegs = (props) => {

  const [state] = useRecoilState(stateState);
  const [orgType] = useRecoilState(orgTypeState);
  const setCenterInfantState = useSetRecoilState(centerInfantState);
  const setCenterToddlerState = useSetRecoilState(centerToddlerState);
  const setCenterThreesState = useSetRecoilState(centerThreesState);
  const setCenterFoursState = useSetRecoilState(centerFoursState);
  const setCenterSchoolState = useSetRecoilState(centerSchoolState);
  const [centerRatioRegs, setCenterRatioRegs] = useRecoilState(centerRatioRegsState);

  const [fccRatioRegs, setFccRatioRegsState] = useRecoilState(fccRatioRegsState);

  const updateState = ({ target: { value } }) => {

     if (orgType === 'center'){
      // store the ratioRegs select value
      setCenterRatioRegs(value)

       //Build and set the 5 age based atoms
       setCenterInfantState(
         calcDefaultDataByCenterDataType(value, state, 'infant')
       )
       setCenterToddlerState(
         calcDefaultDataByCenterDataType(value, state, 'toddler')
       )
       setCenterThreesState(
         calcDefaultDataByCenterDataType(value, state, 'threes')
       )
       setCenterFoursState(
         calcDefaultDataByCenterDataType(value, state, 'fours')
       )
       setCenterSchoolState(
         calcDefaultDataByCenterDataType(value, state, 'school')
       )

    } else {
      setFccRatioRegsState(value)
    }
  };

  let value = null
  let label = null
  let ratioHelp = null

  switch(orgType) {
    case "center":
      value = centerRatioRegs
      ratioHelp = <Help contentKey="help_ratio_group_size" />
      break;
    default:
      value = fccRatioRegs
  }

  switch(orgType) {
    case "center":
      label = props.translate('ratio_regs')
      break;
    default:
      label = props.translate('fcc_ratio_regs')
  }

  const centerOptions = [
    <MenuItem key="licensing" value="licensing">{props.translate('licensing')}</MenuItem>,
    <MenuItem key="cfoc" value="cfoc">{props.translate('cfoc')}</MenuItem>,
    <MenuItem key="user_entered" value="user">{props.translate('user_entered')}</MenuItem>
  ]

  const fccOptions = [
    <MenuItem key="small_fcc" value="small_fcc">{props.translate('small_fcc')}</MenuItem>,
    <MenuItem key="group_fcc" value="group_fcc">{props.translate('group_fcc')}</MenuItem>,
  ]

  return (
    <Stack direction="row"
      spacing={1}
      alignItems="center" >
      <FormControl fullWidth>
        <InputLabel id="ratio-regs-select-label">{label}</InputLabel>
        <Select
          labelId="ratio-regs-select-label"
          id="ratio-regs-select"
          value={value}
          label={label}
          onChange={updateState}
        >
          {orgType === 'center' ? centerOptions : fccOptions}
        </Select>
      </FormControl>
      { ratioHelp }
    </Stack>
  );
}

export default wrapper(RatioAndGroupSizeRegs)
