import React from 'react';
import Grid from '@mui/material/Grid';
import CenterFamilyEngagement from './CenterFamilyEngagement'
import CenterOperatingReserve from './CenterOperatingReserve'

const CenterAdditionalCosts = () => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <CenterFamilyEngagement />
      </Grid >
      <Grid item xs={12} sm={6}>
       <CenterOperatingReserve />
      </Grid >
    </Grid>
  )

}

export default CenterAdditionalCosts

