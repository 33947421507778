import _ from 'lodash'
import { getGroupSizeDefault, getRatioDefault, getClassroomsDefault } from './ratioAndGroupSizeDefaults'


const calcTotals = (data) => {

  return _.reduce(data, (r, v, k) => {

    r.classroom = _.round(_.sum([r.classroom, v.classrooms]), 0)
    r.children = _.round(_.sum([r.children, v.childrenAge]), 0)

    return r;
  }, {
    classroom: 0,
    children: 0
  });

}

export const calcDefaultDataByCenterDataType = (centerRatioRegs, state, age) => {

  const ratios = getRatioDefault(state, centerRatioRegs, age)

  const groupSize = getGroupSizeDefault(state, centerRatioRegs, age)

  const classrooms = getClassroomsDefault(state, centerRatioRegs, age)

  return {
    ratios,
    groupSize,
    classrooms,
    childrenAge: _.round(_.multiply(classrooms, groupSize), 0)
  }

}

export const calcOrgCenterTotals = ({ centerInfant, centerToddler, centerThrees, centerFours, centerSchool }, which) => {

  return _.round(_.sum([centerInfant[which], centerToddler[which], centerThrees[which], centerFours[which], centerSchool[which]]),0)


}
