import React, { useEffect } from 'react';
import {
  centerTotalChildrenState,
  centerTotalSchoolAgeChildrenState,
  centerTotalClassroomsState,
  centerInfantState,
  centerToddlerState,
  centerThreesState,
  centerFoursState,
  centerSchoolState,
  centerTotalSchoolAgeClassroomsState,
  centerPositionCountState,
  centerTotalStaffState,
  centerTeacherStaffState,
  centerSalariesState,
  centerWagesTotalState,
  centerPersonnelTotalState,
  centerSickDaysState,
  centerPaidLeaveState,
  centerMandatoryBenefitsTotalState,
  centerDiscretionaryBenefitsTotalState,
  centerDiscretionaryBenefitsState,
  stateState,
  centerRetirementBenefitsState,
  centerBenefitsTotalState,
  centerFamilyEngagementTotalState,
  centerNonPersonnelTotalState,
  centerOrTotalState,
  centerTotalExpenseState,
  centerQvTotalState,
  centerEpeTotalState,
  centerOccupTotalState,
  centerPmaTotalState,
  centerFamilyEngagementState,
  centerWageGroupsState,
  centerTeachersByGroupState,
  centerAnnualCpcInfantCalculationsState,
  centerAnnualCpcToddlerCalculationsState,
  centerAnnualCpcThreesCalculationsState,
  centerAnnualCpcFoursCalculationsState,
  centerAnnualCpcSchoolAgeCalculationsState,
  centerAnnualCpcInfantState,
  centerAnnualCpcToddlerState,
  centerAnnualCpcThreesState,
  centerAnnualCpcFoursState,
  centerAnnualCpcSchoolAgeState,
  centerRatioRegsState,
  centerWagesByPositionState,
  centerOrPercentageState
} from "../../recoil_state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'
import {
  DAILY_COVERAGE_PERCENTAGE,
  ANNUAL_EMPLOYEE_TRAINING_TIME,
  FICA_SOCIAL_SECURITY_PERCENTAGE,
  MEDICARE_PERCENTAGE,
  WORKERS_COMP_PERCENTAGE,
} from '../../data/statics/pcqc/center'
import { getInsurance } from '../../calculations/stateOverrideData'
import  * as qcp from '../../calculations/qualityCenterProfile'
import { round, roundSum, roundSum2places } from '../../helpers'


const CenterProfileProvider = () => {

  const state = useRecoilValue(stateState);
  const centerInfant = useRecoilValue(centerInfantState);
  const centerToddler = useRecoilValue(centerToddlerState);
  const centerThrees = useRecoilValue(centerThreesState);
  const centerFours = useRecoilValue(centerFoursState);
  const centerSchool = useRecoilValue(centerSchoolState);
  const centerTotalChildren = useRecoilValue(centerTotalChildrenState);
  const centerTotalSchoolAgeChildren = useRecoilValue(centerTotalSchoolAgeChildrenState);
  const centerTotalClassrooms = useRecoilValue(centerTotalClassroomsState);
  const centerTotalSchoolAgeClassrooms = useRecoilValue(centerTotalSchoolAgeClassroomsState);
  const centerSalaries = useRecoilValue(centerSalariesState);
  const centerSickDays = useRecoilValue(centerSickDaysState);
  const centerPaidLeave = useRecoilValue(centerPaidLeaveState);
  const centerDiscretionaryBenefits = useRecoilValue(centerDiscretionaryBenefitsState);
  const centerRetirementBenefits = useRecoilValue(centerRetirementBenefitsState);
  const centerFamilyEngagement = useRecoilValue(centerFamilyEngagementState);
  const centerRatioRegs = useRecoilValue(centerRatioRegsState);
  const centerOrPercentage = useRecoilValue(centerOrPercentageState);

  const [centerPositionCount, setCenterPositionCountState] = useRecoilState(centerPositionCountState);
  const [centerQvTotal, setCenterQvTotalState] = useRecoilState(centerQvTotalState);
  const [centerWageGroups, setCenterWageGroupsState] = useRecoilState(centerWageGroupsState);
  const [centerTeachersByGroup, setCenterTeachersByGroupState] = useRecoilState(centerTeachersByGroupState);
  const [centerAnnualCpcInfantCalculations, setCenterAnnualCpcInfantCalculationsState] = useRecoilState(centerAnnualCpcInfantCalculationsState);
  const [centerAnnualCpcToddlerCalculations, setCenterAnnualCpcToddlerCalculationsState] = useRecoilState(centerAnnualCpcToddlerCalculationsState);
  const [centerAnnualCpcThreesCalculations, setCenterAnnualCpcThreesCalculationsState] = useRecoilState(centerAnnualCpcThreesCalculationsState);
  const [centerAnnualCpcFoursCalculations, setCenterAnnualCpcFoursCalculationsState] = useRecoilState(centerAnnualCpcFoursCalculationsState);
  const [centerAnnualCpcSchoolAgeCalculations, setCenterAnnualCpcSchoolAgeCalculationsState] = useRecoilState(centerAnnualCpcSchoolAgeCalculationsState);

  const setCenterTotalStaffState = useSetRecoilState(centerTotalStaffState);
  const [centerWagesByPosition, setCenterWagesByPositionState] = useRecoilState(centerWagesByPositionState);

  const setCenterTeacherStaffState = useSetRecoilState(centerTeacherStaffState);
  const setCenterWagesTotalState = useSetRecoilState(centerWagesTotalState);
  const setCenterMandatoryBenefitsTotalState = useSetRecoilState(centerMandatoryBenefitsTotalState);
  const setCenterDiscretionaryBenefitsTotalState = useSetRecoilState(centerDiscretionaryBenefitsTotalState);
  const setCenterBenefitsTotalState = useSetRecoilState(centerBenefitsTotalState);
  const setCenterFamilyEngagementTotalState = useSetRecoilState(centerFamilyEngagementTotalState);
  const setCenterNonPersonnelTotalState = useSetRecoilState(centerNonPersonnelTotalState);
  const setCenterOrTotalState = useSetRecoilState(centerOrTotalState);
  const setCenterPersonnelTotalState = useSetRecoilState(centerPersonnelTotalState);
  const setCenterTotalExpenseState = useSetRecoilState(centerTotalExpenseState);
  const setCenterEpeTotalState = useSetRecoilState(centerEpeTotalState);
  const setCenterOccupTotalState = useSetRecoilState(centerOccupTotalState);
  const setCenterPmaTotalState = useSetRecoilState(centerPmaTotalState);
  const setCenterAnnualCpcInfantState = useSetRecoilState(centerAnnualCpcInfantState);
  const setCenterAnnualCpcToddlerState = useSetRecoilState(centerAnnualCpcToddlerState);
  const setCenterAnnualCpcThreesState = useSetRecoilState(centerAnnualCpcThreesState);
  const setCenterAnnualCpcFoursState = useSetRecoilState(centerAnnualCpcFoursState);
  const setCenterAnnualCpcSchoolAgeState = useSetRecoilState(centerAnnualCpcSchoolAgeState);


  /**
   * Determine new position counts
   */

  const newCenterPositionCount = {
    director: qcp.directorCount(centerTotalChildren),
    asstDirector: qcp.asstDirectorCount(centerTotalChildren),
    adminAsst: qcp.asstDirectorCount(centerTotalChildren),
    leadTeacher: centerTotalClassrooms - centerTotalSchoolAgeClassrooms,
    asstTeacher: _.sum([
      qcp.determineTeachersByGroup(centerInfant),
      qcp.determineTeachersByGroup(centerToddler),
      qcp.determineTeachersByGroup(centerThrees),
      qcp.determineTeachersByGroup(centerFours),
    ]) - (centerTotalClassrooms - centerTotalSchoolAgeClassrooms),
    leadTeacherSchoolAge: centerTotalSchoolAgeClassrooms,
    asstTeacherSchoolAge: qcp.determineTeachersByGroup(centerSchool) - centerTotalSchoolAgeClassrooms,
    //substitute: qcp.,
    floaters: null
    //instructionalAides:qcp.
  }

  // calc floaters using lead and asst non school age teachers
  newCenterPositionCount.floaters = (newCenterPositionCount.leadTeacher + newCenterPositionCount.asstTeacher) * DAILY_COVERAGE_PERCENTAGE;


  /**
   * Determine staff count totals
   */

  const newTeacherByGroup = {
    infant: qcp.determineTeachersByGroup(centerInfant),
    toddler: qcp.determineTeachersByGroup(centerToddler),
    threes: qcp.determineTeachersByGroup(centerThrees),
    fours: qcp.determineTeachersByGroup(centerFours),
    schoolAge: qcp.determineTeachersByGroup(centerSchool)
  }

  const newCenterTotalStaff = roundSum2places(_.values(newCenterPositionCount))
  const newCenterTeachingStaff = roundSum2places(_.values(_.omit(newCenterPositionCount, [
    'director',
    'asstDirector',
    'adminAsst',
  ])))

  /**
   * Determine Wages
   */

  const directorWages = qcp.directorCost(centerSalaries.director, centerTotalChildren)
  const asstDirectorWages = qcp.asstDirectorCost(centerSalaries.asstDirector, centerTotalChildren)
  const adminAsstWages = qcp.adminAsstCost(centerSalaries.adminAsst, centerTotalChildren)
  const leadTeacherWages = qcp.leadTeacherCost(centerSalaries.leadTeacher, centerTotalClassrooms, centerTotalSchoolAgeClassrooms)
  const asstTeacherWages = qcp.asstTeacherCost(centerSalaries.asstTeacher, centerTotalClassrooms, centerTotalSchoolAgeClassrooms, newTeacherByGroup)
  const leadSchoolAgeTeacherWages = qcp.leadSchoolAgeTeacherCost(centerSalaries.leadTeacher, centerTotalSchoolAgeClassrooms)
  const asstSchoolAgeTeacherWages = qcp.asstSchoolAgeTeacherCost(centerSalaries.asstTeacher, centerTotalSchoolAgeClassrooms, newTeacherByGroup)
  const floaterWages = qcp.floaterCost(centerSalaries.asstTeacher, newCenterPositionCount.floaters)
  const substituteWages = qcp.substituteCost(centerSalaries.asstTeacher, newCenterTeachingStaff, centerSickDays, centerPaidLeave, ANNUAL_EMPLOYEE_TRAINING_TIME, newCenterTotalStaff)

  //console.log("directorWages", directorWages)
  //console.log("asstDirectorWages", asstDirectorWages)
  //console.log("adminAsstWages", adminAsstWages)
  //console.log("leadTeacherWages", leadTeacherWages)
  //console.log("asstTeacherWages", asstTeacherWages)
  //console.log("leadSchoolAgeTeacherWages", leadSchoolAgeTeacherWages)
  //console.log("asstSchoolAgeTeacherWages", asstSchoolAgeTeacherWages)
  //console.log("floaterWages", floaterWages)

  const wagesTotal = roundSum(
    [
      directorWages.total || 0,
      asstDirectorWages.total || 0,
      adminAsstWages.total || 0,
      leadTeacherWages.total || 0,
      asstTeacherWages.total || 0,
      leadSchoolAgeTeacherWages.total || 0,
      asstSchoolAgeTeacherWages.total || 0,
      floaterWages.total || 0,
      substituteWages.total || 0,
    ]
  );

  const wagesByPosition = {
    director : directorWages.total || 0,
    asstDirector : asstDirectorWages.total || 0,
    adminAsst : adminAsstWages.total || 0,
    leadTeacher : leadTeacherWages.total || 0,
    asstTeacher : asstTeacherWages.total || 0,
    leadSchoolAgeTeacher : leadSchoolAgeTeacherWages.total || 0,
    asstSchoolAgeTeacher : asstSchoolAgeTeacherWages.total || 0,
    floater : floaterWages.total || 0,
    substitute : substituteWages.total || 0,
  };

  const newCenterWageGroups = {
    admin: roundSum([
      directorWages.total || 0,
      asstDirectorWages.total || 0,
      adminAsstWages.total || 0,
    ]),
    teacher: round(leadTeacherWages.total || 0),
    asstTeacher: round(asstTeacherWages.total || 0),
    schoolAgeTeacher: round(leadSchoolAgeTeacherWages.total || 0),
    asstSchoolAgeTeacher: round(asstSchoolAgeTeacherWages.total || 0),
    sub: substituteWages.total || 0,
    floater: floaterWages.total || 0,
  }

  //debugLog(['newCenterWageGroups', newCenterWageGroups])

  /**
   * Determine Mandatory benefits total
   */


  const unemployment = qcp.unemploymentBenefitsCost(substituteWages, newCenterTotalStaff)

  const mandatoryBenefitsTotal = roundSum([
      FICA_SOCIAL_SECURITY_PERCENTAGE * wagesTotal,
      MEDICARE_PERCENTAGE * wagesTotal,
    unemployment,
      WORKERS_COMP_PERCENTAGE * wagesTotal,
    ]
  )

  /**
   * Determine Disc benefits total
   */

  const determineDiscBenefits = () => {

    if(!centerDiscretionaryBenefits){
      return 0
    }

    const insuranceCostPerEmployee = getInsurance(state, 'center') || 0
    const insuranceTotal = insuranceCostPerEmployee * newCenterTotalStaff;
    const retirementPercentage = centerRetirementBenefits/100;
    const retirementTotal = (wagesTotal - substituteWages.total || 0) * retirementPercentage;

    return roundSum([
      insuranceTotal,
      retirementTotal
    ]);

  }

  const discBenefitsTotal = determineDiscBenefits();

  /**
   * Determine Benefits Total
   */


  const benefitsTotal = roundSum([
    mandatoryBenefitsTotal,
    discBenefitsTotal
  ]);


  /**
   * Determine Personnel Total
   */

  const personnelTotal = qcp.personnelCost(wagesTotal, mandatoryBenefitsTotal, discBenefitsTotal)

  /**
   * Determine Family Engagement Cost
   */
  const familyEngagementTotal = qcp.familyEngagementCost(centerFamilyEngagement, centerSalaries.asstTeacher, centerTotalChildren, centerTotalSchoolAgeChildren)

  /**
   * Determine Quality Vars Cost
   *
   * Right now, Family Engagement is the only factor in QV
   */
  const qvTotal = familyEngagementTotal;

  /**
   * Determine NonPersonnel Costs
   */
  const nonPersonnelTotals = qcp.nonPersonnelCost(centerTotalChildren, centerTotalClassrooms, state, qvTotal, newCenterTeachingStaff)

  let nonPersonnelTotal = _.get(nonPersonnelTotals, 'total') || 0;
  const nonEpeTotal = _.get(nonPersonnelTotals, 'epe') || 0;
  const nonOccupTotal = _.get(nonPersonnelTotals, 'occup') || 0;
  const nonPmaTotal = _.get(nonPersonnelTotals, 'pma') || 0;

  /**
   * Determine Operating Reserve Costs, pass in centerOrPercentage for calculations
   */
  const orTotal = qcp.orCost(personnelTotal, nonPersonnelTotal, centerOrPercentage)

  // Add OR into nonPersonnel total vars
  nonPersonnelTotal = round(nonPersonnelTotal + orTotal)
  nonPersonnelTotals.total = nonPersonnelTotal

  /**
   * Determine Total Expense
   */
  const totalExpense = qcp.totalExpense(personnelTotal, nonPersonnelTotal, orTotal)


  /**
   * Determine cpc calcs and values
   */

  //Handle School Age first so we can factor in school age adjustment into the rest

  const initialCenterAnnualCpcSchoolAgeCalculations = qcp.annualCPC(centerWageGroups, centerTotalChildren, centerSchool, newTeacherByGroup, centerTotalClassrooms, newTeacherByGroup.schoolAge, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, null,true);

  /**
   * get the school age adjustment
   */

  const schoolAgeAdjustment = qcp.schoolAgeAdjustment(initialCenterAnnualCpcSchoolAgeCalculations, centerTotalChildren, centerSchool)

  const newCenterAnnualCpcSchoolAgeCalculations = qcp.adjustSchoolAgeCpc(initialCenterAnnualCpcSchoolAgeCalculations, schoolAgeAdjustment, centerSchool)

  const newCenterAnnualCpcInfantCalculations = qcp.annualCPC(centerWageGroups, centerTotalChildren, centerInfant, newTeacherByGroup, centerTotalClassrooms, newTeacherByGroup.infant, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, schoolAgeAdjustment.perChild);
  const newCenterAnnualCpcToddlerCalculations = qcp.annualCPC(centerWageGroups, centerTotalChildren, centerToddler, newTeacherByGroup, centerTotalClassrooms, newTeacherByGroup.toddler, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, schoolAgeAdjustment.perChild);
  const newCenterAnnualCpcThreesCalculations = qcp.annualCPC(centerWageGroups, centerTotalChildren, centerThrees, newTeacherByGroup, centerTotalClassrooms, newTeacherByGroup.threes, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, schoolAgeAdjustment.perChild);
  const newCenterAnnualCpcFoursCalculations = qcp.annualCPC(centerWageGroups, centerTotalChildren, centerFours, newTeacherByGroup, centerTotalClassrooms, newTeacherByGroup.fours, benefitsTotal, nonPersonnelTotals, orTotal, qvTotal, centerSalaries, schoolAgeAdjustment.perChild);

  useEffect(() => {

    /**
     * If we don;t yet have a centerRatioRegs we'll fail so return
     */
    if(!centerRatioRegs){
      return
    }


    /**
     * Set centerPositionCountState
     */
    setCenterPositionCountState({ ...centerPositionCount, ...newCenterPositionCount })

    /**
     * Set Teachers by group state
     */
    setCenterTeachersByGroupState({ ...centerTeachersByGroup, ...newTeacherByGroup})

    /**
     * Set Total and Teacher staff counts
     */
    setCenterTotalStaffState(newCenterTotalStaff);
    setCenterTeacherStaffState(newCenterTeachingStaff);

    /**
     * Set total Wages
     */
    setCenterWagesTotalState(wagesTotal)

    /**
     * Set Wage Groups
     */
    setCenterWageGroupsState({...centerWageGroups, ...newCenterWageGroups})

    /**
     * Set Wage By Position
     */
    setCenterWagesByPositionState({...centerWagesByPosition, ...wagesByPosition})

    /**
     * Set Mandatory benefits Total
     */
    setCenterMandatoryBenefitsTotalState(mandatoryBenefitsTotal)

    /**
     * Set Disc benefits Total
     */
    setCenterDiscretionaryBenefitsTotalState(discBenefitsTotal)

    /**
     * Set Benefits Total
     */
    setCenterBenefitsTotalState(benefitsTotal)

    /**
     * Set Personnel Total
     */
    setCenterPersonnelTotalState(personnelTotal)

    /**
     * Set Family Engagement Total
     */
    setCenterFamilyEngagementTotalState(familyEngagementTotal)

    /**
     * Set Quality Vars Total
     */
    setCenterQvTotalState(qvTotal)

    /**
     * Set EPE Total
     */
    setCenterEpeTotalState(nonEpeTotal)

    /**
     * Set Occupancy Total
     */
    setCenterOccupTotalState(nonOccupTotal)

    /**
     * Set PMA Total
     */
    setCenterPmaTotalState(nonPmaTotal)

    /**
     * Set NonPersonnel Total
     */
    setCenterNonPersonnelTotalState(nonPersonnelTotal)

    /**
     * Set Operating Reserve Total
     */
    setCenterOrTotalState(orTotal)

    /**
     * Set total Expense
     */
    setCenterTotalExpenseState(totalExpense)

    /**
     * Set CPC calcs and values
     */

    setCenterAnnualCpcInfantCalculationsState({ ...centerAnnualCpcInfantCalculations, ...newCenterAnnualCpcInfantCalculations })
    setCenterAnnualCpcToddlerCalculationsState({ ...centerAnnualCpcToddlerCalculations, ...newCenterAnnualCpcToddlerCalculations })
    setCenterAnnualCpcThreesCalculationsState({ ...centerAnnualCpcThreesCalculations, ...newCenterAnnualCpcThreesCalculations })
    setCenterAnnualCpcFoursCalculationsState({ ...centerAnnualCpcFoursCalculations, ...newCenterAnnualCpcFoursCalculations })
    setCenterAnnualCpcSchoolAgeCalculationsState({ ...centerAnnualCpcSchoolAgeCalculations, ...newCenterAnnualCpcSchoolAgeCalculations })

    setCenterAnnualCpcInfantState(newCenterAnnualCpcInfantCalculations.annualCpc)
    setCenterAnnualCpcToddlerState(newCenterAnnualCpcToddlerCalculations.annualCpc)
    setCenterAnnualCpcThreesState(newCenterAnnualCpcThreesCalculations.annualCpc)
    setCenterAnnualCpcFoursState(newCenterAnnualCpcFoursCalculations.annualCpc)
    setCenterAnnualCpcSchoolAgeState(newCenterAnnualCpcSchoolAgeCalculations.annualCpc)

  }, [
    state,
    centerTotalChildren,
    centerTotalClassrooms,
    centerTotalSchoolAgeClassrooms,
    centerInfant,
    centerToddler,
    centerThrees,
    centerFours,
    centerSchool,
    centerSalaries,
    centerSickDays,
    centerPaidLeave,
    centerDiscretionaryBenefits,
    centerRetirementBenefits,
    centerFamilyEngagement,
    centerQvTotal,
    centerRatioRegsState,
    centerOrPercentage
  ]);



}

export default CenterProfileProvider
