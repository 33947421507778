import React from 'react';
import { fccTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccTotalExpenseResults = () => {

  const value = useRecoilValue(fccTotalExpenseState)

  return <ResultsLineItem name="total_expense" value={value} bold={800} />;

}

export default FccTotalExpenseResults;

