/**
 * Cal Constants
 */

export const MAX_SMALL_FCC_CFOC_CHILD_COUNT = 5;
export const MAX_SMALL_FCC_LICENSING_CHILD_COUNT = 8;
export const MAX_GROUP_FCC_CFOC_CHILD_COUNT = 12;
export const MAX_GROUP_FCC_LICENSING_CHILD_COUNT = 16;

export const DAILY_COVERAGE_PERCENTAGE = .2
export const ANNUAL_EMPLOYEE_TRAINING_TIME = 18
export const SALARY_TO_HOURLY_DIVISOR = 2080
export const SCHOOL_AGE_CPC_ADJUSTMENT = .4

export const FICA_SOCIAL_SECURITY_PERCENTAGE = .062
export const MEDICARE_PERCENTAGE = .0145
export const UNEMPLOYMENT_PERCENTAGE = .01
export const UNEMPLOYMENT_MULTIPLIER = 7000
export const WORKERS_COMP_PERCENTAGE = .02

export const FAMILY_ENGAGEMENT_HOURS_PER_CHILD = 2

export const OCCUPANCY_MULTIPLIER = 65
export const OPERATING_RESERVE_FUND_CONTRIBUTION_PERCENTAGE = .05


/**
 * Sheeet: Nonpersonnel PCQC
 */


export const ADVERTISING = 178.36;
export const VEHICLE_EXPENSE = 326.81;
export const DEPRECIATION = 391.96;
export const INSURANCE = 587.4;
export const INTEREST_ON_BIZ_DEBT = 153.79;
export const LEGAL_FEES = 782.84;
export const OFFICE_SUPPLIES = 237.1;
export const REPAIRS = 313.99;
export const SUPPLIES = 593.81;
export const FOOD = 10342.8;
export const TELEPHONE = 1800;
export const TRAINING = 296.9;
export const PROF_MEMBER = 130.3;
export const LICENSE_PERMITS = 130.3;
export const CHILD_ASSESSMENT = 36;
export const EQUIPMENT = 0;
export const PROFESSIONAL_SERVICES = 1200;

export const MORTGAGE_RENT = 18562;
export const HOME_INSURANCE = 1820;
export const REPAIRS_MAINTENANCE = 653;
export const UTILITIES = 3936;
export const HOUSEHOLD_SUPPLIES = 314;

export const HOURS_WORKED = 65;
export const SPACE_IN_HOME = 1200;
export const TOTAL_SPACE = 2400;
export const TIME_SPACE_PERCENT = .19;


/**
 * Multipliers
 */

export const KIDS = 90;

export const CLASSROOMS = 5
