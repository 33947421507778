import React from 'react';
import Grid from '@mui/material/Grid';
import FccOperatingReserve from './FccOperatingReserve'
import FccFamilyEngagement from './FccFamilyEngagement'

const FccAdditionalCosts = () => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
       <FccFamilyEngagement />
      </Grid >
      <Grid item xs={12} sm={6}>
       <FccOperatingReserve />
      </Grid >
    </Grid>
  )

}

export default FccAdditionalCosts

