import * as React from 'react';
import wrapper from './wrapper';
import Help from './Help';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const SectionIntro = ({ translate, contentKey, helpContentKey }) => {

  const help = helpContentKey ? <Help contentKey={helpContentKey} /> : null;


  return (
    <Box className="section-intro">
      <Grid xs={12} >
        {translate(contentKey)}{help}
      </Grid>
    </Box>
  );
}

export default wrapper(SectionIntro)
