import React from 'react';
import Content from '../../Content'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FccInfantCpcResults from './FccInfantCpcResults';
import FccToddlerCpcResults from './FccToddlerCpcResults';
import FccThreesCpcResults from './FccThreesCpcResults';
import FccFoursCpcResults from './FccFoursCpcResults';
import FccSchoolAgeCpcResults from './FccSchoolAgeCpcResults';
import { fccTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const CenterCardResults = () => {


  const value = useRecoilValue(fccTotalExpenseState)

  const results = <Grid container spacing={2}>
      <Container className="cpc-results-container">
          <FccInfantCpcResults />
      </Container>

        <div className="results-line" />
      <Container className="cpc-results-container">

          <FccToddlerCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <FccThreesCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <FccFoursCpcResults />

      </Container>
        <div className="results-line" />
      <Container className="cpc-results-container">

          <FccSchoolAgeCpcResults />

      </Container>




      </Grid>;

  const noResults = <Grid container spacing={2}>
      <Box sx={{ marginTop:2, marginLeft: 2 }}>
        <Content contentKey="results_need_input" />
      </Box>
      </Grid>

  return (
    <Box sx={{ flexGrow: 1 }}>
      {value ? results : noResults}
    </Box >
  )

}

export default CenterCardResults;

