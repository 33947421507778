import React from 'react';
import Content from '../../Content'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FccBenefitsResults from './FccBenefitsResults';
import FccEduProgResults from './FccEduProgResults';
import FccNonPersonnelTotalResults from './FccNonPersonnelTotalResults';
import FccOccupancyResults from './FccOccupancyResults';
import FccOperatingReserveResults from './FccOperatingReserveResults';
import FccPersonnelTotalResults from './FccPersonnelTotalResults';
import FccPgrmMgmtResults from './FccPgrmMgmtResults';
import FccQualityVarResults from './FccQualityVarResults';
import FccTotalExpenseResults from './FccTotalExpenseResults';
import FccTotalStaffResults from './FccTotalStaffResults';
import FccWagesResults from './FccWagesResults';
import { fccTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const FccCardResults = () => {

  const value = useRecoilValue(fccTotalExpenseState)

  const results = <Grid container spacing={2}>

        <FccTotalStaffResults />

        <div className="results-line" />

        <FccWagesResults />
        <FccBenefitsResults />
        <FccPersonnelTotalResults />

        <div className="results-line" />
        <FccQualityVarResults />
        <FccEduProgResults />
        <FccOccupancyResults />
        <FccPgrmMgmtResults />
        <FccOperatingReserveResults />
        <FccNonPersonnelTotalResults />

        <div className="results-line" />
        <FccTotalExpenseResults />
      </Grid>;

  const noResults = <Grid container spacing={2}>
      <Box sx={{ marginTop:2, marginLeft: 2 }}>
        <Content contentKey="results_need_input" />
      </Box>
      </Grid>

  return (
    <Box sx={{ flexGrow: 1 }}>
      {value ? results : noResults}
    </Box >
  )


}

export default FccCardResults;

