import React from 'react';
import { displayPdfState, orgTypeState, stateState, fccRatioRegsState, fccSalaryDataSourceState, fccDiscretionaryBenefitsState, fccRetirementBenefitsState, fccSickDaysState, fccPaidLeaveState, fccFamilyEngagementState, fccOrPercentageState, fccAccreditationState } from '../../recoil_state'
import { useRecoilValue } from 'recoil';
import wrapper from '../wrapper'
import Grid from '@mui/material/Grid';
import FccSizeDisplay from '../fcc/FccSizeDisplay'
import FccCharts from '../fcc/results/FccCharts'
import ResultsCard from '../ResultsCard'

const FccData = ({ translate }) => {

  const orgType = useRecoilValue(orgTypeState)
  const state = useRecoilValue(stateState)
  const displayPdf = useRecoilValue(displayPdfState);
  const fccRatioRegs = useRecoilValue(fccRatioRegsState);
  const fccSalaryDataSource = useRecoilValue(fccSalaryDataSourceState);
  const fccDiscretionaryBenefits = useRecoilValue(fccDiscretionaryBenefitsState);
  const fccRetirementBenefits = useRecoilValue(fccRetirementBenefitsState);
  const fccSickDays = useRecoilValue(fccSickDaysState);
  const fccPaidLeave = useRecoilValue(fccPaidLeaveState);
  const fccFamilyEngagement = useRecoilValue(fccFamilyEngagementState);
  const fccOrPercentage = useRecoilValue(fccOrPercentageState);
  const fccAccreditation = useRecoilValue(fccAccreditationState);

  if(!displayPdf){
    return null;
  }

  const modifiedValue = v => v === true ? 'yes' : 'no';

  return (
    <React.Fragment>
      <Grid container item xs={12} spacing={1} className='pdf-data-grid'>
        <Grid item xs={3}>
          Type:
        </Grid>
        <Grid item xs={3}>
         {translate(orgType)}
        </Grid>
        <Grid item xs={3}>
          {translate('state')}:
        </Grid>
        <Grid item xs={3}> {state}
        </Grid>
        <Grid item xs={3}>
          {translate('fcc_accreditation')}:
        </Grid>
        <Grid item xs={3}> {translate(fccAccreditation)}
        </Grid>
        <Grid item xs={3}>
          {translate('fcc_ratio_regs')}:
        </Grid>
        <Grid item xs={3}> {translate(fccRatioRegs)}
        </Grid>
        <Grid item xs={3}>
          {translate('salary_data_source')}:
        </Grid>
        <Grid item xs={3}> {translate(fccSalaryDataSource)}
        </Grid>
        <Grid item xs={3}>
          {translate('discretionary_benefits')}:
        </Grid>
        <Grid item xs={3}> {translate(modifiedValue(fccDiscretionaryBenefits))}
        </Grid>
        <Grid item xs={3}>
          {translate('retirement_benefits')}:
        </Grid>
        <Grid item xs={3}> {fccRetirementBenefits}%
        </Grid>
        <Grid item xs={3}>
          {translate('sick_days')}:
        </Grid>
        <Grid item xs={3}> {fccSickDays}
        </Grid>
        <Grid item xs={3}>
          {translate('paid_leave')}:
        </Grid>
        <Grid item xs={3}> {fccPaidLeave}
        </Grid>
        <Grid item xs={3}>
          {translate('family_engagement')}:
        </Grid>
        <Grid item xs={3}> {translate(modifiedValue(fccFamilyEngagement))}
        </Grid>
        <Grid item xs={3}>
          {translate('op_reserve_contribution')}:
        </Grid>
        <Grid item xs={3}> {fccOrPercentage}%
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className='pdf-margin pdf-break'>
        <FccSizeDisplay pdfDisplayOnly />
      </Grid>
      <Grid container item xs={12} spacing={2} className='pdf-margin pdf-break'>
        <Grid item xs={6}>
          <ResultsCard title="program_level_results" which="programLevel" orgType={orgType} />
        </Grid>
        <Grid item xs={6}>
          <ResultsCard title="cpc_results" which="cpc" orgType={orgType} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className='pdf-margin'>
        <FccCharts pdfDisplayOnly />
      </Grid>
    </React.Fragment>
  );
}


export default wrapper(FccData);

