import React from 'react';
import Content from './Content'
import Currency from './Currency'
import Grid from '@mui/material/Grid';

const ResultsLineItem = ({ name, value, integer, bold }) => {

  return (
    <>
      <Grid item
        xs={8}
        className="results-grid-left"
      >
        <span >
          <Content contentKey={name} sx={bold ? { fontWeight: bold } : {}} />
        </span >
      </Grid >
      <Grid item
        xs={4}
        className="results-grid-right"
      >
        {integer ? value : <Currency value={value} sx={bold ? { fontWeight: bold } : {}} /> }
      </Grid >
    </>
  )

}

export default ResultsLineItem;

