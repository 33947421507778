import React from 'react';
import { centerPaidLeaveState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const CenterPaidLeave = () => {

  const [centerPaidLeave, setCenterPaidLeaveState] = useRecoilState(centerPaidLeaveState);

  const handleOnChange = (value) => {
    setCenterPaidLeaveState(value)
  }

  return (
    <Stack direction="row" spacing={1}>
    <SalaryField
      value={centerPaidLeave}
      handleOnChange={handleOnChange}
      label='paid_leave'
      fullWidth
    />
  <Help contentKey="help_paid_leave" />
</Stack>
  )

}

export default CenterPaidLeave

