import React from 'react';
import { fccWagesTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccWagesResults = () => {

  const value = useRecoilValue(fccWagesTotalState)

  return <ResultsLineItem name="wages" value={value}/>

}

export default FccWagesResults;

