import React from 'react';
import RatioAndGroupSizeRegs from '../fields/RatioAndGroupSizeRegs';
import State from '../fields/State';
import Grid from '@mui/material/Grid';
import FccSizeDisplay from './FccSizeDisplay';
import FccAccreditation from './FccAccreditation';

const FccStateAndRatioDisplay = () => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
       <State />
      </Grid >
      <Grid item
        xs={12} sm={6} md={4}>
        <RatioAndGroupSizeRegs />
      </Grid >
      <Grid item xs={12} md={4}>
        <FccAccreditation />
      </Grid >
      <Grid item
        xs={12}>
        <FccSizeDisplay />
      </Grid >
    </Grid >
  )

}

export default FccStateAndRatioDisplay

