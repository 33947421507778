import * as React from 'react';
import Stack from '@mui/material/Stack';
import Save from './Save';
import Pdf from './Pdf';
import Compare from './Compare';
import ButtonGroup from '@mui/material/ButtonGroup';
import { centerTotalExpenseState, orgTypeState, fccTotalExpenseState } from '../../recoil_state'
import { useRecoilValue } from 'recoil';

const Actions = () => {

  const orgType = useRecoilValue(orgTypeState);
  const centerValue = useRecoilValue(centerTotalExpenseState);
  const fccValue = useRecoilValue(fccTotalExpenseState);

  let disabled = true;

  if(orgType === 'fcc'){
    disabled = !fccValue;
  } else {
    disabled = !centerValue;
  }

  return (
    <Stack direction="row" spacing={2} className="org-type-selection">
      <ButtonGroup variant="outlined" size="large" >
        <Save disabled={disabled} />
        <Compare disabled={disabled} />
        <Pdf disabled={disabled} />
      </ButtonGroup>
    </Stack>
  )

}

export default  Actions




