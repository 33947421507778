import React from 'react';
import {
  useRecoilValue,
} from "recoil";
import {
  fccTotalChildrenState,
} from "../../recoil_state";


const FccSizeTotals = ({ col }) => {

  const fccTotalChildren = useRecoilValue(fccTotalChildrenState);

  return (
    <span >
      {fccTotalChildren}
    </span >
  )

}

export default FccSizeTotals
