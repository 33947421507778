import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RecoilRoot,
  RecoilEnv
  //atom,
  //selector,
  //useRecoilState,
  //useRecoilValue,
} from 'recoil';
import './index.css';
import App from './App';
import CenterSalariesProvider from './components/providers/CenterSalariesProvider';
import CenterSizeTotalsProvider from './components/providers/CenterSizeTotalsProvider';
import CenterProfileProvider from './components/providers/CenterProfileProvider';
import FccSalariesProvider from './components/providers/FccSalariesProvider';
import FccSizeTotalsProvider from './components/providers/FccSizeTotalsProvider';
import FccProfileProvider from './components/providers/FccProfileProvider';
import StateProvider from './components/StateProvider';
import Header from './components/template/Header';
import Footer from './components/template/Footer';
import CenterRatioGroupSizeProvider from './components/providers/CenterRatioGroupSizeProvider';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import ReactGA from "react-ga4";
import { GA_ID, GA_DEBUG } from './data/config'

ReactGA.initialize(GA_ID, GA_DEBUG ? { debug_mode: true } : {});
ReactGA.send({ hitType: "pageview", page: "", title: "Calc" });

const root = ReactDOM.createRoot(document.getElementById('root'));

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false

let theme = createTheme({
  palette: {
    primary: {
      dark: '#99CCFF',
      light: '#add6ff',
      main: '#6b8eb2',
      contrastText: 'rgba(0, 0, 0, 0.54)'
    },
    secondary: {
      main: '#9999cf',
      light: '#adadd8',
      dark: '#6b6b90',
      contrastText: 'rgba(0, 0, 0, 0.54)'
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 13,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme = responsiveFontSizes(theme)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <RecoilRoot>
      <StateProvider />
      <CenterSalariesProvider />
      <CenterSizeTotalsProvider />
      <CenterProfileProvider />
      <FccSalariesProvider />
      <FccSizeTotalsProvider />
      <FccProfileProvider />
      <CenterRatioGroupSizeProvider />
      <Header />
      <App />
      <Footer />
    </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
