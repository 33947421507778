import React from 'react';
import { fccOrTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccOperatingReserveResults = () => {

  const value = useRecoilValue(fccOrTotalState)

  return <ResultsLineItem name="op_reserve" value={value}/>;

}

export default FccOperatingReserveResults;

