import React, { useEffect } from 'react';
import { stateState, centerInfantState, centerToddlerState, centerThreesState, centerFoursState, centerSchoolState, orgTypeState, centerRatioRegsState } from '../../recoil_state'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { calcDefaultDataByCenterDataType } from '../../calculations/centerSizeDetermination'


const CenterRatioGroupSizeProvider = () => {

  const state = useRecoilValue(stateState);
  const orgType = useRecoilValue(orgTypeState);
  const setCenterInfantState = useSetRecoilState(centerInfantState);
  const setCenterToddlerState = useSetRecoilState(centerToddlerState);
  const setCenterThreesState = useSetRecoilState(centerThreesState);
  const setCenterFoursState = useSetRecoilState(centerFoursState);
  const setCenterSchoolState = useSetRecoilState(centerSchoolState);
  const centerRatioRegs = useRecoilValue(centerRatioRegsState);


  useEffect(() => {

    if (orgType !== 'center'){
      return
    }

    if (centerRatioRegs !== 'licensing'){
      return
    }

    const value = 'licensing';

    setCenterInfantState(
      calcDefaultDataByCenterDataType(value, state, 'infant')
    )
    setCenterToddlerState(
      calcDefaultDataByCenterDataType(value, state, 'toddler')
    )
    setCenterThreesState(
      calcDefaultDataByCenterDataType(value, state, 'threes')
    )
    setCenterFoursState(
      calcDefaultDataByCenterDataType(value, state, 'fours')
    )
    setCenterSchoolState(
      calcDefaultDataByCenterDataType(value, state, 'school')
    )

  }, [state]);

}

export default CenterRatioGroupSizeProvider
