import React from 'react';
import { fccSalariesState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Grid from '@mui/material/Grid';
import wrapper from '../wrapper'

const FccUserEnteredSalaries = ({ translate }) => {

  const [fccSalaries, setFccSalariesState] = useRecoilState(fccSalariesState);

  const handleOnChange = (value, which) => {
    setFccSalariesState({ ...fccSalaries, ...{ [which]: value }})
  }

  return (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <span className="user-enter-salaries-help" >
        {translate('user_entered_salaries_intro')}
      </span>
    </Grid>
    <Grid item xs={12} md={6}>
      <SalaryField
        value={fccSalaries.director}
        handleOnChange={handleOnChange}
        which='director'
        label='provider_owner'
        startAdornment="$"
      />
    </Grid >
    <Grid item xs={12} md={6}>
      <SalaryField
        value={fccSalaries.asstTeacher}
        handleOnChange={handleOnChange}
        which='asstTeacher'
        label='asst_teacher'
        startAdornment="$"
      />
    </Grid>
  </Grid>
  )

}

export default wrapper(FccUserEnteredSalaries)


