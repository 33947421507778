import React from 'react';
import wrapper from '../wrapper'
import Content from '../Content'
import CenterTableCellData from './CenterTableCellData'
import Grid from '@mui/material/Grid';

const CenterSizeGridItem = ({ translate, which }) => {

  const keys = [
    "infant",
    "toddler",
    "threes",
    "fours",
    "school"
  ]

  return (
    <Grid container xs={12} spacing={2}>

      {/* INFANT */}
      <Grid item xs={12} className="center-text">
        <Content contentKey={which} />
      </Grid >
      <Grid container item spacing={2}>
        <Grid item xs={3}>
          <Content contentKey="number_of_classrooms"/>
        </Grid >
        <Grid item xs={3}>
          <Content contentKey="ratios"/>
        </Grid >
        <Grid item xs={3}>
          <Content contentKey="group_size"/>
        </Grid >
        <Grid item xs={3}>
          <Content contentKey="child_count"/>
        </Grid >
      </Grid >
      <Grid container item xs={12}>
        <Grid item xs={3} className="pad5">
          <CenterTableCellData
            row={which}
            col='classrooms'
            numerical
          />
        </Grid >
        <Grid item xs={3} className="pad5">
          <CenterTableCellData
            row={which}
            col='ratios'
            numerical
          />
        </Grid >
        <Grid item xs={3} className="pad5">
          <CenterTableCellData
            row={which}
            col='groupSize'
            numerical
          />
        </Grid >
        <Grid item xs={3} className="pad5">
          <CenterTableCellData
            row={which}
            col='childrenAge'
            numerical
          />
        </Grid >
      </Grid >
      </Grid >
  );
}

export default wrapper(CenterSizeGridItem)
