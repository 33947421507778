import React from 'react';
import { fccRetirementBenefitsState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Stack from '@mui/material/Stack';
import Help from '../Help'

const FccRetirementBenefits = () => {

  const [fccRetirementBenefits, setFccRetirementBenefitsState] = useRecoilState(fccRetirementBenefitsState);

  const handleOnChange = (value) => {
    setFccRetirementBenefitsState(value)
  }

  return (
  <Stack direction="row" spacing={1}>
    <SalaryField
      value={fccRetirementBenefits}
      handleOnChange={handleOnChange}
      label='retirement_benefits'
      endAdornment="%"
      fullWidth
    />
    <Help contentKey="help_fcc_retirement_benefits" />
  </Stack>
  )

}

export default FccRetirementBenefits

