import React from 'react';
import { fccDiscretionaryBenefitsState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import Stack from '@mui/material/Stack';
import wrapper from '../wrapper'
import Help from '../Help'
import YesNoSelect  from '../YesNoSelect'

const FccDiscretionaryBenefits = ({ translate }) => {


  const [fccDiscretionaryBenefits, setFccDiscretionaryBenefitsState] = useRecoilState(fccDiscretionaryBenefitsState);

  const handleOnChange = (value) => {
    setFccDiscretionaryBenefitsState(value)
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" >
      {/*  <Content contentKey="discretionary_benefits" />*/}
      {/*<Switch*/}
      {/*  value={fccDiscretionaryBenefits}*/}
      {/*  handleOnChange={handleOnChange}*/}
      {/*  label='discretionary_benefits'*/}
      {/*/> */}
      <YesNoSelect handleOnChange={handleOnChange} value={fccDiscretionaryBenefits} titleKey='discretionary_benefits' />
      <Help contentKey="help_fcc_discretionary_benefits" />
    </Stack >
  )

}

export default wrapper(FccDiscretionaryBenefits)

