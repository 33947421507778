import React from 'react';
import { centerTotalStaffState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterTotalStaffResults = () => {

  const value = useRecoilValue(centerTotalStaffState)

  return <ResultsLineItem name="total_staff" value={value} integer/>

}

export default CenterTotalStaffResults;

