import React from 'react';
import { fccSalaryDataSourceState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryDataSource from '../fields/SalaryDataSource'

const FccSalaryDataSource = () => {

  const [fccSalaryDataSource, setFccSalaryDataSourceState] = useRecoilState(fccSalaryDataSourceState);

  const handleOnChange = ({ target: { value } }) => {
    setFccSalaryDataSourceState(value)
  }

  return (
    <SalaryDataSource value={fccSalaryDataSource} handleOnChange={handleOnChange} />
  )

}

export default FccSalaryDataSource
