import React from 'react';
import { centerQvTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterQualityVarResults = () => {

  const value = useRecoilValue(centerQvTotalState)

  return <ResultsLineItem name="quality_variables" value={value} />

}

export default CenterQualityVarResults;

