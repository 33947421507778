import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import wrapper from '../wrapper'


const SalaryDataSource = ({ translate, handleOnChange, value }) => {

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="salary_data_source_label">{translate('salary_data_source')}</InputLabel>
        <Select
          labelId="salary_data_source_label"
          id="salary_data_source"
          value={value}
          label={translate('salary_data_source')}
          onChange={handleOnChange}
        >
          <MenuItem value="bls">{translate('bls')}</MenuItem>
          <MenuItem value="livingWage">{translate('living_wage')}</MenuItem>
          <MenuItem value="user">{translate('user_entered')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default wrapper(SalaryDataSource)

