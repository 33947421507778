import * as content from './content'
import * as core from './core'
import * as center from './center'
import * as fcc from './fcc'


export const contentState = content.contentState;
export const stateState = core.stateState;
export const orgTypeState = core.orgTypeState;
export const localeState = core.localeState;
export const comparisonSavedState = core.comparisonSavedState;
export const displayComparisonState = core.displayComparisonState;
export const comparisonCurrentState = core.comparisonCurrentState;
export const displayPdfState = core.displayPdfState;

export const centerRatioRegsState = center.centerRatioRegsState;
export const centerTotalClassroomsState = center.centerTotalClassroomsState;
export const centerTotalChildrenState = center.centerTotalChildrenState;
export const centerTotalSchoolAgeChildrenState = center.centerTotalSchoolAgeChildrenState;
export const centerInfantState = center.centerInfantState;
export const centerToddlerState = center.centerToddlerState;
export const centerThreesState = center.centerThreesState;
export const centerFoursState = center.centerFoursState;
export const centerSchoolState = center.centerSchoolState;
export const centerSalaryDataSourceState = center.centerSalaryDataSourceState;
export const centerSalariesState = center.centerSalariesState;
export const centerPositionCountState = center.centerPositionCountState;
export const centerTeachersByGroupState = center.centerTeachersByGroupState;
export const centerDiscretionaryBenefitsState = center.centerDiscretionaryBenefitsState;
export const centerRetirementBenefitsState = center.centerRetirementBenefitsState;
export const centerSickDaysState = center.centerSickDaysState;
export const centerPaidLeaveState = center.centerPaidLeaveState;
export const centerTotalSchoolAgeClassroomsState = center.centerTotalSchoolAgeClassroomsState;
export const centerFamilyEngagementState = center.centerFamilyEngagementState;
export const centerProfessionalDevelopmentSupportsState = center.centerProfessionalDevelopmentSupportsState;
export const centerPlanningReleaseTimeState = center.centerPlanningReleaseTimeState;
export const centerEduMaterialsState = center.centerEduMaterialsState;
export const centerInclusionMaterialsState = center.centerInclusionMaterialsState;
export const centerInclusionSupportsState = center.centerInclusionSupportsState;
export const centerTotalStaffState = center.centerTotalStaffState;
export const centerTeacherStaffState = center.centerTeacherStaffState;
export const centerWagesTotalState = center.centerWagesTotalState;
export const centerWageGroupsState = center.centerWageGroupsState;
export const centerBenefitsTotalState = center.centerBenefitsTotalState;
export const centerPersonnelTotalState = center.centerPersonnelTotalState;
export const centerQvTotalState = center.centerQvTotalState;
export const centerEpeTotalState = center.centerEpeTotalState;
export const centerOccupTotalState = center.centerOccupTotalState;
export const centerPmaTotalState = center.centerPmaTotalState;
export const centerOrPercentageState = center.centerOrPercentageState;
export const centerOrTotalState = center.centerOrTotalState;
export const centerNonPersonnelTotalState = center.centerNonPersonnelTotalState;
export const centerTotalExpenseState = center.centerTotalExpenseState;
export const centerAnnualCpcInfantState = center.centerAnnualCpcInfantState;
export const centerAnnualCpcToddlerState = center.centerAnnualCpcToddlerState;
export const centerAnnualCpcThreesState = center.centerAnnualCpcThreesState;
export const centerAnnualCpcFoursState = center.centerAnnualCpcFoursState;
export const centerAnnualCpcSchoolAgeState = center.centerAnnualCpcSchoolAgeState;
export const centerAnnualCpcInfantCalculationsState = center.centerAnnualCpcInfantCalculationsState;
export const centerAnnualCpcToddlerCalculationsState = center.centerAnnualCpcToddlerCalculationsState;
export const centerAnnualCpcThreesCalculationsState = center.centerAnnualCpcThreesCalculationsState;
export const centerAnnualCpcFoursCalculationsState = center.centerAnnualCpcFoursCalculationsState;
export const centerAnnualCpcSchoolAgeCalculationsState = center.centerAnnualCpcSchoolAgeCalculationsState;
export const centerMandatoryBenefitsTotalState = center.centerMandatoryBenefitsTotalState;
export const centerDiscretionaryBenefitsTotalState = center.centerDiscretionaryBenefitsTotalState;
export const centerFamilyEngagementTotalState = center.centerFamilyEngagementTotalState;
export const centerWagesByPositionState = center.centerWagesByPositionState;




export const fccRatioRegsState = fcc.fccRatioRegsState;
//export const fccTotalClassroomsState = fcc.fccTotalClassroomsState;
//export const fccTotalSchoolAgeClassroomsState = fcc.fccTotalSchoolAgeClassroomsState;
export const fccTotalChildrenState = fcc.fccTotalChildrenState;
export const fccTotalSchoolAgeChildrenState = fcc.fccTotalSchoolAgeChildrenState;
export const fccInfantState = fcc.fccInfantState;
export const fccToddlerState = fcc.fccToddlerState;
export const fccThreesState = fcc.fccThreesState;
export const fccFoursState = fcc.fccFoursState;
export const fccSchoolState = fcc.fccSchoolState;
export const fccPositionCountState = fcc.fccPositionCountState;
//export const fccTeachersByGroupState = fcc.fccTeachersByGroupState;
export const fccSalariesState = fcc.fccSalariesState;
export const fccSalaryDataSourceState = fcc.fccSalaryDataSourceState;
export const fccDiscretionaryBenefitsState = fcc.fccDiscretionaryBenefitsState;
export const fccRetirementBenefitsState = fcc.fccRetirementBenefitsState;
export const fccSickDaysState = fcc.fccSickDaysState;
export const fccPaidLeaveState = fcc.fccPaidLeaveState;
export const fccTotalStaffState = fcc.fccTotalStaffState;
export const fccTeacherStaffState = fcc.fccTeacherStaffState;
export const fccWagesTotalState = fcc.fccWagesTotalState;
export const fccWageGroupsState = fcc.fccWageGroupsState;
export const fccMandatoryBenefitsTotalState = fcc.fccMandatoryBenefitsTotalState;
export const fccDiscretionaryBenefitsTotalState = fcc.fccDiscretionaryBenefitsTotalState;
export const fccBenefitsTotalState = fcc.fccBenefitsTotalState;
export const fccFamilyEngagementTotalState = fcc.fccFamilyEngagementTotalState;
export const fccPersonnelTotalState = fcc.fccPersonnelTotalState;
export const fccQvTotalState = fcc.fccQvTotalState;
export const fccEpeTotalState = fcc.fccEpeTotalState;
export const fccOccupTotalState = fcc.fccOccupTotalState;
export const fccPmaTotalState = fcc.fccPmaTotalState;
export const fccOrPercentageState = fcc.fccOrPercentageState;
export const fccOrTotalState = fcc.fccOrTotalState;
export const fccNonPersonnelTotalState = fcc.fccNonPersonnelTotalState;
export const fccTotalExpenseState = fcc.fccTotalExpenseState;
export const fccAnnualCpcInfantState = fcc.fccAnnualCpcInfantState;
export const fccAnnualCpcToddlerState = fcc.fccAnnualCpcToddlerState;
export const fccAnnualCpcThreesState = fcc.fccAnnualCpcThreesState;
export const fccAnnualCpcFoursState = fcc.fccAnnualCpcFoursState;
export const fccAnnualCpcSchoolAgeState = fcc.fccAnnualCpcSchoolAgeState;
export const fccAnnualCpcInfantCalculationsState = fcc.fccAnnualCpcInfantCalculationsState;
export const fccAnnualCpcToddlerCalculationsState = fcc.fccAnnualCpcToddlerCalculationsState;
export const fccAnnualCpcThreesCalculationsState = fcc.fccAnnualCpcThreesCalculationsState;
export const fccAnnualCpcFoursCalculationsState = fcc.fccAnnualCpcFoursCalculationsState;
export const fccAnnualCpcSchoolAgeCalculationsState = fcc.fccAnnualCpcSchoolAgeCalculationsState;
export const fccEnrollmentEfficiencyState = fcc.fccEnrollmentEfficiencyState;
export const fccBadDebtEfficiencyState = fcc.fccBadDebtEfficiencyState;
export const fccWagesByPositionState = fcc.fccWagesByPositionState;
export const fccFamilyEngagementState = fcc.fccFamilyEngagementState;
export const fccAccreditationState = fcc.fccAccreditationState;
