import React from 'react';
import { fccOccupTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccOccupancyResults = () => {

  const value = useRecoilValue(fccOccupTotalState)

  return <ResultsLineItem name="occupancy" value={value}/>;

}

export default FccOccupancyResults;

