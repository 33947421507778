import { getMonthly, getWeekly } from '../../helpers'

const config = {

  center:[
    {
      label: 'state',
      atom: 'stateState',
      format: null
    },
    {
      label: 'total_staff',
      atom: 'centerTotalStaffState',
      format: null
    },
    {
      label: 'teaching_staff',
      atom: 'centerTeacherStaffState',
      format: null
    },
    {
      label: 'wages',
      atom: 'centerWagesTotalState',
      format: 'currency'
    },
    {
      label: 'benefits',
      atom: 'centerBenefitsTotalState',
      format: 'currency'
    },
    {
      label: 'total_personnel',
      atom: 'centerPersonnelTotalState',
      format: 'currency'
    },
    {
      label: 'quality_variables',
      atom: 'centerQvTotalState',
      format: 'currency'
    },
    {
      label: 'education_program_exp',
      atom: 'centerEpeTotalState',
      format: 'currency'
    },
    {
      label: 'occupancy',
      atom: 'centerOccupTotalState',
      format: 'currency'
    },
    {
      label: 'program_mgmt',
      atom: 'centerPmaTotalState',
      format: 'currency'
    },
    {
      label: 'op_reserve',
      atom: 'centerOrTotalState',
      format: 'currency'
    },
    {
      label: 'total_nonpersonnel',
      atom: 'centerNonPersonnelTotalState',
      format: 'currency'
    },
    {
      label: 'total_expense_lower',
      atom: 'centerTotalExpenseState',
      format: 'currency'
    },
    {
      label: [
        'annual',
        'infant',
        'cpc'
      ],
      atom: 'centerAnnualCpcInfantState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'infant',
        'cpc'
      ],
      atom: 'centerAnnualCpcInfantState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'infant',
        'cpc'
      ],
      atom: 'centerAnnualCpcInfantState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'annual',
        'toddler',
        'cpc'
      ],
      atom: 'centerAnnualCpcToddlerState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'toddler',
        'cpc'
      ],
      atom: 'centerAnnualCpcToddlerState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'toddler',
        'cpc'
      ],
      atom: 'centerAnnualCpcToddlerState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'threes',
        'cpc'
      ],
      atom: 'centerAnnualCpcThreesState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'threes',
        'cpc'
      ],
      atom: 'centerAnnualCpcThreesState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'threes',
        'cpc'
      ],
      atom: 'centerAnnualCpcThreesState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'fours',
        'cpc'
      ],
      atom: 'centerAnnualCpcFoursState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'fours',
        'cpc'
      ],
      atom: 'centerAnnualCpcFoursState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'fours',
        'cpc'
      ],
      atom: 'centerAnnualCpcFoursState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'school',
        'cpc'
      ],
      atom: 'centerAnnualCpcSchoolAgeState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'school',
        'cpc'
      ],
      atom: 'centerAnnualCpcSchoolAgeState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'school',
        'cpc'
      ],
      atom: 'centerAnnualCpcSchoolAgeState',
      format: 'currency',
      math: getWeekly
    },
  ],
  fcc: [
    {
      label: 'state',
      atom: 'stateState',
      format: null
    },
    {
      label: 'total_staff',
      atom: 'fccTotalStaffState',
      format: null
    },
    {
      label: 'wages',
      atom: 'fccWagesTotalState',
      format: 'currency'
    },
    {
      label: 'benefits',
      atom: 'fccBenefitsTotalState',
      format: 'currency'
    },
    {
      label: 'total_personnel',
      atom: 'fccPersonnelTotalState',
      format: 'currency'
    },
    {
      label: 'quality_variables',
      atom: 'fccQvTotalState',
      format: 'currency'
    },
    {
      label: 'education_program_exp',
      atom: 'fccEpeTotalState',
      format: 'currency'
    },
    {
      label: 'occupancy',
      atom: 'fccOccupTotalState',
      format: 'currency'
    },
    {
      label: 'program_mgmt',
      atom: 'fccPmaTotalState',
      format: 'currency'
    },
    {
      label: 'op_reserve',
      atom: 'fccOrTotalState',
      format: 'currency'
    },
    {
      label: 'total_nonpersonnel',
      atom: 'fccNonPersonnelTotalState',
      format: 'currency'
    },
    {
      label: 'total_expense_lower',
      atom: 'fccTotalExpenseState',
      format: 'currency'
    },
    {
      label: [
        'annual',
        'infant',
        'cpc'
      ],
      atom: 'fccAnnualCpcInfantState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'infant',
        'cpc'
      ],
      atom: 'fccAnnualCpcInfantState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'infant',
        'cpc'
      ],
      atom: 'fccAnnualCpcInfantState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'annual',
        'toddler',
        'cpc'
      ],
      atom: 'fccAnnualCpcToddlerState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'toddler',
        'cpc'
      ],
      atom: 'fccAnnualCpcToddlerState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'toddler',
        'cpc'
      ],
      atom: 'fccAnnualCpcToddlerState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'threes',
        'cpc'
      ],
      atom: 'fccAnnualCpcThreesState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'threes',
        'cpc'
      ],
      atom: 'fccAnnualCpcThreesState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'threes',
        'cpc'
      ],
      atom: 'fccAnnualCpcThreesState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'fours',
        'cpc'
      ],
      atom: 'fccAnnualCpcFoursState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'fours',
        'cpc'
      ],
      atom: 'fccAnnualCpcFoursState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'fours',
        'cpc'
      ],
      atom: 'fccAnnualCpcFoursState',
      format: 'currency',
      math: getWeekly
    },
    {
      label: [
        'annual',
        'school',
        'cpc'
      ],
      atom: 'fccAnnualCpcSchoolAgeState',
      format: 'currency'
    },
    {
      label: [
        'monthly',
        'school',
        'cpc'
      ],
      atom: 'fccAnnualCpcSchoolAgeState',
      format: 'currency',
      math: getMonthly
    },
    {
      label: [
        'weekly',
        'school',
        'cpc'
      ],
      atom: 'fccAnnualCpcSchoolAgeState',
      format: 'currency',
      math: getWeekly
    },
  ]

}


export default config;
