import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ComparisonTable from './ComparisonTable';
import { displayComparisonState, comparisonCurrentState } from '../../recoil_state'
import { useRecoilState, useResetRecoilState } from 'recoil';
import wrapper from '../wrapper'

const ComparisonDialog = ({ translate }) => {

  const [displayComparison, setDisplayComparisonState] = useRecoilState(displayComparisonState);

  const resetState = useResetRecoilState(comparisonCurrentState);

  const handleClose = () => {
    setDisplayComparisonState(false);
    resetState()
  };


  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={displayComparison}
        onClose={handleClose}
      >
        <DialogTitle>{translate('cost_model_comparisons')}</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%',
            }}
          >
<ComparisonTable />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default wrapper(ComparisonDialog);
