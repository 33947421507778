import _ from 'lodash'
import { SALARY_TO_HOURLY_DIVISOR } from '../data/statics/pcqc/center'
import states from '../data/states.json'

export const round = (value) => {
  const v = _.round(value, 0);
  return _.isNaN(v) ? 0 : v
}

export const roundSum = (value) => {
  const v =_.round(_.sum(value), 0)
  return _.isNaN(v) ? 0 : v
}

export const roundSum2places = (value) => {
  const v =_.round(_.sum(value), 1)
  return _.isNaN(v) ? 0 : v
}

export const halfPerFifty = (value) => {
  return Math.ceil(value / 49.9999999) * .5;
}

export const salaryToHourly = (salary) => {
  return _.round(salary/SALARY_TO_HOURLY_DIVISOR, 2)
}

export const getStateName = (code) => {
  const obj = _.find(states, ['code', code])
  return _.get(obj, 'name') || code;
}

export const debugLog = (values) => {

  if(process.env.NODE_ENV !== 'development'){
    return
  }

  let vals = [...values]

  //vals.forEach(v => console.log(v))

}

export const getMonthly = (v) => round(v / 12);
export const getWeekly = (v) => round(v / 52);
