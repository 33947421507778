import {
  round,
  salaryToHourly,
  roundSum,
} from '../helpers'
import _ from 'lodash'
import {
  ADVERTISING,
  VEHICLE_EXPENSE,
  DEPRECIATION,
  INSURANCE,
  INTEREST_ON_BIZ_DEBT,
  LEGAL_FEES,
  OFFICE_SUPPLIES,
  REPAIRS,
  SUPPLIES,
  FOOD,
  TELEPHONE,
  TRAINING,
  PROF_MEMBER,
  LICENSE_PERMITS,
  CHILD_ASSESSMENT,
  PROFESSIONAL_SERVICES,
  MORTGAGE_RENT,
  HOME_INSURANCE,
  REPAIRS_MAINTENANCE,
  UTILITIES,
  HOUSEHOLD_SUPPLIES,
  HOURS_WORKED,
  SPACE_IN_HOME,
  TOTAL_SPACE,
  UNEMPLOYMENT_PERCENTAGE,
  UNEMPLOYMENT_MULTIPLIER,
  FAMILY_ENGAGEMENT_HOURS_PER_CHILD,
  SCHOOL_AGE_CPC_ADJUSTMENT,
} from '../data/statics/pcqc/fcc';
import costOfLiving from '../data/costOfLiving.json'


/**
 * NOTES
 *
 * Group Size = Number of Children in a classroom
 * Ratios = ratio of children to teacher - e.g. 1 = 1 teacher for every child, 4 = 1 teacher for every 4 children
 * Children determined by classrooms * group size
 * Teachers calculated by Children divided by ratio
 *
 */


/**
 * Expenses: Personnel
 */


/**
 * Costs
 */

/**
 * Program Director
 * Qty: 1
 * Cell: E18
 * Atom: centerSalariesState
 * key: director
 */

export const directorCost = (salary = 0, positionCount = 0) => {
  try {

    return {
      unit: salary,
      total: round(salary * positionCount),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Asst Teacher
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E24
 * Atom: centerSalariesState
 * key: asstTeacher
 */

export const asstTeacherCost = (salary = 0, positionCount = 0) => {
  try {
    return {
      unit: salary,
      total: round(salary * positionCount),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Floater Assistants
 * Qty: 1 per Infant-Fours classrooms
 * Cell: E26
 * Atom: centerSalariesState
 * key: substitute
 */

export const floaterCost = (salary = 0, floaterCount) => {
  try {

    return {
      unit: salary,
      total: round(salary * floaterCount),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Substitute Costs
 */
export const substituteCost = (salary = 0, totalStaff, sickDays, paidLeave, trainingTime) => {
  try {

    // Convert sal to hourly rate
    const hourlyRate = salaryToHourly(salary)

    const paidLeaveSubsCost = totalStaff * (paidLeave * 8) * hourlyRate;
    const sickDaysSubsCost = totalStaff * (sickDays * 8) * hourlyRate;
    const trainingTimeSubsCost = (totalStaff * trainingTime) * hourlyRate;

    return {
      paidLeaveSubsCost,
      sickDaysSubsCost,
      unit: salary,
      total: roundSum([
        paidLeaveSubsCost,
        sickDaysSubsCost,
        trainingTimeSubsCost
      ]),
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Personnel Cost
 * Sum of wages, mandatory and discretionary benefits
 */

export const personnelCost = (wagesTotal = 0, mandatoryBenefitsTotal = 0, discBenefitsTotal = 0) => {
  return roundSum([
    wagesTotal,
    mandatoryBenefitsTotal,
    discBenefitsTotal
  ])
}

/**
 * Unemployment Benefits Cost
 * UNEMPLOYMENT Percentage * (UNEMPLOYMENT_MULTIPLIER * Total Staff)
 */

export const unemploymentBenefitsCost = (substituteWages, centerTotalStaff) => {

  const subsSum = _.sum([
    substituteWages.paidLeaveSubsCost,
    substituteWages.sickDaysSubsCost
  ])

  return ((UNEMPLOYMENT_MULTIPLIER * centerTotalStaff) + substituteWages.total) * UNEMPLOYMENT_PERCENTAGE

}

/**
 * Family Engagement
 * Qty: 2 hours per child (omitting school age children) paid at floater/sub hourly
 * Cell: E26
 * Atom: centerFamilyEngagementTotalState
 * key: substitute
 */

export const familyEngagementCost = (centerFamilyEngagement, salary = 0, centerTotalChildren = 0) => {
  try {

    if (!centerFamilyEngagement) {
      return 0;
    }

    // Convert sal to hourly rate
    const hourlyRate = salaryToHourly(salary)

    return round(hourlyRate * centerTotalChildren * FAMILY_ENGAGEMENT_HOURS_PER_CHILD);

  } catch (e) {
    console.error(e)
  }
}

/**
 * NonPersonnel Totals
 * Adjust the following by state based COLA.  Use static vals for rest
 *  EDU_CHILD_FOOD, EDU_CHILD_ADVERTISING, EDU_STAFF_PERSONNEL, OCC_RENT, OCC_OTHER, OCC_MAINT,
 *  PM_PHONE,  PM_INSURANCE, PM_LEGAL, PM_FEES
 */

export const nonPersonnelCost = (totalChildren = 0, state, qvTotal) => {

  const cola = _.get(costOfLiving, state) || 1

  // admin

  const adminCosts = (_.sum([
    ADVERTISING * cola,
    VEHICLE_EXPENSE,
    DEPRECIATION,
    INSURANCE * cola,
    INTEREST_ON_BIZ_DEBT,
    LEGAL_FEES,
    OFFICE_SUPPLIES,
    REPAIRS,
    TELEPHONE * cola,
    PROF_MEMBER,
    LICENSE_PERMITS * cola,
  ]) / 6) * totalChildren;

  // edu

  const eduCosts = (_.sum([
    SUPPLIES,
    FOOD * cola,
    TRAINING,
    //CHILD_ASSESSMENT * totalChildren,
    CHILD_ASSESSMENT * 6,
    PROFESSIONAL_SERVICES * cola,
  ])/ 6) * totalChildren;

  // Facility

  const facilityCosts = ((_.sum([
    MORTGAGE_RENT,
    HOME_INSURANCE,
    REPAIRS_MAINTENANCE,
    UTILITIES,
    HOUSEHOLD_SUPPLIES,
  ]) * cola)/ 6) * totalChildren;

  const totalBiz = _.sum([
    adminCosts,
    eduCosts
  ]);

  const timeAndSpacePercent = (HOURS_WORKED/(24*7))*(SPACE_IN_HOME/TOTAL_SPACE)

  const sharedExpense = facilityCosts * timeAndSpacePercent;

  const total = roundSum([
    totalBiz,
    sharedExpense,
    qvTotal
  ])

  return {
    total,
    epe: round(eduCosts),
    occup: round(sharedExpense),
    pma: round(adminCosts)
  }

}

/**
 * Operating Reserve
 * Total of Personnel and NonPersonnel expenses * fccOrPercentage
 */

export const orCost = (personnelTotal = 0, nonPersonnelTotal = 0, fccOrPercentage = 0) => {

  const orp = fccOrPercentage/100;

  return round(
    (personnelTotal + nonPersonnelTotal) * orp
  )
}

/**
 * Total Expense
 * Total of Personnel and NonPersonnel expenses and Operating Reserve
 */

export const totalExpense = (personnelTotal = 0, nonPersonnelTotal = 0, orTotal = 0) => {
  return roundSum(
    [personnelTotal, nonPersonnelTotal, orTotal]
  )
}


/**
 * Annual Cost per infant
 *
 */

export const annualCPC = (totalChildren = 0, totalExpense = 0, childGroupState, schoolAgeAdjustmentPerChild, isSchoolAge = false) => {

   let totalGroupCost = null;
   let annualCpc = null;

  const totalGroupCostUnadjusted = round((totalExpense/totalChildren) * childGroupState.childrenAge);
  const annualCpcUnadjusted = round(totalGroupCostUnadjusted / childGroupState.childrenAge)

  if(!schoolAgeAdjustmentPerChild){
    totalGroupCost = totalGroupCostUnadjusted;
    annualCpc = annualCpcUnadjusted;
  }else{
    totalGroupCost = roundSum([
      totalGroupCostUnadjusted,
      schoolAgeAdjustmentPerChild * childGroupState.childrenAge
    ])
    annualCpc = round(totalGroupCost / childGroupState.childrenAge)
  }

  return {
    totalGroupCost,
    annualCpc,
    totalGroupCostUnadjusted,
    annualCpcUnadjusted,
  }

}

/**
 * School Age Adjustment
 * School age cpc totals - school age teacher salaries (already accounted for) /
 */

export const schoolAgeAdjustment = (schoolAgeCpc, centerTotalChildren, centerSchool) => {

  const { totalGroupCost } = schoolAgeCpc

  const adjustment = totalGroupCost * SCHOOL_AGE_CPC_ADJUSTMENT;

  // cpc for adjustment - all kids excluding school age kids

  return {
    perChild: round(adjustment / (centerTotalChildren - centerSchool.childrenAge)),
    total: adjustment
  }

}

/**
 * Updates school age cpc to account for school age adjustment subtractions
 */

export const adjustSchoolAgeCpc = (cpcCalcs, schoolAgeAdjustment, centerChildGroupState) => {

  const { totalGroupCostUnadjusted, annualCpcUnadjusted } = cpcCalcs

  const totalGroupCost = round(
    totalGroupCostUnadjusted - schoolAgeAdjustment.total
  )
  const annualCpc = round(totalGroupCost / centerChildGroupState.childrenAge)

  return {
    ...cpcCalcs,
    totalGroupCost,
    annualCpc,
  }

}
