import React from 'react';
import Content from '../../Content'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CenterBenefitsResults from './CenterBenefitsResults';
import CenterEduProgResults from './CenterEduProgResults';
import CenterNonPersonnelTotalResults from './CenterNonPersonnelTotalResults';
import CenterOccupancyResults from './CenterOccupancyResults';
import CenterOperatingReserveResults from './CenterOperatingReserveResults';
import CenterPersonnelTotalResults from './CenterPersonnelTotalResults';
import CenterPgrmMgmtResults from './CenterPgrmMgmtResults';
import CenterQualityVarResults from './CenterQualityVarResults';
import CenterTeachingStaffResults from './CenterTeachingStaffResults';
import CenterTotalExpenseResults from './CenterTotalExpenseResults';
import CenterTotalStaffResults from './CenterTotalStaffResults';
import CenterWagesResults from './CenterWagesResults';
import { centerTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const CenterCardResults = () => {

  const value = useRecoilValue(centerTotalExpenseState)

  const results = <Grid container spacing={2}>

        <CenterTotalStaffResults />
        <CenterTeachingStaffResults />

        <div className="results-line" />

        <CenterWagesResults />
        <CenterBenefitsResults />
        <CenterPersonnelTotalResults />

        <div className="results-line" />
        <CenterQualityVarResults />
        <CenterEduProgResults />
        <CenterOccupancyResults />
        <CenterPgrmMgmtResults />
        <CenterOperatingReserveResults />
        <CenterNonPersonnelTotalResults />

        <div className="results-line" />
        <CenterTotalExpenseResults />
      </Grid>;

  const noResults = <Grid container spacing={2}>
      <Box sx={{ marginTop:2, marginLeft: 2 }}>
        <Content contentKey="results_need_input" />
      </Box>
      </Grid>

  return (
    <Box sx={{ flexGrow: 1 }}>
      {value ? results : noResults}
    </Box >
  )

}

export default CenterCardResults;

