import React from 'react';
import { displayPdfState, orgTypeState } from '../../recoil_state'
import { useRecoilValue } from 'recoil';
import PdfDisplay from './PdfDisplay'

const PdfGenerator = () => {

  const displayPdf = useRecoilValue(displayPdfState);

  if(!displayPdf){
    return null;
  }


  return (
    <React.Fragment>
      <PdfDisplay />
    </React.Fragment>
  );
}


export default PdfGenerator;

