import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import wrapper from '../wrapper';


const PdfCard = ({ title, children, translate }) => {

  return (
    <Card className="results-card" id="resultsCard">
      <CardHeader
        className="results-card-header"
        title={translate(title)}
      />
      <CardContent >
        {children}
      </CardContent >
    </Card >
  );

}

export default wrapper(PdfCard);
