import React from 'react';
import { fccQvTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccQualityVarResults = () => {

  const value = useRecoilValue(fccQvTotalState)

  return <ResultsLineItem name="quality_variables" value={value}/>;

}

export default FccQualityVarResults;

