import ReactGA from "react-ga4";
import en from '../data/content/en.json'
import _ from 'lodash'

const translate = (contentKey) => {
  return _.get(en, [contentKey]) || contentKey
}

const send = (action, params) => {

  return ReactGA.event(action, params);

}





export const entryState = (v) => send("entry_state", {
  state: v,
});

export const stateSelect = (v) => send("state_change", {
  state: v,
});

export const orgTypeSelect = (v) => send("organization_change", {
  organization_type: translate(v),
});

export const centerGroupSizeSelect = (v) => send("center_ratio_regs_change", {
  center_ratio_regs: translate(v),
  organization_type: translate('center')
});

export const fccSizeSelect = (v) => send("fcc_size_change", {
  fcc_size: translate(v),
  organization_type: translate('fcc')
});

export const fccAccreditationSelect = (v) => send("fcc_accreditation_change", {
  fcc_size_regulations: translate(v),
  organization_type: translate('fcc')
});

const salariesSelect = (v, o={}) => send("salaries_change", {
  salary_base: translate(v),
  ...o
});

export const centerSalariesSelect = (v) => salariesSelect(v, {
  organization_type: translate('center')
});

export const fccSalariesSelect = (v) => salariesSelect(v, {
  organization_type: translate('fcc')
});



const familyEngagementSelect = (v, o={}) => send("family_engagement_change", {
  family_engagement_enabled: v ? 'true' : 'false',
  ...o
});

export const centerFamilyEngagementSelect = (v) => familyEngagementSelect(v, {
  organization_type: translate('center')
});

export const fccFamilyEngagementSelect = (v) => familyEngagementSelect(v, {
  organization_type: translate('fcc')
});



const discBenefitsSelect = (v) => send("discretionary_benefits_change", {
  discretionary_benefits_enabled: v ? 'true' : 'false',
});

export const centerDiscBenefitsSelect = (v) => discBenefitsSelect(v, {
  organization_type: translate('center')
});

export const fccDiscBenefitsSelect = (v) => discBenefitsSelect(v, {
  organization_type: translate('fcc')
});



export const savedForComparison = () => send("click", {
  action: 'Save for Comparison',
});

export const viewComparison = () => send("click", {
  action: 'View Comparison',
});

export const viewPdf = () => send("click", {
  action: 'View PDF',
});

export const technicalMethodology = () => send("click", {
  action: 'Technical Methodology',
});
