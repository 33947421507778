import React from "react";
import { localeState, contentState } from "../recoil_state";
import { useRecoilState } from "recoil";
import _ from 'lodash'

const UpdatedComponent = (OriginalComponent) => {
  function NewComponent(props) {
    //render OriginalComponent and pass on its props.

    const [locale] = useRecoilState(localeState);
    const [content] = useRecoilState(contentState);

    const translate = (contentKey) => {
      return _.get(content, [locale, contentKey]) || contentKey
    }


    return <OriginalComponent {...props} translate={translate} />;
  }
  return NewComponent;
};
export default UpdatedComponent;
