import {
  atom,
} from "recoil";
import { centerGroupSizeSelect, centerDiscBenefitsSelect, centerFamilyEngagementSelect, centerSalariesSelect } from '../helpers/analytics'

export const centerRatioRegsState = atom({
  key: "centerRatioRegsState",
  default: "",
  effects: [
    ({onSet}) => {
      onSet(v => {
        centerGroupSizeSelect(v)
      });
    },
  ]
});

export const centerTotalClassroomsState = atom({
  key: "centerTotalClassroomsState",
  default: 0
});

export const centerTotalSchoolAgeClassroomsState = atom({
  key: "centerTotalSchoolAgeClassroomsState",
  default: 0
});

export const centerTotalChildrenState = atom({
  key: "centerTotalChildrenState",
  default: 0
});

export const centerTotalSchoolAgeChildrenState = atom({
  key: "centerTotalSchoolAgeChildrenState",
  default: 0
});

export const centerInfantState = atom({
  key: "centerInfantState",
  default: {
    classrooms: 1,
    ratios: null,
    groupSize: null,
    childrenAge: null,
  },
  effects: [
  ({onSet}) => {
    onSet(newID => {
      //console.debug("centerInfantState", newID);
    });
  },
],
});

export const centerToddlerState = atom({
  key: "centerToddlerState",
  default: {
    classrooms: 1,
    ratios: null,
    groupSize: null,
    childrenAge: null,
  }
});

export const centerThreesState = atom({
  key: "centerThreesState",
  default: {
    classrooms: 1,
    ratios: null,
    groupSize: null,
    childrenAge: null,
  }
});

export const centerFoursState = atom({
  key: "centerFoursState",
  default: {
    classrooms: 1,
    ratios: null,
    groupSize: null,
    childrenAge: null,
  }
});

export const centerSchoolState = atom({
  key: "centerSchoolState",
  default: {
    classrooms: 1,
    ratios: null,
    groupSize: null,
    childrenAge: null,
  }
});

export const centerPositionCountState = atom({
  key: "centerPositionCountState",
  default: {
    director: 0,
    asstDirector: 0,
    adminAsst: 0,
    leadTeacher: 0,
    asstTeacher: 0,
    leadTeacherSchoolAge: 0,
    asstTeacherSchoolAge:0,
    substitute: 0,
    floaters: 0,
    instructionalAides: 0
  },
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("New Positions", newID);
      });
    },
  ],
});

export const centerTeachersByGroupState = atom({
  key: "centerTeachersByGroupState",
  default: {
    infant: 0,
    toddler: 0,
    threes: 0,
    fours: 0,
    schoolAge: 0
  },
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerTeachersByGroupState", newID);
      });
    },
  ],
});

export const centerSalariesState = atom({
  key: "centerSalariesState",
  default: {
    director: null,
    asstDirector: null,
    adminAsst: null,
    leadTeacher: null,
    asstTeacher: null,
    substitute: null,
  }
});

export const centerSalaryDataSourceState = atom({
  key: "centerSalaryDataSourceState",
  default: "",
  effects: [
    ({onSet}) => {
      onSet(v => {
        centerSalariesSelect(v)
      });
    },
  ]
});

export const centerDiscretionaryBenefitsState = atom({
  key: "centerDiscretionaryBenefitsState",
  default: true,
  effects: [
    ({onSet}) => {
      onSet(v => {
        centerDiscBenefitsSelect(v)
      });
    },
  ]
});

export const centerRetirementBenefitsState = atom({
  key: "centerRetirementBenefitsState",
  default: 0,
});

export const centerSickDaysState = atom({
  key: "centerSickDaysState",
  default: 10,
});

export const centerPaidLeaveState = atom({
  key: "centerPaidLeaveState",
  default: 10,
});

export const centerFamilyEngagementState = atom({
  key: "centerFamilyEngagementState",
  default: true,
  effects: [
    ({onSet}) => {
      onSet(v => {
        centerFamilyEngagementSelect(v)
      });
    },
  ]
});

export const centerProfessionalDevelopmentSupportsState = atom({
  key: "centerProfessionalDevelopmentSupportsState",
  default: true,
});

export const centerPlanningReleaseTimeState = atom({
  key: "centerPlanningReleaseTimeState",
  default: true,
});

export const centerEduMaterialsState = atom({
  key: "centerEduMaterialsState",
  default: true,
});

export const centerInclusionMaterialsState = atom({
  key: "centerInclusionMaterialsState",
  default: true,
});

export const centerInclusionSupportsState = atom({
  key: "centerInclusionSupportsState",
  default: true,
});

export const centerTotalStaffState = atom({
  key: "centerTotalStaffState",
  default: 0,
});

export const centerTeacherStaffState = atom({
  key: "centerTeacherStaffState",
  default: 0,
});

export const centerWagesTotalState = atom({
  key: "centerWagesTotalState",
  default: 0,
  effects: [
  ({onSet}) => {
    onSet(newID => {
      //console.debug("Wages", newID);
    });
  },
],
});

export const centerWageGroupsState = atom({
  key: "centerWageGroupsState",
  default: {
    admin: 0,
    teacher: 0,
    schoolAgeTeacher: 0,
    subFloater: 0
  },
  effects: [
  ({onSet}) => {
    onSet(newID => {
      //console.debug("Wages", newID);
    });
  },
],
});

export const centerWagesByPositionState = atom({
  key: "centerWagesByPositionState",
  default: {},
  effects: [
  ({onSet}) => {
    onSet(newID => {
      //console.debug("Wages", newID);
    });
  },
],
});

export const centerMandatoryBenefitsTotalState = atom({
  key: "centerMandatoryBenefitsTotalState",
  default: 0,
});

export const centerDiscretionaryBenefitsTotalState = atom({
  key: "centerDiscretionaryBenefitsTotalState",
  default: 0,
});

export const centerBenefitsTotalState = atom({
  key: "centerBenefitsTotalState",
  default: 0,
});

export const centerFamilyEngagementTotalState = atom({
  key: "centerFamilyEngagementTotalState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerFamilyEngagementTotalState", newID);
      });
    },
  ],
});

export const centerPersonnelTotalState = atom({
  key: "centerPersonnelTotalState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerPersonnelTotalState", newID);
      });
    },
  ],
});

export const centerQvTotalState = atom({
  key: "centerQvTotalState",
  default: 0,
});

export const centerEpeTotalState = atom({
  key: "centerEpeTotalState",
  default: 0,
});

export const centerOccupTotalState = atom({
  key: "centerOccupTotalState",
  default: 0,
});

export const centerPmaTotalState = atom({
  key: "centerPmaTotalState",
  default: 0,
});

export const centerOrPercentageState = atom({
  key: "centerOrPercentageState",
  default: 5,
});

export const centerOrTotalState = atom({
  key: "centerOrTotalState",
  default: 0,
});

export const centerNonPersonnelTotalState = atom({
  key: "centerNonPersonnelTotalState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerNonPersonnelTotalState", newID);
      });
    },
  ],
});

export const centerTotalExpenseState = atom({
  key: "centerTotalExpenseState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerTotalExpenseState", newID);
      });
    },
  ],
});

export const centerAnnualCpcInfantState = atom({
  key: "centerAnnualCpcInfantState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("centerAnnualCpcInfantState", newID);
      });
    },
  ],
});

export const centerAnnualCpcToddlerState = atom({
  key: "centerAnnualCpcToddlerState",
  default: 0,
});

export const centerAnnualCpcThreesState = atom({
  key: "centerAnnualCpcThreesState",
  default: 0,
});

export const centerAnnualCpcFoursState = atom({
  key: "centerAnnualCpcFoursState",
  default: 0,
});

export const centerAnnualCpcSchoolAgeState = atom({
  key: "centerAnnualCpcSchoolAgeState",
  default: 0,
});

export const centerAnnualCpcInfantCalculationsState = atom({
  key: "centerAnnualCpcInfantCalculationsState",
  default: {},
});

export const centerAnnualCpcToddlerCalculationsState = atom({
  key: "centerAnnualCpcToddlerCalculationsState",
  default: {},
});

export const centerAnnualCpcThreesCalculationsState = atom({
  key: "centerAnnualCpcThreesCalculationsState",
  default: {},
});

export const centerAnnualCpcFoursCalculationsState = atom({
  key: "centerAnnualCpcFoursCalculationsState",
  default: {},
});

export const centerAnnualCpcSchoolAgeCalculationsState = atom({
  key: "centerAnnualCpcSchoolAgeCalculationsState",
  default: {},
});

