import React from 'react';
import _ from 'lodash'
import Typography from '@mui/material/Typography';

export default function Currency({ value, round=true, noWrap=false, sx }) {

  const v = _.isNumber(value) ? value : _.toNumber(value);

  const val = new Intl.NumberFormat('en-US', { style: 'currency', currency:'USD', maximumFractionDigits:0 }).format(
    v,
    )

  return (
    <Typography noWrap={noWrap} sx={sx}>
      {val}
    </Typography>
  );
}
