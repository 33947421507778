import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import wrapper from '../wrapper'
import { comparisonSavedState } from '../../recoil_state'
import {
  useRecoilCallback, useRecoilState, useResetRecoilState
} from 'recoil';
import _ from 'lodash'

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Save = ({ translate, disabled }) => {
  const [state, setState] = React.useState({
    open: false,
    Transition: SlideTransition
  });

  const showSnack = () => {
    setState({
      Transition: SlideTransition,
      open: true
    })
  }

  const hideSnack = () => {
    setState({
      ...state,
      open: false
    })
  }

  const [comparisonSaved, setComparisonSavedState] = useRecoilState((comparisonSavedState))

  const handleSnapShot = useRecoilCallback(({ snapshot }) => async () => {

    const newState = { }

    for (const node of snapshot.getNodes_UNSTABLE()) {
      const value = await snapshot.getPromise(node);
      newState[node.key] = value
    }

    setComparisonSavedState(newState)
    showSnack();
  }, []);

  const resetState = useResetRecoilState(comparisonSavedState);

  const handleClick = () => {

    if(!_.isEmpty(comparisonSaved)){
      resetState();
    }

    handleSnapShot();

  }

  return (
    <>
    <Tooltip disableFocusListener title={translate('save_for_comparison')}>
      <span>
        <IconButton disabled={disabled} onClick={handleClick}>
          <SaveIcon />
        </IconButton>
      </span>
    </Tooltip>
      <Snackbar
        open={state.open}
        onClose={hideSnack}
        TransitionComponent={state.Transition}
        message="Current results have been saved for comparison"
        key={state.Transition.name}
        autoHideDuration={6000}
      />
      </>
  );
}


export default wrapper(Save);

