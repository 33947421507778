import _ from 'lodash'
import stateData from '../data/stateOverrides/stateData.json'
import stateWages from '../data/stateOverrides/stateWages.json'
import wages from '../data/wages.json'

export const stateOverrideData = (state) => {
  return _.get(stateData, state)
}

export const getWages = (state) => {

  const stateData = _.get(stateWages, state ) || {}
  return _.merge(wages.wages, stateData)

}

export const getInsurance = (state, orgType) => {
  return _.get(wages, ['insurance', state, orgType]) || 0
}
