import React from 'react';
import { centerRetirementBenefitsState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const CenterRetirementBenefits = () => {

  const [centerRetirementBenefits, setCenterRetirementBenefitsState] = useRecoilState(centerRetirementBenefitsState);

  const handleOnChange = (value) => {
    setCenterRetirementBenefitsState(value)
  }

  return (
    <Stack direction="row" spacing={1}>
        <SalaryField
          value={centerRetirementBenefits}
          handleOnChange={handleOnChange}
          label='retirement_benefits'
          endAdornment="%"
          fullWidth
        />
    <Help contentKey="help_retirement_benefits" />
    </Stack>
  )

}

export default CenterRetirementBenefits

