import React from 'react';
import { fccTotalStaffState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccTotalStaffResults = () => {

  const value = useRecoilValue(fccTotalStaffState)

  return <ResultsLineItem name="total_staff" value={value} integer/>;

}

export default FccTotalStaffResults;

