import * as React from 'react';
import { PiePlot } from '@mui/x-charts/PieChart';
import { ChartsLegend } from '@mui/x-charts/ChartsLegend';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { PieChart } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import { chartColors } from '../../data/config'

const PdfPieChart = ({ xAxis, series, width, height }) => {
  return (
    <Box sx={{ width: 460, height: 350 }} >
      <PieChart
        colors={chartColors}
        series={series}
        slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
        }}}
        width={460}
        height={350}
      >
        <PiePlot />
        <ChartsTooltip trigger="item"/>
        <ChartsLegend />
      </PieChart >
    </Box >
  );
}

export default PdfPieChart
