import React from 'react';
import { fccOrPercentageState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const FccOperatingReserve = () => {

  const [fccOrPercentage, setFccOrPercentageState] = useRecoilState(fccOrPercentageState);

  const handleOnChange = (value) => {
    setFccOrPercentageState(value)
  }

  return (
    <Stack direction="row" spacing={1}>
        <SalaryField
          value={fccOrPercentage}
          handleOnChange={handleOnChange}
          label='op_reserve_contribution'
          endAdornment="%"
          fullWidth
        />
    <Help contentKey="help_op_reserve_contribution" />
    </Stack>
  )

}

export default FccOperatingReserve

