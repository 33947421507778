import React from 'react';
import { fccPmaTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const FccPgrmMgmtResults = () => {

  const value = useRecoilValue(fccPmaTotalState)

  return <ResultsLineItem name="program_mgmt" value={value}/>;

}

export default FccPgrmMgmtResults;

