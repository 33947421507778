import React from 'react';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fccAccreditationState } from "../../recoil_state";
import { useRecoilState } from "recoil";
import wrapper from '../wrapper'
import Help from '../Help'


const FccAccreditation = ({ translate }) => {

  const [fccAccreditation, setFccAccreditationState] = useRecoilState(fccAccreditationState);

  const updateState = ({ target: { value } }) => {
     setFccAccreditationState(value)
  };


  return (
    <Stack direction="row"
      spacing={1}
      alignItems="center" >
      <FormControl fullWidth>
        <InputLabel id="fcc_accreditation-label">{translate('fcc_accreditation')}</InputLabel>
        <Select
          labelId="fcc_accreditation-label"
          id="fcc_accreditation"
          value={fccAccreditation}
          label={translate('fcc_accreditation')}
          onChange={updateState}
        >
          <MenuItem value="licensing">{translate('licensing')}</MenuItem>
          <MenuItem value="cfoc">{translate('cfoc')}</MenuItem>
        </Select>
      </FormControl>
      <Help contentKey="help_fcc_accreditation" />
    </Stack>
  );
}

export default wrapper(FccAccreditation)
