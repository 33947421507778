import React from 'react';
import CenterSizeGridItem from './CenterSizeGridItem'

const CenterSizeGrid = () => {

  return (
    <>
      <CenterSizeGridItem which='infant' />
      <div className="results-line-btm-margin" />
      <CenterSizeGridItem which='toddler' />
      <div className="results-line-btm-margin" />
      <CenterSizeGridItem which='threes' />
      <div className="results-line-btm-margin" />
      <CenterSizeGridItem which='fours' />
      <div className="results-line-btm-margin" />
      <CenterSizeGridItem which='school' />
    </>
  );
}

export default CenterSizeGrid
