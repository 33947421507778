import React from 'react';
import { fccSickDaysState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import SalaryField from '../fields/SalaryField'
import Help from '../Help'
import Stack from '@mui/material/Stack';

const FccSickDays = () => {

  const [fccSickDays, setFccSickDaysState] = useRecoilState(fccSickDaysState);

  const handleOnChange = (value) => {
    setFccSickDaysState(value)
  }

  return (
    <Stack direction="row" spacing={1}>
    <SalaryField
      value={fccSickDays}
      handleOnChange={handleOnChange}
      label='sick_days'
      fullWidth
    />
    <Help contentKey="help_fcc_sick_days" />
  </Stack>

  )

}

export default FccSickDays

