import React from 'react';
import { centerDiscretionaryBenefitsState } from '../../recoil_state'
import { useRecoilState } from 'recoil'
import Stack from '@mui/material/Stack';
import wrapper from '../wrapper'
import Help from '../Help'
import YesNoSelect  from '../YesNoSelect'

const CenterDiscretionaryBenefits = ({ translate }) => {


  const [centerDiscretionaryBenefits, setCenterDiscretionaryBenefitsState] = useRecoilState(centerDiscretionaryBenefitsState);

  const handleOnChange = (value) => {
    setCenterDiscretionaryBenefitsState(value)
  }

  return (
    <Stack direction="row"
      spacing={1}
      alignItems="center" >
    {/*    <Content contentKey="discretionary_benefits" />*/}
    {/*<Switch*/}
    {/*  value={centerDiscretionaryBenefits}*/}
    {/*  handleOnChange={handleOnChange}*/}
    {/*  label='discretionary_benefits'*/}
    {/*/> */}
    <YesNoSelect handleOnChange={handleOnChange} value={centerDiscretionaryBenefits} titleKey='discretionary_benefits' />
    <Help contentKey="help_discretionary_benefits" />
    </Stack >
  )

}

export default wrapper(CenterDiscretionaryBenefits)

