import {
  atom
} from "recoil";
import { fccSizeSelect, fccSalariesSelect, fccDiscBenefitsSelect, fccFamilyEngagementSelect, fccAccreditationSelect } from '../helpers/analytics'

export const fccRatioRegsState = atom({
  key: "fccRatioRegsState",
  default: "",
  effects: [
    ({onSet}) => {
      onSet(v => {
        fccSizeSelect(v)
      });
    },
  ]
});

export const fccAccreditationState = atom({
  key: "fccAccreditationState",
  default: "",
  effects: [
    ({onSet}) => {
      onSet(v => {
        fccAccreditationSelect(v)
      });
    },
  ]
});

//export const fccTotalClassroomsState = atom({
//  key: "fccTotalClassroomsState",
//  default: 0
//});
//
//export const fccTotalSchoolAgeClassroomsState = atom({
//  key: "fccTotalSchoolAgeClassroomsState",
//  default: 0
//});

export const fccTotalChildrenState = atom({
  key: "fccTotalChildrenState",
  default: 0
});

export const fccTotalSchoolAgeChildrenState = atom({
  key: "fccTotalSchoolAgeChildrenState",
  default: 0
});

export const fccInfantState = atom({
  key: "fccInfantState",
  default: {
    //classrooms: 1,
    //ratios: null,
    //groupSize: null,
    childrenAge: 1,
  }
});

export const fccToddlerState = atom({
  key: "fccToddlerState",
  default: {
    //classrooms: 1,
    //ratios: null,
    //groupSize: null,
    childrenAge: 1,
  }
});

export const fccThreesState = atom({
  key: "fccThreesState",
  default: {
    //classrooms: 1,
    //ratios: null,
    //groupSize: null,
    childrenAge: 1,
  }
});

export const fccFoursState = atom({
  key: "fccFoursState",
  default: {
    //classrooms: 1,
    //ratios: null,
    //groupSize: null,
    childrenAge: 1,
  }
});

export const fccSchoolState = atom({
  key: "fccSchoolState",
  default: {
    //classrooms: 1,
    //ratios: null,
    //groupSize: null,
    childrenAge: 1,
  }
});

export const fccPositionCountState = atom({
  key: "fccPositionCountState",
  default: {
    director: 1,
    asstTeacher: .25,
    substitute: 0,
    floaters: 0,
    instructionalAides: 0
  },
});



export const fccSalariesState = atom({
  key: "fccSalariesState",
  default: {
    director: null,
    asstTeacher: null,
  },
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("New Salaries", newID);
      });
    },
  ],
});

export const fccSalaryDataSourceState = atom({
  key: "fccSalaryDataSourceState",
  default: "",
  effects: [
    ({onSet}) => {
      onSet(v => {
        fccSalariesSelect(v)
      });
    },
  ]
});

export const fccDiscretionaryBenefitsState = atom({
  key: "fccDiscretionaryBenefitsState",
  default: true,
  effects: [
  ({onSet}) => {
    onSet(v => {
      fccDiscBenefitsSelect(v)
    });
  },
]
});

export const fccRetirementBenefitsState = atom({
  key: "fccRetirementBenefitsState",
  default: 0,
});

export const fccSickDaysState = atom({
  key: "fccSickDaysState",
  default: 10,
});

export const fccPaidLeaveState = atom({
  key: "fccPaidLeaveState",
  default: 10,
});

export const fccEnrollmentEfficiencyState = atom({
  key: "fccEnrollmentEfficiencyState",
  default: 85,
});

export const fccBadDebtEfficiencyState = atom({
  key: "fccBadDebtEfficiencyState",
  default: 3,
});


export const fccTotalStaffState = atom({
  key: "fccTotalStaffState",
  default: 0,
});

export const fccTeacherStaffState = atom({
  key: "fccTeacherStaffState",
  default: 0,
});

export const fccWagesTotalState = atom({
  key: "fccWagesTotalState",
  default: 0,
  effects: [],
});

export const fccWageGroupsState = atom({
  key: "fccWageGroupsState",
  default: {
    admin: 0,
    teacher: 0,
    schoolAgeTeacher: 0,
    subFloater: 0
  },
  effects: [
  ({onSet}) => {
    onSet(newID => {
      //console.debug("Wages", newID);
    });
  },
],
});

export const fccMandatoryBenefitsTotalState = atom({
  key: "fccMandatoryBenefitsTotalState",
  default: 0,
});

export const fccDiscretionaryBenefitsTotalState = atom({
  key: "fccDiscretionaryBenefitsTotalState",
  default: 0,
});

export const fccBenefitsTotalState = atom({
  key: "fccBenefitsTotalState",
  default: 0,
});

export const fccFamilyEngagementState = atom({
  key: "fccFamilyEngagementState",
  default: true,
  effects: [
    ({onSet}) => {
      onSet(v => {
        fccFamilyEngagementSelect(v)
      });
    },
  ]
});

export const fccFamilyEngagementTotalState = atom({
  key: "fccFamilyEngagementTotalState",
  default: 0
});

export const fccPersonnelTotalState = atom({
  key: "fccPersonnelTotalState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("fccPersonnelTotalState", newID);
      });
    },
  ],
});



export const fccWagesByPositionState = atom({
  key: "fccWagesByPositionState",
  default: {},
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("Wages", newID);
      });
    },
  ],
});

export const fccQvTotalState = atom({
  key: "fccQvTotalState",
  default: 0,
});

export const fccEpeTotalState = atom({
  key: "fccEpeTotalState",
  default: 0,
});

export const fccOccupTotalState = atom({
  key: "fccOccupTotalState",
  default: 0,
});

export const fccPmaTotalState = atom({
  key: "fccPmaTotalState",
  default: 0,
});

export const fccOrPercentageState = atom({
  key: "fccOrPercentageState",
  default: 5,
});

export const fccOrTotalState = atom({
  key: "fccOrTotalState",
  default: 0,
});

export const fccNonPersonnelTotalState = atom({
  key: "fccNonPersonnelTotalState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("fccNonPersonnelTotalState", newID);
      });
    },
  ],
});

export const fccTotalExpenseState = atom({
  key: "fccTotalExpenseState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("fccTotalExpenseState", newID);
      });
    },
  ],
});

export const fccAnnualCpcInfantState = atom({
  key: "fccAnnualCpcInfantState",
  default: 0,
  effects: [
    ({onSet}) => {
      onSet(newID => {
        //console.debug("fccAnnualCpcInfantState", newID);
      });
    },
  ],
});

export const fccAnnualCpcToddlerState = atom({
  key: "fccAnnualCpcToddlerState",
  default: 0,
});

export const fccAnnualCpcThreesState = atom({
  key: "fccAnnualCpcThreesState",
  default: 0,
});

export const fccAnnualCpcFoursState = atom({
  key: "fccAnnualCpcFoursState",
  default: 0,
});

export const fccAnnualCpcSchoolAgeState = atom({
  key: "fccAnnualCpcSchoolAgeState",
  default: 0,
});

export const fccAnnualCpcInfantCalculationsState = atom({
  key: "fccAnnualCpcInfantCalculationsState",
  default: {},
});

export const fccAnnualCpcToddlerCalculationsState = atom({
  key: "fccAnnualCpcToddlerCalculationsState",
  default: {},
});

export const fccAnnualCpcThreesCalculationsState = atom({
  key: "fccAnnualCpcThreesCalculationsState",
  default: {},
});

export const fccAnnualCpcFoursCalculationsState = atom({
  key: "fccAnnualCpcFoursCalculationsState",
  default: {},
});

export const fccAnnualCpcSchoolAgeCalculationsState = atom({
  key: "fccAnnualCpcSchoolAgeCalculationsState",
  default: {},
});

