import _ from 'lodash'
import { stateOverrideData } from './stateOverrideData'

import groupSizeDefaults from '../data/statics/ratios/groupSize.json'
import ratiosDefaults from '../data/statics/ratios/ratios.json'
import classroomsDefaults from '../data/statics/ratios/classrooms.json'


export const getRatioDefault = (state, ratioRegs, age) => {

  const defaultData = _.get(ratiosDefaults, [ratioRegs, age])
  const stateDataOverrides = stateOverrideData(state)
  const stateData = _.get(stateDataOverrides, ['ratios', ratioRegs, age])
  return stateData || defaultData

}

export const getGroupSizeDefault = (state, ratioRegs, age) => {

  const defaultData = _.get(groupSizeDefaults, [ratioRegs, age])
  const stateDataOverrides = stateOverrideData(state)
  const stateData = _.get(stateDataOverrides, ['groupSize', ratioRegs, age])
  return stateData || defaultData

}

export const getClassroomsDefault = (state, ratioRegs, age) => {

  const defaultData = _.get(classroomsDefaults, [ratioRegs, age])
  const stateDataOverrides = stateOverrideData(state)
  const stateData = _.get(stateDataOverrides, ['classroomsDefaults', ratioRegs, age])
  return stateData || defaultData

}
