import * as React from 'react';
import CenterCpcChartResults from './CenterCpcChartResults';
import CenterExpenseTotalChartResults from './CenterExpenseTotalChartResults';
import CenterExpensePersonnelChartResults from './CenterExpensePersonnelChartResults';
import CenterExpenseNonPersonnelChartResults from './CenterExpenseNonPersonnelChartResults';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { centerTotalExpenseState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'

const CenterCharts = ({ pdfDisplayOnly }) => {

  const value = useRecoilValue(centerTotalExpenseState)

  if(!value) return null

  const xs = pdfDisplayOnly ? 12 : 12;
  const md = pdfDisplayOnly ? 6 : 6;
  const lg = pdfDisplayOnly ? 6 : 4;

  return (

    <Box sx={{ flexGrow: 1 }} >
      <Grid container
        spacing={2} >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterCpcChartResults which="annual"
            cardTitle="annual_cost_per_child" />
        </Grid >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterCpcChartResults which="monthly"
            cardTitle="monthly_cost_per_child" />
        </Grid >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterCpcChartResults which="weekly"
            cardTitle="weekly_cost_per_child" />
        </Grid >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterExpenseTotalChartResults
            pdfDisplayOnly={pdfDisplayOnly}
            cardTitle="total_expense_lower" />
        </Grid >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterExpensePersonnelChartResults
            pdfDisplayOnly={pdfDisplayOnly}
            cardTitle="total_personnel" />
        </Grid >
        <Grid item
          xs={xs} md={md} lg={lg}>
          <CenterExpenseNonPersonnelChartResults
            pdfDisplayOnly={pdfDisplayOnly}
            cardTitle="total_nonpersonnel" />
        </Grid >
        {/*<Grid item*/}
        {/*  xs={12} md={12} lg={6}>*/}
        {/*  <CenterExpenseWageBreakdownChartResults*/}
        {/*    cardTitle="wage_by_position" />*/}
        {/*</Grid >*/}
      </Grid >
    </Box >

  )
};

export default CenterCharts;
