import React from 'react';
import { centerEpeTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterEduProgResults = () => {

  const value = useRecoilValue(centerEpeTotalState)

  return <ResultsLineItem name="education_program_exp" value={value} />

}

export default CenterEduProgResults;

