import React from 'react';
import { centerAnnualCpcToddlerState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import Content from '../../Content'
import Currency from '../../Currency'
import Grid from '@mui/material/Grid';
import { round } from '../../../helpers'


const CenterToddlerCpcResults = () => {

  const value = useRecoilValue(centerAnnualCpcToddlerState)

  const sx = { fontWeight: 600 };

  return (
    <Grid container spacing={2} className="cpc-parent-grid">
      <Grid item xs={12} className="cpc-child-grid cpc-title">
        <Content contentKey="toddler" sx={sx} />
      </Grid >
      <Grid item className="cpc-container"
        xs={4} >
        <Content contentKey="annual" />
      </Grid >
      <Grid item className="cpc-container"
        xs={4} >
        <Content contentKey="monthly" />
      </Grid >
      <Grid item className="cpc-container"
        xs={4} >
        <Content contentKey="weekly" />
      </Grid >
          <Grid item className="cpc-container"
            xs={4} >
        <Currency value={value} />
      </Grid >
      <Grid item className="cpc-container"
        xs={4} >
        <Currency value={round(value / 12)} />
      </Grid >
      <Grid item className="cpc-container"
        xs={4} >
        <Currency value={round(value / 52)} />
      </Grid >
    </Grid >

  )

}

export default CenterToddlerCpcResults;

