import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import states from '../../data/states.json';
import { stateState } from "../../recoil_state";
import { useRecoilState } from "recoil";
import wrapper from '../wrapper'
import Content from '../Content'
import { stateSelect } from '../../helpers/analytics'


const State = ({ translate }) => {

  const [state, setState] = useRecoilState(stateState);

  const updateState = ({ target: { value } }) => {
    setState(value);
    stateSelect(value)
  };

  let stateList = [];

  states.forEach(v => stateList.push(<MenuItem key={v.code} value={v.code}>{v.name}</MenuItem>))

  return (
    <Box sx={{ minWidth: 120 }}>
      {/*<span>{props.translate('state')}</span>*/}
      {/*<Content contentKey='state' />*/}
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label"><Content contentKey="state"/></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state}
          label={translate('state')}
          onChange={updateState}
        >
          <MenuItem/>
          {stateList}
        </Select>
      </FormControl>
    </Box>
  );
}

export default wrapper(State)
