import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  comparisonCurrentState,
  comparisonSavedState,
  orgTypeState
} from '../../recoil_state'
import { useRecoilValue } from 'recoil'
import comparisonConfig from '../../data/statics/comparisonConfig'
import _ from 'lodash'
import wrapper from '../wrapper';
import Currency from '../Currency';

const columns = [
  { key: 0, id: 'dataPoint', label: 'Data Point', align: 'left' },
  { key: 1, id: 'saved', label: 'Saved Value', align: 'right' },
  { key: 2, id: 'current', label: 'Current Value', align: 'right' },
];

const ComparisonTable = ({ translate }) => {

  const orgType = useRecoilValue(orgTypeState);
  const saved = useRecoilValue(comparisonSavedState);
  const current = useRecoilValue(comparisonCurrentState);

  const config = _.get(comparisonConfig, orgType);

  const rows = config.map(v => {

    const doTranslate = (c) => {
      if(!_.isArray(c)){
        return translate(c)
      } else {
        const vals = c.map(v => translate(v))
        return vals.join(' ');
      }
    }

    const result = {
      key: v.label,
      dataPoint: doTranslate(v.label)
    }

    const savedStateValue = _.get(saved, v.atom);
    const currentStateValue = _.get(current, v.atom);
    const modify = v.math;

    const newSavedStateValue = _.isFunction(modify) ? modify(savedStateValue) : savedStateValue;
    const newCurrentStateValue = _.isFunction(modify) ? modify(currentStateValue) : currentStateValue;

    if(v.format){
      if(v.format === 'currency'){
        result.saved = <Currency value={newSavedStateValue} />;
        result.current = <Currency value={newCurrentStateValue} />;
      }
    } else {
      result.saved = newSavedStateValue;
      result.current = newCurrentStateValue;
    }


    return result
  })



  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className="comparison-table-header"
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
            .map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default wrapper(ComparisonTable);
