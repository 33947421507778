import React from 'react';
import { centerNonPersonnelTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'


const CenterNonPersonnelTotalResults = () => {

  const value = useRecoilValue(centerNonPersonnelTotalState)

  return <ResultsLineItem name="total_nonpersonnel" value={value} bold={600} />

}

export default CenterNonPersonnelTotalResults;

