import * as React from 'react';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import Box from '@mui/material/Box';
import { chartColors } from '../data/config'

const BarChart = ({ xAxis, series, width, height }) => {

  return (
    <Box sx={{ width: '100%', height: 350 }} >
      <ResponsiveChartContainer
        xAxis={xAxis}
        series={series}
        tooltip={{ trigger: 'axis' }}
        colors={chartColors}
      >
        <BarPlot
          skipAnimation
        />
        <ChartsTooltip />
        <ChartsYAxis />
        <ChartsXAxis
          position="bottom"
          axisId="x-axis-id" />
      </ResponsiveChartContainer >
    </Box >
  );
}

export default BarChart
