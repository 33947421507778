import React from 'react';
import { centerOccupTotalState } from '../../../recoil_state'
import { useRecoilValue } from 'recoil'
import ResultsLineItem from '../../ResultsLineItem'

const CenterOccupancyResults = () => {

  const value = useRecoilValue(centerOccupTotalState)

  return <ResultsLineItem name="occupancy" value={value} />

}

export default CenterOccupancyResults;

