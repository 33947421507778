import * as React from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import wrapper from '../wrapper';
import CenterSalaries from './CenterSalaries';
import CenterBenefits from './CenterBenefits';
import CenterAdditionalCosts from './CenterAdditionalCosts';
import CenterStateAndRatioDisplay from './CenterStateAndRatioDisplay';
import SectionIntro from '../SectionIntro';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(153, 204, 255, .2)'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Center = ({ translate }) => {

  return (
    <div >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="section-header"
        >
          {translate('state_center_size')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="center_section_1_intro" />
          <CenterStateAndRatioDisplay />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {translate('salaries')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="center_section_2_intro" />
          <CenterSalaries />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          {translate('benefits')}
        </AccordionSummary >
        <AccordionDetails >
          <CenterBenefits />
        </AccordionDetails >
      </Accordion >
      <Accordion defaultExpanded >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          {translate('enhancements')}
        </AccordionSummary >
        <AccordionDetails >
          <SectionIntro contentKey="center_section_4_intro" />
          <CenterAdditionalCosts />
        </AccordionDetails >
      </Accordion >
    </div >
  );
}

export default wrapper(Center)

