import React from 'react';
import { displayPdfState, orgTypeState } from '../../recoil_state'
import { useRecoilValue } from 'recoil';
import wrapper from '../wrapper'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CenterData from './CenterData';
import FccData from './FccData';
import logo from '../../images/logo.webp'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const PdfDisplay = ({ translate }) => {

  const orgType = useRecoilValue(orgTypeState)
  const displayPdf = useRecoilValue(displayPdfState);

  if(!displayPdf){
    return null;
  }

  const dataDisplay = orgType === 'fcc' ? <FccData /> : <CenterData />;


  return (
    //<Box sx={{ flexGrow: 1, maxWidth:1022, display: 'flex' }} id="rootCalc">
        <Grid container spacing={2} sx={{ flexGrow: 1, maxWidth:1022 }} id="rootCalc">
          <Grid item xs={12}>
  <div className="pdf-header" >
    <Stack direction="row"
      spacing={2}
      sx={{ justifyContent: 'space-between' }}
    >
      <img src={logo}
        className="logo" />

      <div className="title-container" >
        <div className="title-box" >
        <Typography variant="h4"
          gutterBottom
          className="title" >
            {translate('app_title')}
        </Typography >
      </div >
    </div ></Stack >
  </div >
      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={2}>
          {dataDisplay}

        </Grid>
      </Box>
          </Grid>
          </Grid>
    //</Box>
  );
}


export default wrapper(PdfDisplay);

