import React from 'react';
import {
  centerPersonnelTotalState,
  centerNonPersonnelTotalState,
} from "../../../recoil_state";
import { useRecoilValue } from 'recoil'
import PieChartCard from '../../PieChartCard';
import PdfPieChartCard from '../../pdf/PdfPieChartCard';
import wrapper from '../../wrapper'


const CenterExpenseTotalChartResults = ({ translate, which, cardTitle, pdfDisplayOnly }) => {

const centerPersonnelTotal = useRecoilValue(centerPersonnelTotalState);
const centerNonPersonnelTotal = useRecoilValue(centerNonPersonnelTotalState);

  const series = [
    {
      type: 'pie',
      data: [
        { id: 0, value: centerNonPersonnelTotal, label: translate('total_nonpersonnel') },
        { id: 1, value: centerPersonnelTotal, label: translate('total_personnel') },
      ],
      innerRadius: 30,
      outerRadius: 100,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: -180,
      endAngle: 180,
      cy: 150
    },
  ]

  const ChartComponent = pdfDisplayOnly ? PdfPieChartCard : PieChartCard;

  return (
    <ChartComponent
      cardTitle={translate(cardTitle)}
      series={series}
    />
  )

}

export default wrapper(CenterExpenseTotalChartResults)

