import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import wrapper from '../wrapper'
import Content from '../Content'
import CenterTableCellData from './CenterTableCellData'
import CenterSizeTotals from './CenterSizeTotals'
import Help from '../Help'

const CenterSizeTable = ({ pdfDisplayOnly, translate }) => {

  const keys = [
    "infant",
    "toddler",
    "threes",
    "fours",
    "school"
  ]

  return (

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('age')}</TableCell>
            <TableCell align="right">{translate('number_of_classrooms')}<Help pdfDisplayOnly={pdfDisplayOnly} contentKey="help_classrooms"  /></TableCell>
            <TableCell align="right">{translate('ratios')}<Help pdfDisplayOnly={pdfDisplayOnly} contentKey="help_ratio"  /></TableCell>
            <TableCell align="right">{translate('group_size')}<Help pdfDisplayOnly={pdfDisplayOnly} contentKey="help_group_size"  /></TableCell>
            <TableCell align="right">{translate('child_count')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((key) => (
            <TableRow
              key={key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Content contentKey={key} />
              </TableCell>
              <TableCell align="right">
                <CenterTableCellData
                  row={key}
                  col='classrooms'
                  numerical
                  pdfDisplayOnly={pdfDisplayOnly}
                />
              </TableCell>
              <TableCell align="right">
                <CenterTableCellData
                  row={key}
                  col='ratios'
                  numerical
                  pdfDisplayOnly={pdfDisplayOnly}
                />
              </TableCell>
              <TableCell align="right">
                <CenterTableCellData
                  row={key}
                  col='groupSize'
                  numerical
                  pdfDisplayOnly={pdfDisplayOnly}
                />
              </TableCell>
              <TableCell align="right">
                <CenterTableCellData
                  row={key}
                  col='childrenAge'
                  numerical
                  pdfDisplayOnly={pdfDisplayOnly}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
              <TableCell component="th" scope="row">
                <Content contentKey="totals" />
              </TableCell>
              <TableCell align="right">
                <CenterSizeTotals col="classrooms" />
              </TableCell>
              <TableCell align="right">
                <span/>
              </TableCell>
              <TableCell align="right">
                <span/>
              </TableCell>
              <TableCell align="right">
                <CenterSizeTotals col="childrenAge" />
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default wrapper(CenterSizeTable)
